import { useDispatch } from "react-redux";
import { uiActions } from "../../store/ui-slice";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import { useRef, useState } from "react";
import { validateCharterId } from "../../api/chart-api";

const CharterIdModal = (props) => {
  const [inputValid, setInputValid] = useState(true);
  const [statusText, setStatusText] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const dispatch = useDispatch();
  const charterIdInputRef = useRef();

  const handleModalSubmit = async () => {
    const charterId = charterIdInputRef.current.value.trim();
    // Validate user ID if needed
    setInputValid(true);
    setStatusText("Checking charter ID...");
    setIsChecking(true);
    const isValid = await validateCharterId(charterId);
    setIsChecking(false);
    setStatusText("");
    console.log(isValid);
    if (isValid) {
      // Save user ID to localStorage
      localStorage.setItem("charterID", charterId);
      dispatch(uiActions.closeModal());
    } else {
      setInputValid(false);
      setStatusText("Invalid charter ID");
      //   setTimeout(() => {
      //     setInputValid(true);
      //     setStatusText("");
      //   }, 3000);
    }
  };

  return (
    <Modal
      undismissable
      className="p-6 md:p-8"
      width="w-4/5"
      maxWidth="max-w-2xl"
    >
      <h2 className="text-center text-lg sm:text-xl font-bold mb-6">
        Please Enter Your Charter ID
      </h2>
      <div className="relative">
        <input
          ref={charterIdInputRef}
          className={`w-full rounded-lg py-2 px-3 mb-6 focus:outline-none ${
            inputValid
              ? "placeholder:text-slate-300 bg-slate-100 dark:bg-slate-700 dark:placeholder:text-slate-500"
              : "bg-red-50 text-red-900 placeholder-red-700 dark:bg-red-100"
          }`}
          type="text"
          placeholder="Enter your provided charter ID"
          value={props.value}
          onChange={props.onChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleModalSubmit();
            }
          }}
        />
        <p
          className={`text-xs md:text-sm absolute bottom-0 left-0 ${
            inputValid ? "text-slate-400" : "text-red-600 dark:text-red-500"
          }`}
        >
          {statusText}
        </p>
      </div>
      <Button
        className="mt-2 w-full"
        onClick={handleModalSubmit}
        disabled={isChecking}
      >
        Confirm
      </Button>
    </Modal>
  );
};

export default CharterIdModal;
