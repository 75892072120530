const FixingArt = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width="690"
      height="448.7592"
      viewBox="0 0 690 448.7592"
      className={props.className}
    >
      <path
        d="M466.81292,645.7313c4.66847-10.08358,9.33339-20.31666,11.35946-31.24223s1.17569-22.78293-4.627-32.25932-17.22813-15.90241-28.13153-13.76022c-8.95532,1.75945-16.14605,8.81268-20.35651,16.90992S419.15759,602.60959,417.52,611.588c-.52917-10.2001-1.0896-20.56436-4.478-30.19975s-10.06045-18.61463-19.5694-22.34313-21.87886-.79648-26.89061,8.1032c-7.06074,12.53818,2.30155,30.12541-5.81734,42.0058-1.39265-11.917-13.85471-21.33234-25.69879-19.4159s-20.70109,14.78128-18.2646,26.5294c1.44978,6.99047,6.21931,12.93631,11.92156,17.232s12.33421,9.27436,18.89475,12.09Z"
        transform="translate(-255 -225.6204)"
        fill="#f2f2f2"
      />
      <path
        d="M323.01987,598.15781c9.40825,3.28851,18.903,6.61425,27.49226,11.75558,7.698,4.60786,14.553,10.81187,18.88231,18.7569a33.35556,33.35556,0,0,1,4.12583,13.85861c.06251,1.01759,1.65458,1.02469,1.59164,0-.55661-9.061-4.97238-17.353-11.087-23.91075-6.704-7.18984-15.39158-12.10041-24.3611-15.91043-5.31821-2.259-10.76859-4.17895-16.2208-6.08468-.96887-.33866-1.3854,1.19842-.42311,1.53477Z"
        transform="translate(-255 -225.6204)"
        fill="#fff"
      />
      <path
        d="M377.28745,560.62486a143.38254,143.38254,0,0,1,13.79113,30.61557,145.11672,145.11672,0,0,1,6.361,32.96846,143.30543,143.30543,0,0,1,.15151,18.83685c-.05905,1.02328,1.53278,1.01989,1.59163,0a145.1941,145.1941,0,0,0-2.04032-33.82049,146.92647,146.92647,0,0,0-9.769-32.44022,143.25,143.25,0,0,0-8.71162-16.9635.79641.79641,0,0,0-1.37432.80333Z"
        transform="translate(-255 -225.6204)"
        fill="#fff"
      />
      <path
        d="M455.188,569.76743a232.04359,232.04359,0,0,0-17.11648,57.57847q-1.34225,8.36487-2.07791,16.81182c-.089,1.02023,1.50317,1.01426,1.59163,0a231.20919,231.20919,0,0,1,12.73788-58.02528q2.83382-7.89209,6.23921-15.56169c.412-.92791-.959-1.73862-1.37433-.80332Z"
        transform="translate(-255 -225.6204)"
        fill="#fff"
      />
      <rect
        x="709.17625"
        y="639.47815"
        width="9.88235"
        height="46.58824"
        transform="translate(1112.59535 -285.4804) rotate(89.25872)"
        fill="#e6e6e6"
      />
      <path
        d="M754.02652,669.61624l-6.65257-.0194-4.11239-7.33712,4.445-7.31237,6.1811.01792a11.99893,11.99893,0,1,0,.13889,14.651Z"
        transform="translate(-255 -225.6204)"
        fill="#e6e6e6"
      />
      <path
        d="M674.20833,669.61624l6.65258-.0194,4.11239-7.33712-4.445-7.31237-6.18109.01792a11.99892,11.99892,0,1,1-.1389,14.651Z"
        transform="translate(-255 -225.6204)"
        fill="#e6e6e6"
      />
      <path
        d="M944,646.58958H256a1,1,0,0,1,0-2H944a1,1,0,0,1,0,2Z"
        transform="translate(-255 -225.6204)"
        fill="#3f3d56"
      />
      <circle cx="540.48622" cy="197.96918" r="33" fill="#2f2e41" />
      <polygon
        points="553.859 383.226 556.401 395.219 603.871 391.124 600.12 373.423 553.859 383.226"
        fill="#ffb8b8"
      />
      <path
        d="M789.18809,618.49673h38.53073a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H804.07494a14.88685,14.88685,0,0,1-14.88685-14.88685v0A0,0,0,0,1,789.18809,618.49673Z"
        transform="translate(108.71051 1320.97269) rotate(-101.96466)"
        fill="#2f2e41"
      />
      <polygon
        points="524.12 382.586 518.777 393.621 473.673 378.264 481.559 361.979 524.12 382.586"
        fill="#ffb8b8"
      />
      <path
        d="M771.03745,617.45562h23.64388a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H756.15059a0,0,0,0,1,0,0v0A14.88685,14.88685,0,0,1,771.03745,617.45562Z"
        transform="translate(-379.9408 824.86818) rotate(-64.16458)"
        fill="#2f2e41"
      />
      <path
        d="M690.25925,586.41362a10.74272,10.74272,0,0,0,12.70881-10.48016l74.37388-68.5711-18.47-14.30549-67.33743,71.94341a10.80091,10.80091,0,0,0-1.27527,21.41334Z"
        transform="translate(-255 -225.6204)"
        fill="#ffb8b8"
      />
      <circle cx="536.34449" cy="207.89831" r="24.56103" fill="#ffb8b8" />
      <path
        d="M813.38612,577.89329c-17.22851.00049-37.978-3.62842-50.77856-18.477l-.28833-.33447.29663-.32813c.09668-.10693,9.51367-10.86865.11084-30.061L749.799,532.67015,736.9286,515.68138l7.12964-21.38916,29.17749-23.50391a26.75074,26.75074,0,0,1,14.6106-5.78906,80.21058,80.21058,0,0,0,27.78467-7.91309,27.906,27.906,0,0,1,12.7998-2.79834l.57373.01611a9.95022,9.95022,0,0,1,9.64063,10.70752c-1.97852,25.62989-5.47242,87.54346,4.7915,108.86133l.26514.55078-.59229.15039A136.1132,136.1132,0,0,1,813.38612,577.89329Z"
        transform="translate(-255 -225.6204)"
        fill="#2563eb"
      />
      <path
        d="M765.98622,559.08958s-65-6-72,13,1,28,13,32,41,9,41,9l13-16,34,2s37.88482,21.9732,48.35975,45.47377A30.76193,30.76193,0,0,0,869.12343,662.802c8.398.58447,15.86279-2.5874,15.86279-15.7124,0-30-42-73-42-73Z"
        transform="translate(-255 -225.6204)"
        fill="#2f2e41"
      />
      <path
        d="M716.48622,588.58958s17-5,44,8"
        transform="translate(-255 -225.6204)"
        fill="#2f2e41"
      />
      <path
        d="M766.84286,417.85547a73.04115,73.04115,0,0,0,31.59919,10.4119l-3.33084-3.991a24.4775,24.4775,0,0,0,7.5611,1.50142,8.28066,8.28066,0,0,0,6.74954-3.15917,7.70229,7.70229,0,0,0,.51556-7.115,14.58851,14.58851,0,0,0-4.58936-5.7385,27.32286,27.32286,0,0,0-25.43066-4.54493,16.32974,16.32974,0,0,0-7.59542,4.8722,9.23578,9.23578,0,0,0-1.86256,8.56086"
        transform="translate(-255 -225.6204)"
        fill="#2f2e41"
      />
      <path
        d="M795.30911,398.22407A75.48468,75.48468,0,0,1,814.446,371.70269c5.292-4.70276,11.47246-8.74308,18.44626-9.9627s14.83309.86982,19.11055,6.51116c3.49772,4.613,4.15213,10.79276,3.76672,16.569s-1.67632,11.49651-1.5527,17.28428a35.46794,35.46794,0,0,0,50.52712,31.351c-6.02152,3.32885-10.714,8.59777-16.3048,12.60812s-12.96272,6.7601-19.31233,4.11012c-6.71812-2.80378-9.79963-10.41937-12.20607-17.28984L846.189,402.2443c-1.82434-5.20852-3.739-10.57156-7.462-14.6454s-9.76461-6.5568-14.88927-4.50871c-3.884,1.55225-6.41275,5.25755-8.63029,8.804s-4.557,7.31984-8.30359,9.17935-9.29837.7147-10.52292-3.28471"
        transform="translate(-255 -225.6204)"
        fill="#2f2e41"
      />
      <rect
        x="802.91902"
        y="543.31418"
        width="9.88235"
        height="46.58824"
        transform="translate(-416.74744 524.13837) rotate(-45.74128)"
        fill="#e6e6e6"
      />
      <path
        d="M821.51005,579.90944a9.88235,9.88235,0,1,0,13.97459-.18081A9.88238,9.88238,0,0,0,821.51005,579.90944Zm10.111,9.8527a4.23529,4.23529,0,1,1-.07749-5.98911A4.2354,4.2354,0,0,1,831.62105,589.76214Z"
        transform="translate(-255 -225.6204)"
        fill="#e6e6e6"
      />
      <path
        d="M784.47962,533.54889l4.69037,4.7178-2.28023,8.096-8.3137,2.02755-4.358-4.38337a11.99891,11.99891,0,1,0,10.26158-10.458Z"
        transform="translate(-255 -225.6204)"
        fill="#e6e6e6"
      />
      <path
        d="M803.736,577.97923a11.579,11.579,0,0,1-1.26831-.07032,11.02341,11.02341,0,0,1-9.68018-9.68115,11.00337,11.00337,0,0,1,10.93115-12.25,10.62483,10.62483,0,0,1,1.46607.10938L838.76332,520.342l-3.50513-11.68457,17.605-7.8916,4.93384,12.06006a20.48972,20.48972,0,0,1-6.17724,23.67236l-36.96119,29.40088a10.25885,10.25885,0,0,1,.06006,1.07861,11.01824,11.01824,0,0,1-3.66382,8.19678A10.87186,10.87186,0,0,1,803.736,577.97923Z"
        transform="translate(-255 -225.6204)"
        fill="#ffb8b8"
      />
      <path
        d="M829.98622,459.08958h0a9.45335,9.45335,0,0,1,12.628,5.1563l17.372,43.84371-27,18Z"
        transform="translate(-255 -225.6204)"
        fill="#2563eb"
      />
      <path
        d="M703.48622,469.32728V250.8827a25.29812,25.29812,0,0,0-25.2623-25.2623H334.95386a25.29813,25.29813,0,0,0-25.2623,25.2623V469.32728a25.29813,25.29813,0,0,0,25.2623,25.2623H678.22392A25.29812,25.29812,0,0,0,703.48622,469.32728ZM334.95386,491.61754a22.31743,22.31743,0,0,1-22.29026-22.29026V250.8827a22.31744,22.31744,0,0,1,22.29026-22.29027H678.22392a22.31744,22.31744,0,0,1,22.29027,22.29027V469.32728a22.31744,22.31744,0,0,1-22.29027,22.29026Z"
        transform="translate(-255 -225.6204)"
        fill="#3f3d56"
      />
      <path
        d="M700.51419,264.92368H312.6636a1.486,1.486,0,1,1,0-2.972H700.51419a1.486,1.486,0,0,1,0,2.972Z"
        transform="translate(-255 -225.6204)"
        fill="#3f3d56"
      />
      <circle cx="82.9259" cy="20.80425" r="7.43009" fill="#2563eb" />
      <circle cx="108.1882" cy="20.80425" r="7.43009" fill="#2563eb" />
      <circle cx="133.4505" cy="20.80425" r="7.43009" fill="#2563eb" />
      <path
        d="M443.97867,424.85206a.99974.99974,0,0,1-1-1c0-10.42675-4.5708-16.24218-11.94531-22.24707a.99992.99992,0,1,1,1.2627-1.55078c7.83007,6.375,12.68261,12.58008,12.68261,23.79785A.99973.99973,0,0,1,443.97867,424.85206Z"
        transform="translate(-255 -225.6204)"
      />
      <path
        d="M358.31656,424.85206a.99974.99974,0,0,1-1-1c0-11.21777,4.85254-17.42285,12.68262-23.79785a.99992.99992,0,1,1,1.2627,1.55078c-7.37452,6.00489-11.94532,11.82129-11.94532,22.24707A.99973.99973,0,0,1,358.31656,424.85206Z"
        transform="translate(-255 -225.6204)"
      />
      <path
        d="M435.41324,356.32179a1,1,0,0,1-.63183-1.77539c7.58056-6.17139,8.19726-14.93848,8.19726-24.92334a1,1,0,0,1,2,0c0,10.50488-.67236,19.74756-8.93457,26.47412A.99381.99381,0,0,1,435.41324,356.32179Z"
        transform="translate(-255 -225.6204)"
      />
      <path
        d="M366.88248,355.78663a.99383.99383,0,0,1-.63086-.22461c-8.26269-6.72705-8.93506-15.72705-8.93506-25.939a1,1,0,0,1,2,0c0,10.03662.60352,18.20557,8.19776,24.38818a1,1,0,0,1-.63184,1.77539Z"
        transform="translate(-255 -225.6204)"
      />
      <path
        d="M456.82828,377.77247H435.41276a1,1,0,0,1,0-2h21.41552a1,1,0,1,1,0,2Z"
        transform="translate(-255 -225.6204)"
      />
      <path
        d="M366.883,377.77247H345.46744a1,1,0,0,1,0-2H366.883a1,1,0,0,1,0,2Z"
        transform="translate(-255 -225.6204)"
      />
      <path
        d="M401.14762,420.56886a.99974.99974,0,0,1-1-1V351.03908a1,1,0,0,1,2,0v68.52978A.99974.99974,0,0,1,401.14762,420.56886Z"
        transform="translate(-255 -225.6204)"
      />
      <path
        d="M401.14762,420.56886A35.30522,35.30522,0,0,1,365.883,385.30372V359.58644c0-16.46826,13.5127-26.69873,35.26465-26.69873,22.74121,0,35.26514,9.48193,35.26514,26.69873v25.71728A35.30533,35.30533,0,0,1,401.14762,420.56886Zm0-85.68115c-16.08106,0-33.26465,6.48828-33.26465,24.69873v25.71728a33.2649,33.2649,0,1,0,66.52979,0V359.58644C434.41276,338.09669,413.571,334.88771,401.14762,334.88771Z"
        transform="translate(-255 -225.6204)"
      />
      <path
        d="M380.651,339.06154a1.0002,1.0002,0,0,1-.9292-.62989,14.08794,14.08794,0,0,1-.98828-4.53173A22.47566,22.47566,0,0,1,401.089,311.49074h.11475a22.46679,22.46679,0,0,1,22.35938,22.35693,12.22735,12.22735,0,0,1-.91016,4.21094,1,1,0,0,1-1.84961-.76075,10.19507,10.19507,0,0,0,.76074-3.4873,20.45407,20.45407,0,0,0-20.36279-20.31982h-.10986a20.46143,20.46143,0,0,0-20.35938,20.36181,12.07569,12.07569,0,0,0,.84717,3.83789,1.00121,1.00121,0,0,1-.92822,1.3711Z"
        transform="translate(-255 -225.6204)"
      />
      <path
        d="M502.71045,333.62308h-12a7,7,0,0,1,0-14h12a7,7,0,0,1,0,14Z"
        transform="translate(-255 -225.6204)"
        fill="#ccc"
      />
      <path
        d="M584.71045,363.12308h-12a7,7,0,0,1,0-14h12a7,7,0,0,1,0,14Z"
        transform="translate(-255 -225.6204)"
        fill="#ccc"
      />
      <path
        d="M622.71045,363.12308h-12a7,7,0,0,1,0-14h12a7,7,0,0,1,0,14Z"
        transform="translate(-255 -225.6204)"
        fill="#ccc"
      />
      <path
        d="M660.71045,363.12308h-12a7,7,0,0,1,0-14h12a7,7,0,0,1,0,14Z"
        transform="translate(-255 -225.6204)"
        fill="#ccc"
      />
      <path
        d="M585.71045,334.12308h-56a7,7,0,0,1,0-14h56a7,7,0,0,1,0,14Z"
        transform="translate(-255 -225.6204)"
        fill="#ccc"
      />
      <path
        d="M546.71045,362.12308h-56a7,7,0,0,1,0-14h56a7,7,0,0,1,0,14Z"
        transform="translate(-255 -225.6204)"
        fill="#ccc"
      />
      <path
        d="M656.71045,334.12308h-43a7,7,0,0,1,0-14h43a7,7,0,0,1,0,14Z"
        transform="translate(-255 -225.6204)"
        fill="#ccc"
      />
      <path
        d="M502.71045,392.62308h-12a7,7,0,0,1,0-14h12a7,7,0,0,1,0,14Z"
        transform="translate(-255 -225.6204)"
        fill="#ccc"
      />
      <path
        d="M585.71045,393.12308h-56a7,7,0,0,1,0-14h56a7,7,0,0,1,0,14Z"
        transform="translate(-255 -225.6204)"
        fill="#ccc"
      />
      <path
        d="M656.71045,393.12308h-43a7,7,0,0,1,0-14h43a7,7,0,0,1,0,14Z"
        transform="translate(-255 -225.6204)"
        fill="#ccc"
      />
      <path
        d="M637.71045,416.62308a7.00786,7.00786,0,0,1,7-7h12a7,7,0,0,1,0,14h-12A7.00785,7.00785,0,0,1,637.71045,416.62308Z"
        transform="translate(-255 -225.6204)"
        fill="#ccc"
      />
      <path
        d="M554.71045,417.12308a7.00786,7.00786,0,0,1,7-7h56a7,7,0,0,1,0,14h-56A7.00785,7.00785,0,0,1,554.71045,417.12308Z"
        transform="translate(-255 -225.6204)"
        fill="#ccc"
      />
      <path
        d="M483.71045,417.12308a7.00786,7.00786,0,0,1,7-7h43a7,7,0,0,1,0,14h-43A7.00785,7.00785,0,0,1,483.71045,417.12308Z"
        transform="translate(-255 -225.6204)"
        fill="#ccc"
      />
    </svg>
  );
};

export default FixingArt;
