import Card from "../UI/Card";
import Tabs from "../UI/Tabs";
import BioTab from "./BioTab";
import PlayerFormTab from "./PlayerFormTab";
import PlayerRankingsTab from "./PlayerRankingsTab";
import PlayerTitlesTab from "./PlayerTitlesTab";
import WinLossTab from "./WinLossTab";

const PlayerDetailTabs = (props) => {
  const isLoading = !props.playerDetail;

  const tabs = !isLoading && {
    Bio: <BioTab bio={props.playerDetail.bio} />,
    Form: (
      <PlayerFormTab
        key={props.matchYear}
        form={props.playerMatches}
        matchYear={props.matchYear}
        years={props.playerDetail.matchYears}
        matchYearChangeHandler={props.matchYearChangeHandler}
      />
    ),
    // Rankings: <PlayerRankingsTab rankings={props.playerDetail.rankings} />,
    ...(props.playerDetail.winLoss && {
      "Win/Loss": <WinLossTab winLoss={props.playerDetail.winLoss} />,
    }),
    ...(!(
      props.playerDetail.titles.Singles === null &&
      props.playerDetail.titles.Doubles === null &&
      props.playerDetail.titles["Mixed Doubles"] === null
    ) && { Titles: <PlayerTitlesTab titles={props.playerDetail.titles} /> }),
  };

  return (
    <Card key={isLoading}>
      <Tabs tabs={tabs} skeletonTab={<BioTab />} />
    </Card>
  );
};

export default PlayerDetailTabs;
