import axios from "axios";
import { getErrorMessage } from "../helpers";

export const getDailySummaries = async (dateDiff, images) => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/scores?dateDiff=${dateDiff}&images=${images}&timezone=${timezone}`
    );
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export const getMatchDetail = async (scoreId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/scores/` + scoreId
    );
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};
