import Skeleton from "../Skeleton";

const PSICategory = (props) => {
  const isLoading = !props.psiCategory;
  if (isLoading) {
    return (
      <Skeleton
        className={`${
          props.rounded === "right" ? "rounded-r-2xl" : "rounded-l-2xl"
        } flex w-1/2 items-center px-5`}
      />
    );
  }

  let colors = null;
  switch (props.psiCategory) {
    case "Poor Performance":
      colors = { bg: "bg-poorPSIColor", text: "text-white" };
      break;
    case "Below Average Performance":
      colors = { bg: "bg-belowAveragePSIColor", text: "text-black" };
      break;
    case "Solid Performance":
      colors = { bg: "bg-solidPSIColor", text: "text-black" };
      break;
    case "Above Average Performance":
      colors = { bg: "bg-aboveAveragePSIColor", text: "text-white" };
      break;
    case "Excellent Performance":
      colors = { bg: "bg-excellentPSIColor", text: "text-white" };
      break;
    default:
      break;
  }
  return (
    <div
      className={`${
        props.rounded === "right" ? "rounded-r-2xl" : "rounded-l-2xl"
      } flex w-1/2 items-center ${colors.bg} ${colors.text} px-5`}
    >
      {props.psiCategory}
    </div>
  );
};

export default PSICategory;
