import axios from "axios";
import { getErrorMessage } from "../helpers";

export const getSearchResult = async (query) => {
  try {
    if (query.length === 0) return;
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/search?query=${query}`
    );
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};
