import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, Outlet, ScrollRestoration } from "react-router-dom";
import NavBar from "../components/UI/Navbar";
import { uiActions } from "../store/ui-slice";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import TrackPageViews from "../TrackPageViews";

const RootLayout = (props) => {
  // const authModal = useSelector((state) => state.ui.authModal);
  // className={authModal && "h-screen overflow-y-hidden"}
  const subdomain = window.location.host.split(".")[0];
  const {
    darkMode: isDarkMode,
    scoresCategoryPicker,
    scoresDatePicker,
    tournamentMonthPicker,
    tournamentTypePicker,
    tournamentDetailYearPicker,
    tournamentDetailTypePicker,
    rankingsCategoryPicker,
    rankingsTypePicker,
    playerDetailTypePicker,
    playerDetailYearPicker,
  } = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDarkMode) {
      document.getElementById("modal").classList.add("dark");
    } else {
      document.getElementById("modal").classList.remove("dark");
    }
  }, [isDarkMode]);

  const closeAllPickers = () => {
    if (
      scoresCategoryPicker ||
      scoresDatePicker ||
      tournamentMonthPicker ||
      tournamentTypePicker ||
      tournamentDetailYearPicker ||
      tournamentDetailTypePicker ||
      rankingsCategoryPicker ||
      rankingsTypePicker ||
      playerDetailTypePicker ||
      playerDetailYearPicker
    ) {
      dispatch(uiActions.closeAllPickers());
    }
  };

  return (
    <div
      className={`text-sm sm:text-base ${isDarkMode ? "dark" : ""}`}
      // className={`flex min-h-screen flex-col ${isDarkMode && "dark"}`}
      onClick={closeAllPickers}
      // onScroll={() => {
      //   console.log("scrolled");
      // }}
    >
      <NavBar
        navItems={
          subdomain.includes("charter")
            ? [
                { name: "Chart", to: "/chart" },
                { name: "Tournaments", to: "/tournaments" },
                // { name: "History", to: "/history" },
              ]
            : [
                { name: "Live Scores", to: "/scores" },
                { name: "Tournaments", to: "/tournaments" },
                { name: "Rankings", to: "/rankings" },
                { name: "Blogs", to: "/blogs" },
              ]
        }
      />
      <div className="min-h-dvh bg-slate-100 pt-16 dark:bg-slate-950 dark:text-white flex flex-col justify-between">
        <main>
          <TrackPageViews />
          <div className="mx-auto max-w-7xl p-3 sm:p-4 md:p-6">
            <Outlet />
          </div>
        </main>

        <footer>
          <div className="py-5 flex flex-col items-center space-y-3">
            <div className="flex justify-center gap-5 text-lg sm:text-xl text-slate-600 dark:text-slate-300">
              <NavLink
                className="dark:hover:text-white hover:text-black"
                to="https://www.facebook.com/psitennis/"
              >
                <FaFacebook />
              </NavLink>
              <NavLink
                className="dark:hover:text-white hover:text-black"
                to="https://twitter.com/PsiTennis"
              >
                <FaXTwitter />
              </NavLink>
              <NavLink
                className="dark:hover:text-white hover:text-black"
                to="https://www.instagram.com/psitennis/"
              >
                <FaInstagram />
              </NavLink>
            </div>
            <p className="text-2xs sm:text-xs text-slate-400">
              ©2024 by Court9 LLC. · {<Link to="/terms">Terms</Link>} ·{" "}
              {<Link to="/privacy">Privacy</Link>}
            </p>
          </div>
        </footer>
      </div>
      <ScrollRestoration
        getKey={(location, matches) => {
          return location.pathname;
        }}
      />
    </div>
  );
};

export default RootLayout;
