import NotFoundArt from "../assets/NotFoundArt";
import EmptyText from "../components/shared/EmptyText";

const NotFoundPage = (props) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-8 px-8 pt-28 pb-8">
      <NotFoundArt className="h-48 w-full" />
      <EmptyText>The page you are looking for does not exist...</EmptyText>
    </div>
  );
};

export default NotFoundPage;
