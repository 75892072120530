import BlogItem from "../components/blogs/BlogItem";
import PageTitle from "../components/shared/PageTitle";

const BlogsPage = (props) => {
  return (
    <>
      <PageTitle>Blogs</PageTitle>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <BlogItem
          to="./tips-for-sports-bettor-2"
          photo="tips_for_sports_bettor_2.jpeg"
          alt=""
          title="A Guide to Professional ATP, WTA, and ITF Tennis Betting: Odds and Tips"
          content="Master professional ATP, WTA, and ITF tennis betting with our comprehensive guide. Learn about different odds, betting markets, and expert tips for success in tennis wagering."
        />
        <BlogItem
          to="./tips-for-sports-bettor-1"
          photo="tips_for_sports_bettor_1.jpeg"
          alt=""
          title="Using PSI Analytics with Details in ATP, WTA, and ITF Tennis Betting"
          content="Explore PSI Tennis Analytics for comprehensive player performance data in tennis. Learn how to leverage this raw data for informed sports betting decisions, considering factors like opponent strength and court conditions."
        />
        <BlogItem
          to="./for-sport-bettors"
          photo="for_sport_bettors.jpeg"
          alt=""
          title="For Sport Bettors"
          content="Discover the future of sports betting with PSI tennis analytics. Gain near real-time insights beyond rankings and ratings for better predictions. Unlock the power of PSI now!"
        />
        <BlogItem
          to="./psi-background"
          photo="psi_background.jpeg"
          alt=""
          title="PSI Background"
          content="Explore the origins and development of PSI Tennis, a revolutionary approach to analyzing player performance in tennis. Learn about the innovative algorithm and 5-tier Performance Grade Index."
        />
        {/* <BlogItem
          to="./psi-metrics"
          photo="psi_metrics.jpeg"
          alt=""
          title="PSI Metrics"
          content="Learn about the measurement metrics used by PSI to measure tennis players' performances, which include ratings, grades, ratio and ratio differential."
        />
        <BlogItem
          to="./psi-performance-measurements"
          photo="psi_performance_measurements.jpeg"
          alt=""
          title="PSI Performance Measurements"
          content="Learn how the 4 key areas of a tennis player's performance (Serve, Groundstroke & Auxiliary, Overall, Return of Serve) are measured."
        /> */}
      </div>
    </>
  );
};

export default BlogsPage;
