import { random, range } from "lodash";
import MatchScores from "../scores/MatchScores";
import MatchItem from "../charter/MatchItem";
import { motion } from "framer-motion";
import { transformRoundName } from "../../helpers";

const MatchesList = (props) => {
  const subdomain = window.location.host.split(".")[0];
  const isLoading = !props.matches;

  return (
    <motion.ul layout className="space-y-1 divide-y dark:divide-slate-600">
      {(isLoading ? range(random(2, 5)) : props.matches).map((match, index) => {
        const round =
          match.season &&
          `${match.season.qualification ? "Qualification - " : ""}${
            match.season.round ? match.season.round : ""
          }`;
        const transformedRoundName = transformRoundName(round);

        const matchLabel = props.tournamentDetail
          ? match.court
          : props.showTournament
            ? `${match.season && match.season.name} - ${transformedRoundName}`
            : transformedRoundName;
        return (
          <motion.li layout key={index}>
            {subdomain.includes("charter") ? (
              <MatchItem
                id={match.id}
                className={index > 0 && "mt-1"}
                status={match.status}
                bestOf={match.bestOf || props.bestOf}
                tournamentType={match.season && match.season.tournamentType}
                startTime={new Date(match.startTime)}
                competitors={match.competitors}
                chartingBy={match.chartingBy}
                charted={match.chartedData}
              />
            ) : (
              <MatchScores
                id={match.id}
                className={index > 0 && "mt-1"}
                isOnMatchDetailModal={props.isOnMatchDetailModal}
                competitors={match.competitors}
                charted={match.chartedData}
                label={matchLabel}
                showStartDate={props.showStartDate}
                showTimeAgo={props.showTimeAgo}
                status={match.status}
                startTime={new Date(match.startTime)}
                gameStats={match.gameStats}
                pointByPoint={match.pointByPoint}
              />
            )}
          </motion.li>
        );
      })}
    </motion.ul>
  );
};

export default MatchesList;
