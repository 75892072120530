import CompetitorName from "./CompetitorName";
import CompetitorPhoto from "./CompetitorPhoto";
import CountryFlag from "./CountryFlag";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { uiActions } from "../../store/ui-slice";
import PlayerRank from "./PlayerRank";

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const PlayerItem = (props) => {
  const dispatch = useDispatch();

  const showPlayerDetail = (player, isDouble) => {
    if (!isDouble) {
      dispatch(uiActions.closeModal());
      props.navigate(`/rankings/${player.id}`);
    } else {
      dispatch(
        uiActions.openDoublePlayerModal({
          player: player,
          navigate: props.navigate,
        })
      );
    }
  };

  return (
    <div
      className="flex justify-between items-center rounded-xl px-2 py-4 cursor-pointer bg-slate-100 dark:bg-slate-800"
      onClick={() => {
        showPlayerDetail(props.player, props.isDouble);
      }}
    >
      <div className="flex gap-4">
        <CompetitorPhoto
          id={props.player.id}
          image={props.player.image}
          alt={props.player.name}
          className="h-14 w-14 sm:h-16 sm:w-16"
        />
        {props.isDouble ? (
          <motion.div layout className="space-y-2">
            <CompetitorName
              withSeed
              competitor={{ ...props.player, isDouble: true }}
              className="font-bold"
            />
            <div className="flex items-center gap-2">
              <CountryFlag
                className="w-6"
                country={props.player.country[0] || ""}
              />
              <p className="text-xs sm:text-sm">
                {countries.getAlpha3Code(props.player.country[0], "en")}
              </p>
              <p>/</p>
              <CountryFlag
                className="w-6"
                country={props.player.country[1] || ""}
              />
              <p className="text-xs sm:text-sm">
                {countries.getAlpha3Code(props.player.country[1], "en")}
              </p>
            </div>
          </motion.div>
        ) : (
          <motion.div layout className="space-y-2">
            <CompetitorName
              withSeed
              competitor={props.player}
              className="font-bold"
            />
            <div className="flex items-center gap-2">
              <CountryFlag className="w-6" country={props.player.country} />
              <p className="text-xs sm:text-sm">
                {countries.getAlpha3Code(props.player.country, "en")}
              </p>
            </div>
          </motion.div>
        )}
      </div>
      {props.player.rank && <PlayerRank rank={props.player.rank} />}
    </div>
  );
};

export default PlayerItem;
