import { HiUser } from "react-icons/hi";
import Skeleton from "./Skeleton";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const SinglePlayerPhoto = (props) => {
  const isLoading = props.image === null;

  if (isLoading) {
    return <Skeleton className={`rounded-full ${props.className}`} />;
  }

  const content = props.image ? (
    <motion.img
      layout
      src={`data:image/jpeg;base64,${props.image}`}
      alt={props.alt}
      className={`rounded-full ${props.className}`}
    />
  ) : (
    <HiUser
      className={`${props.className} rounded-full dark:bg-slate-700 bg-slate-200 p-3 text-slate-400`}
    />
  );

  if (props.clickable) {
    return (
      <Link className="hover:opacity-75" to={`/rankings/${props.id}`}>
        {content}
      </Link>
    );
  }

  return content;
};

const CompetitorPhoto = (props) => {
  const isLoading = props.image === null;

  if (isLoading)
    return <SinglePlayerPhoto image={null} className={props.className} />;

  const isDouble = Array.isArray(props.image);
  if (isDouble) {
    return (
      <div className="flex justify-center">
        {props.image.map((image, index) => (
          <SinglePlayerPhoto
            className={`${props.className}  ${
              index === 0 ? "relative left-2" : "relative -left-2"
            }`}
            id={props.id.split("-")[index]}
            alt={props.alt[index]}
            image={image}
            clickable={props.clickable}
          />
        ))}
      </div>
    );
  }
  return (
    <SinglePlayerPhoto
      className={props.className}
      id={props.id}
      alt={props.alt}
      image={props.image}
      clickable={props.clickable}
    />
  );
};

export default CompetitorPhoto;
