import { motion } from "framer-motion";

const Card = (props) => {
  return props.animated ? (
    <motion.div
      layout
      className={`bg-white dark:bg-slate-900 ${
        props.overflow || "overflow-auto"
      } ${props.className}`}
      ref={props.innerRef}
      style={
        props.bottomModal
          ? { borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }
          : { borderRadius: "16px" }
      }
      onClick={props.onClick}
      initial={props.initial}
      animate={props.animate}
      exit={props.exit}
      whileHover={props.whileHover}
    >
      {props.children}
    </motion.div>
  ) : (
    <div
      className={`bg-white dark:bg-slate-900 ${
        props.overflow || "overflow-auto"
      } ${props.className}`}
      ref={props.innerRef}
      style={
        props.bottomModal
          ? { borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }
          : { borderRadius: "16px" }
      }
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

export default Card;
