import { AnimatePresence } from "framer-motion";
import { useEffect, useRef } from "react";
import { useIsFetching } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import useViewport from "../../hooks/use-viewport";
import { uiActions } from "../../store/ui-slice";
import DarkModeButton from "../shared/main-header/DarkModeButton";
import MenuButton from "../shared/main-header/MenuButton";
import NavItems from "./NavItems";
import SearchButton from "../shared/main-header/SearchButton";
import PsiLogo from "../shared/main-header/PsiLogo";

const NavBar = (props) => {
  const subdomain = window.location.host.split(".")[0];
  const ref = useRef();
  const showMenu = useSelector((state) => state.ui.showMenu);
  const isLoading = useIsFetching({
    predicate: (query) => query.state.status === "loading",
  });
  const dispatch = useDispatch();

  const { width } = useViewport();
  const breakpoint = 1024;
  const isMobile = width < breakpoint;

  useEffect(() => {
    if (isLoading) {
      ref.current.continuousStart();
    } else {
      ref.current.complete();
    }
  }, [isLoading]);

  const menuClickedHandler = (e) => {
    e.stopPropagation();
    dispatch(uiActions.toggleMenu());
  };

  return (
    <nav className="fixed top-0 z-20 h-14 w-full border-b border-gray-200 bg-white bg-opacity-50 backdrop-blur-lg backdrop-filter dark:border-slate-700 dark:bg-slate-800 dark:bg-opacity-5">
      <div className="flex h-full w-full items-center justify-between px-3 sm:px-4 md:px-8">
        <div className="flex-1">
          <PsiLogo />
        </div>
        {!isMobile && (
          <NavItems navItems={props.navItems} isMobile={isMobile} />
        )}
        <div className="flex flex-1 justify-end gap-3">
          {!subdomain.includes("charter") && <SearchButton />}
          {!isMobile && <DarkModeButton />}
          {/* <AuthButton /> */}
          {isMobile && (
            <div className="relative">
              <MenuButton onClick={menuClickedHandler} />
              <AnimatePresence>
                {isMobile && showMenu && (
                  <NavItems navItems={props.navItems} isMobile={isMobile} />
                )}
              </AnimatePresence>
            </div>
          )}
        </div>
      </div>
      <LoadingBar color="#2563eb" ref={ref} />
    </nav>
  );
};

export default NavBar;
