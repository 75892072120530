import { FaChevronDown } from "react-icons/fa";
import { useIsFetching } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/ui-slice";
import Skeleton from "../shared/Skeleton";
import { motion } from "framer-motion";

const Picker = (props) => {
  const isLoading = useIsFetching({
    predicate: (query) => query.state.status === "loading",
  });
  const isExpanded = useSelector((state) => state.ui[props.name]);
  const dispatch = useDispatch();

  const toggleExpandedState = (e) => {
    e.stopPropagation();
    dispatch(uiActions.togglePicker(props.name));
  };

  // skeleton state
  if (isLoading) {
    return (
      <Skeleton
        baseBackground={props.baseBackground}
        className="h-10 w-full rounded-lg md:w-36"
      />
    );
  }

  const pickerVariants = {
    closed: {
      scale: 0,
      opacity: 0,
    },
    open: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.4,
        delayChildren: 0.2,
        staggerChildren: 0.05,
      },
    },
  };

  return (
    <div className="relative w-full md:w-max" onClick={toggleExpandedState}>
      <div
        className={`flex h-10 cursor-pointer items-center justify-center gap-2 rounded-lg px-4 ${
          props.pickerBgColor || "bg-white dark:bg-slate-900"
        }`}
      >
        {props.icon}
        {props.activeOption}
        <motion.div
          variants={{ open: { rotate: 180 }, closed: { rotate: 0 } }}
          animate={isExpanded ? "open" : "closed"}
          transition={{ duration: 0.2 }}
        >
          {<FaChevronDown />}
        </motion.div>
      </div>

      <motion.div
        className={`absolute top-12 left-0 z-10 w-full origin-top overflow-auto rounded-lg bg-white py-2 px-4 text-center shadow-lg dark:bg-slate-800 sm:min-w-max ${
          props.maxDropdownHeight || "max-h-96"
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        variants={pickerVariants}
        animate={isExpanded ? "open" : "closed"}
        initial="closed"
        exit="closed"
      >
        {props.options.map((option, index) => {
          const isActive = option === props.activeOption;
          return (
            <div
              key={option}
              className={`cursor-pointer rounded-lg py-2 px-4 ${
                isActive
                  ? "bg-primary text-white"
                  : "hover:bg-slate-100 dark:hover:bg-slate-700"
              }`}
              onClick={() => {
                dispatch(uiActions.closeAllPickers());
                props.onOptionChange(props.options, index);
              }}
            >
              {option}
            </div>
          );
        })}
      </motion.div>
    </div>
  );
};

export default Picker;
