import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";

const WinLossPercent = (props) => {
  const darkMode = useSelector((state) => state.ui.darkMode);

  let color;
  switch (props.surface) {
    case "Overall":
      color = "#64748b";
      break;
    case "Hard":
      color = "#38bdf8";
      break;
    case "Clay":
      color = "#f97316";
      break;
    case "Grass":
      color = "#22c55e";
      break;
    default:
      break;
  }

  return (
    <div className="w-36 h-36 md:w-48 md:h-48">
      <CircularProgressbarWithChildren
        value={props.percent || 0}
        strokeWidth={7}
        styles={buildStyles({
          pathColor: color,
          trailColor: darkMode ? "#334155" : "#e2e8f0",
        })}
      >
        <p className="text-lg md:text-xl font-bold">{props.surface}</p>
        <p className="font-number text-xl md:text-2xl font-bold">
          {props.percent === null ? "-" : props.percent}%
        </p>
        <p>
          {props.win} / {props.loss}
        </p>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default WinLossPercent;
