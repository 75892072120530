import { useDispatch, useSelector } from "react-redux";
import MatchDetailBanner from "../shared/match-details/MatchDetailBanner";
import MatchDetailTabs from "../shared/match-details/MatchDetailTabs";
import Modal from "../UI/Modal";
import { matchDetailTransformer } from "../../api/chart-api";
import { useQuery, useQueryClient } from "react-query";
import useViewport from "../../hooks/use-viewport";
import { GAME_SCORE_NUMBERS } from "../../constants";
import { getMatchDetail } from "../../api/scores-api";
import { useEffect } from "react";
import { matchDetailDataChangeHandlerFactory } from "../../helpers";
import io from "socket.io-client";
import { BiChevronRight } from "react-icons/bi";
import { uiActions } from "../../store/ui-slice";

const chartingMatchDetailQuery = (matchPresentState) => ({
  queryKey: ["chart", matchPresentState.chartingMatchId],
  queryFn: async () => matchDetailTransformer(matchPresentState),
});

const matchDetailQuery = (scoreId) => ({
  queryKey: ["scores", scoreId],
  queryFn: async () => getMatchDetail(scoreId),
  staleTime: 300_000,
});

const MatchDetailModal = (props) => {
  const subdomain = window.location.host.split(".")[0];
  const isChartingSite = subdomain.includes("charter");
  const { width } = useViewport();
  const breakpoint = 640;
  const isMobile = width < breakpoint;

  const matchPresentState = useSelector((state) => state.chart.present);
  const { home, away, startTime, season, scoreId, navigate } = useSelector(
    (state) => state.ui.matchDetailModal
  );

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    data: matchDetail,
    isFetched,
    isLoading,
    error,
  } = useQuery(
    isChartingSite
      ? chartingMatchDetailQuery({
          ...matchPresentState,
          competitors: { home: home, away: away },
          startTime: startTime,
        })
      : matchDetailQuery(scoreId)
  );

  useEffect(() => {
    if (isChartingSite || !isFetched) return;
    document.title = `${matchDetail.competitors[0].name} vs ${matchDetail.competitors[1].name} - ${matchDetail.season.name} - PSI Tennis Analytics`;

    const dataChangeHandler = matchDetailDataChangeHandlerFactory(
      queryClient,
      scoreId
    );

    const socket = io(process.env.REACT_APP_BACKEND_URL);

    // Listen for 'dataChange' events from the server
    socket.on("dataChange", dataChangeHandler);
    // Clean up the socket connection on unmount
    return () => {
      socket.off("dataChange", dataChangeHandler);
      socket.disconnect();
    };
  }, [queryClient, isFetched, scoreId]);

  const matchStatus = isChartingSite
    ? matchPresentState.isFinished
      ? "Finished"
      : `Set ${matchPresentState.currentSet}`
    : matchDetail && matchDetail.status;
  const competitorsWithChartedScores = isChartingSite && [
    {
      ...home,
      periodScores: matchPresentState.periodScores.map((period) => ({
        score: period.home_score,
        tiebreakScore: period.home_tiebreak_score,
      })),
      gameScore: GAME_SCORE_NUMBERS[matchPresentState.gameScores.home],
      totalScore: matchPresentState.setScores.home,
      serve: matchPresentState.server === "home",
    },
    {
      ...away,
      periodScores: matchPresentState.periodScores.map((period) => ({
        score: period.away_score,
        tiebreakScore: period.away_tiebreak_score,
      })),
      gameScore: GAME_SCORE_NUMBERS[matchPresentState.gameScores.away],
      totalScore: matchPresentState.setScores.away,
      serve: matchPresentState.server === "away",
    },
  ];

  const navigateToMatchDetailPage = () => {
    dispatch(uiActions.closeModal());
    navigate(`/scores/${scoreId}`);
  };

  return (
    <Modal
      bottomModal={isMobile}
      header="Match Detail"
      action={<BiChevronRight className="text-2xl cursor-pointer" />}
      actionCallback={navigateToMatchDetailPage}
      className="h-[92dvh]"
    >
      <MatchDetailBanner
        isOnMatchDetailModal
        key={isLoading}
        status={matchStatus}
        startTime={
          isChartingSite ? startTime : matchDetail && matchDetail.startTime
        }
        competitors={
          isChartingSite
            ? competitorsWithChartedScores
            : matchDetail && matchDetail.competitors
        }
        season={isChartingSite ? season : matchDetail && matchDetail.season}
        dewPoint={matchDetail && matchDetail.dewPoint}
        pastPsi={matchDetail && matchDetail.pastPsi}
      />
      <MatchDetailTabs
        isOnMatchDetailModal
        competitors={
          isChartingSite
            ? competitorsWithChartedScores
            : matchDetail && matchDetail.competitors
        }
        status={matchStatus}
        // startTime={matchDetail && new Date(matchDetail.startTime)}
        // court={matchDetail && matchDetail.court}
        psi={
          matchDetail &&
          (isChartingSite
            ? matchDetail.psi
            : matchDetail.chartedData && matchDetail.chartedData.psi)
        }
        pastPsi={!isChartingSite && matchDetail && matchDetail.pastPsi}
        gameStats={!isChartingSite && matchDetail && matchDetail.gameStats}
        ways={
          matchDetail &&
          (isChartingSite
            ? matchDetail.ways
            : matchDetail.chartedData && matchDetail.chartedData.ways)
        }
        misses={
          matchDetail &&
          (isChartingSite
            ? matchDetail.misses
            : matchDetail.chartedData && matchDetail.chartedData.misses)
        }
        pointByPoint={
          matchDetail &&
          (matchDetail.pointByPoint ||
            (matchDetail.chartedData && matchDetail.chartedData.pointByPoint))
        }
        h2h={!isChartingSite && matchDetail && matchDetail.h2h}
        form={!isChartingSite && matchDetail && matchDetail.form}
        note={
          matchDetail &&
          (isChartingSite
            ? matchDetail.note
            : matchDetail.chartedData && matchDetail.chartedData.note)
        }
      />
    </Modal>
  );
};

export default MatchDetailModal;
