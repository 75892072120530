import Skeleton from "../shared/Skeleton";

const CardSubheader = (props) => {
  const isLoading = !props.name;
  if (isLoading) return <Skeleton className="h-9 w-full rounded-md" />;
  return (
    <div className="mb-4 w-full rounded-md bg-slate-100 p-2 text-center dark:bg-slate-800">
      {props.name}
    </div>
  );
};

export default CardSubheader;
