import { nameTransformer } from "../../helpers";
import CountryFlag from "./CountryFlag";
import Skeleton from "./Skeleton";

const SinglePlayerName = (props) => {
  const isLoading = !props.competitor;

  //   if (props.competitor === undefined) {
  //     return <div />;
  //   }

  //   if (isLoading)
  //     return (
  //       <Skeleton
  //         darkBgColor="dark:bg-slate-700"
  //         className="h-3 w-40 rounded-full"
  //       />
  //     );

  const firstLastNames = nameTransformer(
    props.competitor && props.competitor.name,
    "list"
  );

  return (
    <div className="flex items-center gap-2">
      {props.withFlag && (
        <CountryFlag
          country={props.competitor ? props.competitor.country || "" : null}
          className="w-6"
        />
      )}
      {isLoading ? (
        <Skeleton className="h-3 w-24 rounded-full sm:w-40" />
      ) : (
        <p
          className={`${props.centered && "text-center"} ${
            props.isWinner === false && "text-slate-400"
          }`}
        >
          {firstLastNames
            ? firstLastNames[0]
              ? props.isMobile || props.shortName
                ? nameTransformer(props.competitor.name, "short")
                : nameTransformer(props.competitor.name, "full")
              : firstLastNames[1]
            : "null"}
        </p>
      )}
    </div>
  );
};

export default SinglePlayerName;
