import PointByPointList from "./PointByPointList";
import { useDispatch, useSelector } from "react-redux";
import Filters from "../../UI/Filters";
import { pagesStatesActions } from "../../../store/pages-states-slice";
import { camelCase, startCase } from "lodash";

const PointByPointTab = (props) => {
  const activeSet = useSelector(
    (state) => state.pagesStates.pointByPointActiveSet
  );
  const dispatch = useDispatch();

  const setChangeHandler = (newSet) => {
    dispatch(
      pagesStatesActions.changeActiveSet({
        tab: "pointByPoint",
        activeSet: newSet,
      })
    );
  };

  return (
    <div className="space-y-8">
      <Filters
        filtersList={Object.keys(props.pointByPoint).map((set) =>
          startCase(set)
        )}
        activeItem={activeSet}
        onActiveItemChange={setChangeHandler}
        className="justify-start sm:justify-center"
      />
      <PointByPointList
        pointByPointList={props.pointByPoint[camelCase(activeSet)]}
      />
    </div>
  );
};

export default PointByPointTab;
