import { MdDarkMode, MdLightMode } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../../store/ui-slice";

const DarkModeButton = (props) => {
  const isDarkMode = useSelector((state) => state.ui.darkMode);
  const dispatch = useDispatch();

  const clickHandler = () => {
    dispatch(uiActions.toggleDarkMode());
  };

  return (
    <button
      className="rounded-lg p-2.5 text-xl text-slate-500 hover:bg-slate-100 dark:text-slate-400 dark:hover:bg-slate-800 dark:hover:text-white hover:text-primary"
      onClick={clickHandler}
    >
      {isDarkMode ? <MdLightMode /> : <MdDarkMode />}
    </button>
  );
};

export default DarkModeButton;
