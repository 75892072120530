import { camelCase, startCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { pagesStatesActions } from "../../../store/pages-states-slice";
import Filters from "../../UI/Filters";
import StatList from "./StatList";

const StatisticsTab = (props) => {
  const activeSet = useSelector(
    (state) => state.pagesStates.statisticsActiveSet
  );
  const dispatch = useDispatch();

  const setChangeHandler = (newSet) => {
    dispatch(
      pagesStatesActions.changeActiveSet({
        tab: "statistics",
        activeSet: newSet,
      })
    );
  };

  const filtersList = Object.keys(props.statistics).map((set) =>
    startCase(set)
  );

  return (
    <div className="space-y-8">
      {filtersList.length > 1 && (
        <Filters
          filtersList={filtersList}
          activeItem={activeSet}
          onActiveItemChange={setChangeHandler}
          className="justify-start sm:justify-center"
        />
      )}
      <StatList statsList={props.statistics[camelCase(activeSet)]} />
    </div>
  );
};

export default StatisticsTab;
