import { useSelector } from "react-redux";

const Number = (props) => {
  const isDarkMode = useSelector((state) => state.ui.darkMode);
  let className;

  if (props.background) {
    className = isDarkMode
      ? `w-8 h-8 sm:w-10 sm:h-10 rounded-lg flex items-center justify-center ${props.sign === "+" ? "text-green-400 bg-green-950" : props.sign === "-" ? "text-red-400 bg-red-950" : "text-slate-300 bg-slate-800"}`
      : `w-8 h-8 sm:w-10 sm:h-10 rounded-lg flex items-center justify-center ${props.sign === "+" ? "text-green-500 bg-green-100" : props.sign === "-" ? "text-red-500 bg-red-100" : "text-slate-400 bg-slate-100"}`;
  } else {
    className =
      props.sign === "+"
        ? "text-green-500"
        : props.sign === "-"
          ? "text-red-500"
          : "text-slate-400";
  }
  return <div className={`font-number ${className}`}>{props.children}</div>;
};

export default Number;
