import Skeleton from "../Skeleton";
import PSICategory from "./PSICategory";

const PSIItem = (props) => {
  const isLoading = !props.name;
  if (props.name === "Return of Serve") {
    return (
      <li className="grid grid-cols-[2.5rem_1fr_2.5rem] items-center gap-x-1 sm:gap-x-2">
        <p className="text-right font-number w-10">
          {props.home.value.toFixed(2)}
        </p>
        <p className="text-center text-lg sm:text-xl font-semibold flex-grow">
          {props.name}
        </p>
        <p className="text-left font-number w-10">
          {props.away.value.toFixed(2)}
        </p>
      </li>
    );
  } else {
    return (
      <li className="space-y-1">
        {isLoading ? (
          <div className="flex justify-center">
            <Skeleton className="w-24 h-6 rounded-full" />
          </div>
        ) : (
          <p className="text-center text-lg sm:text-xl font-semibold">
            {props.name}
          </p>
        )}
        <div className="grid grid-cols-[2.5rem_1fr_2.5rem] items-center gap-x-1 sm:gap-x-2">
          {isLoading ? (
            <Skeleton className="w-10 h-4 rounded-full" />
          ) : (
            <p className="text-right font-number w-10">
              {props.home.value.toFixed(2)}
            </p>
          )}
          <div className="flex h-20 flex-grow">
            <PSICategory
              rounded="left"
              psiCategory={props.home && props.home.category}
            />
            <PSICategory
              rounded="right"
              psiCategory={props.away && props.away.category}
            />
          </div>
          {isLoading ? (
            <Skeleton className="w-10 h-4 rounded-full" />
          ) : (
            <p className="text-left font-number w-10">
              {props.away.value.toFixed(2)}
            </p>
          )}
        </div>
      </li>
    );
  }
};

export default PSIItem;
