import { combineReducers, configureStore } from "@reduxjs/toolkit";
import uiReducer from "./ui-slice";
import pagesStatesReducer from "./pages-states-slice";
import chartReducer from "./chart-slice";
import undoable from "redux-undo";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["pagesStates", "ui"],
};

const uiPersistConfig = {
  key: "ui",
  storage,
  whitelist: ["darkMode"],
  // blacklist: [
  //   "showMenu",
  //   "authModal",
  //   "matchSettingsModal",
  //   "matchDetailModal",
  //   "scoresDatePicker",
  //   "tournamentMonthPicker",
  //   "tournamentTypePicker",
  //   "tournamentDetailYearPicker",
  //   "tournamentDetailTypePicker",
  //   "rankingsAtpWtaPicker",
  //   "rankingsTypePicker",
  // ],
};

const rootReducer = combineReducers({
  ui: persistReducer(uiPersistConfig, uiReducer),
  pagesStates: pagesStatesReducer,
  chart: undoable(chartReducer),
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);
