import { HiMenu } from "react-icons/hi";

const MenuButton = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      className="inline-flex items-center rounded-lg p-2 dark:hover:text-white hover:text-primary text-sm text-slate-500 hoverable:hover:bg-slate-100 dark:text-slate-400 dark:hoverable:hover:bg-slate-800"
    >
      <span className="sr-only">Open main menu</span>
      <HiMenu className="h-6 w-6" />
    </button>
  );
};

export default MenuButton;
