const ScoreItem = (props) => {
  if (props.tiebreakScore !== undefined) {
    return (
      <div className={`flex font-number ${props.className}`}>
        <p className="mr-0.5">{props.setScore}</p>
        <p className="relative bottom-1 text-2xs sm:bottom-0 sm:text-xs">
          {props.tiebreakScore}
        </p>
      </div>
    );
  }
  return <p className={`${props.className} font-number`}>{props.setScore}</p>;
};

export default ScoreItem;
