import { defer, useParams } from "react-router-dom";
import { getPlayerDetail } from "../api/rankings-api";
import { useQuery } from "react-query";
import PlayerSummary from "../components/rankings/PlayerSummary";
import PlayerDetailTabs from "../components/rankings/PlayerDetailTabs";
import { useEffect, useState } from "react";

const playerDetailQuery = (playerId, matchYear) => ({
  queryKey: matchYear
    ? ["players", playerId, matchYear]
    : ["players", playerId],
  queryFn: async () => getPlayerDetail(playerId, matchYear),
});

const PlayerDetailPage = (props) => {
  const { playerId } = useParams();
  const [matchYear, setMatchYear] = useState(-1);
  const {
    data: playerDetail,
    isFetched,
    error,
  } = useQuery(playerDetailQuery(playerId));
  const {
    data: playerMatches,
    playerMatchesIsFetched,
    playerMatchesError,
  } = useQuery(playerDetailQuery(playerId, matchYear));

  if (error || playerMatchesError) {
    throw new Error(error);
  }
  const isGhostPlayer = playerDetail && Object.keys(playerDetail).length === 1;

  useEffect(() => {
    if (!isFetched) return;
    document.title = `${playerDetail.name} - PSI Tennis Analytics`;
    if (!isGhostPlayer) setMatchYear(playerDetail.matchYears[0]);
  }, [isFetched, playerDetail]);

  const matchYearChangeHandler = (years, newIndex) => {
    setMatchYear(years[newIndex]);
  };

  const pageContent = isGhostPlayer ? (
    <PlayerSummary name={playerDetail && playerDetail.name} />
  ) : (
    <>
      <PlayerSummary
        name={playerDetail && playerDetail.name}
        country={playerDetail ? playerDetail.country : null}
        image={playerDetail ? playerDetail.image : null}
        rankings={playerDetail && playerDetail.rankings}
      />
      <PlayerDetailTabs
        playerDetail={playerDetail}
        playerMatches={playerMatches}
        matchYear={matchYear}
        matchYearChangeHandler={matchYearChangeHandler}
      />
    </>
  );

  return (
    <div className="space-y-3 sm:space-y-4 md:space-y-6">{pageContent}</div>
  );
};

export default PlayerDetailPage;

export const loader = (queryClient) => {
  return async ({ params }) => {
    const playerId = params.playerId;
    return defer({
      promise:
        queryClient.getQueryData(playerDetailQuery(playerId)) ??
        queryClient.fetchQuery(playerDetailQuery(playerId)),
    });
  };
};
