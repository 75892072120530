import TournamentResultsTab from "./TournamentResultsTab";
import TournamentDrawsTab from "./TournamentDrawsTab";
import TournamentPlayersTab from "./TournamentPlayersTab";
import Card from "../UI/Card";
import Tabs from "../UI/Tabs";

const TournamentDetailTabs = (props) => {
  const subdomain = window.location.host.split(".")[0];
  const { tournamentDetail } = props;
  const isLoading = !tournamentDetail;

  const tabs = !isLoading && {
    Results: (
      <TournamentResultsTab
        rounds={tournamentDetail && tournamentDetail.seasonMatches}
        bestOf={tournamentDetail && tournamentDetail.bestOf}
      />
    ),
    ...(!subdomain.includes("charter") && {
      Draws: (
        <TournamentDrawsTab
          isDouble={tournamentDetail && tournamentDetail.type === "Doubles"}
          rounds={tournamentDetail && tournamentDetail.seasonMatches}
          surface={tournamentDetail && tournamentDetail.surface}
        />
      ),
    }),
    ...(!subdomain.includes("charter") &&
      tournamentDetail &&
      tournamentDetail.players && {
        Players: (
          <TournamentPlayersTab
            isDouble={tournamentDetail && tournamentDetail.type === "Doubles"}
            players={tournamentDetail && tournamentDetail.players}
          />
        ),
      }),
    // ...(!subdomain.includes("charter") && {
    //   "Past Winners": <TournamentPastWinnersTab />,
    // }),
  };
  return (
    <Card
      key={
        tournamentDetail && `${tournamentDetail.year}${tournamentDetail.type}`
      }
    >
      <Tabs tabs={tabs} skeletonTab={<TournamentResultsTab />} />
    </Card>
  );
};

export default TournamentDetailTabs;
