import { LayoutGroup } from "framer-motion";
import TournamentsGroupItem from "./TournamentsGroupItem";

const TournamentsGroupList = (props) => {
  return (
    <LayoutGroup>
      <ul className="mt-5 space-y-5">
        {props.tournaments.map((tournamentsGroup) => {
          return (
            <li key={tournamentsGroup.name}>
              <TournamentsGroupItem
                category={tournamentsGroup.name}
                tournaments={tournamentsGroup.tournaments}
              />
            </li>
          );
        })}
      </ul>
    </LayoutGroup>
  );
};

export default TournamentsGroupList;
