import { GiTennisCourt } from "react-icons/gi";
import { MdSportsTennis } from "react-icons/md";
import Picker from "../UI/Picker";

const RankingsFilter = (props) => {
  return (
    <div className="flex gap-5">
      {/* <Filters
    filtersList={["ATP", "WTA"]}
    activeItem={activeRankingType}
    onActiveItemChange={activeRankingTypeChangeHandler}
  /> */}
      <Picker
        baseBackground
        name="rankingsCategoryPicker"
        icon={<GiTennisCourt />}
        options={["ATP", "WTA"]}
        activeOption={props.tournamentCategory}
        onOptionChange={props.tournamentCategoryChangeHandler}
      />
      <Picker
        baseBackground
        name="rankingsTypePicker"
        icon={<MdSportsTennis />}
        options={["Singles", "Singles Race", "Doubles"]}
        activeOption={props.tournamentType}
        onOptionChange={props.tournamentTypeChangeHandler}
      />
    </div>
  );
};

export default RankingsFilter;
