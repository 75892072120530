import { useSelector } from "react-redux";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import { motion } from "framer-motion";

const AlertModal = (props) => {
  const { header, message, actions } = useSelector(
    (state) => state.ui.alertModal
  );

  return (
    <Modal width="w-4/5" maxWidth="max-w-2xl" header={header}>
      {/* <motion.div
        layout
        className="grid grid-cols-[2rem_1fr_2rem] grid-rows-1 items-center pb-3"
      >
        <div />
        <h2 className="text-center text-lg sm:text-xl font-bold">{header}</h2>
        <MdOutlineClose
          className="cursor-pointer justify-self-end text-2xl"
          onClick={closeModalHandler}
        />
      </motion.div> */}
      <div className="px-6 pb-6">
        <motion.p layout className="text-center text-slate-400">
          {message}
        </motion.p>
        {actions &&
          actions.map((action) => (
            <Button className="mt-3 w-full" onClick={action.onClick}>
              {action.name}
            </Button>
          ))}
      </div>
    </Modal>
  );
};

export default AlertModal;
