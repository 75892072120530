import { useRouteError } from "react-router-dom";
import FixingArt from "../assets/FixingArt";
import EmptyText from "../components/shared/EmptyText";

const ErrorPage = (props) => {
  const error = useRouteError();
  return (
    <div className="flex flex-col items-center justify-center space-y-8 px-8 pt-28 pb-8">
      <FixingArt className="h-48 w-full" />
      <EmptyText className="text-center">
        An error occured!
        <br />
        {error.message}
        <br />
        We will fix this ASAP.
      </EmptyText>
    </div>
  );
};

export default ErrorPage;
