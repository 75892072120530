import { LayoutGroup } from "framer-motion";
import LiveTournamentItem from "./LiveTournamentItem";
import { random, range } from "lodash";

const LiveTournamentsList = (props) => {
  const isLoading = props.ongoingTournaments === null;
  return (
    // <Skeleton className="h-3 w-10" />
    <LayoutGroup>
      <ul className="mt-5 space-y-5">
        {(isLoading ? range(random(3, 6)) : props.ongoingTournaments).map(
          (tournamentData, index) => (
            <LiveTournamentItem
              key={index}
              id={tournamentData.id}
              isLoading={props.isLoading}
              title={`${tournamentData.category} - ${tournamentData.matchType}: ${tournamentData.name} (${tournamentData.location}), ${tournamentData.surface}`}
              surface={tournamentData.surface}
              category={tournamentData.category}
              matches={tournamentData.matches}
            />
          )
        )}
      </ul>
    </LayoutGroup>
  );
};

export default LiveTournamentsList;
