import { random, range } from "lodash";
import TournamentItem from "./TournamentItem";
import { motion } from "framer-motion";

const TournamentItemsList = (props) => {
  return (
    <motion.ul layout className="space-y-1 divide-y dark:divide-slate-600">
      {(props.tournaments || range(random(3, 6))).map((tournament, index) => (
        <motion.li layout key={index}>
          {
            <TournamentItem
              className={index > 0 && "mt-1"}
              id={tournament.id}
              name={tournament.name}
              category={tournament.category}
              level={tournament.level}
              startDate={tournament.startDate}
              endDate={tournament.endDate}
              location={tournament.location}
              city={tournament.city}
              surface={tournament.surface}
              defaultSeason={tournament.defaultSeason}
              isSearchResult={props.isSearchResult}
              isCharted={tournament.isCharted}
              isOngoing={tournament.isOngoing}
            />
          }
        </motion.li>
      ))}
    </motion.ul>
  );
};

export default TournamentItemsList;
