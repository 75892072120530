import { range } from "lodash";
import { getWinner } from "../../helpers";
import CompetitorName from "../shared/CompetitorName";
import ScoreItem from "../shared/ScoreItem";
import Label from "../UI/Label";
import { MdDewPoint, MdInfo } from "react-icons/md";
import { uiActions } from "../../store/ui-slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const PsiScoreItem = (props) => {
  let bgColor;
  if (props.category === "Poor Performance") {
    bgColor = "bg-poorPSIColor";
  } else if (props.category === "Below Average Performance") {
    bgColor = "bg-belowAveragePSIColor";
  } else if (props.category === "Solid Performance") {
    bgColor = "bg-solidPSIColor";
  } else if (props.category === "Above Average Performance") {
    bgColor = "bg-aboveAveragePSIColor";
  } else if (props.category === "Excellent Performance") {
    bgColor = "bg-excellentPSIColor";
  }

  const textColor = [
    "bg-poorPSIColor",
    "bg-aboveAveragePSIColor",
    "bg-excellentPSIColor",
  ].includes(bgColor)
    ? "text-white"
    : "dark:text-black";
  return (
    <div className="flex justify-between gap-1">
      <p>{props.name}</p>
      <div
        className={`flex h-5 w-10 items-center justify-center rounded ${props.name !== "R" ? bgColor : "bg-slate-200 dark:bg-slate-600"} ${props.name !== "R" && textColor}`}
      >
        {props.score.toFixed(2)}
      </div>
    </div>
  );
};

const PsiScoresList = (props) => {
  return (
    <div
      className={`flex flex-col justify-center gap-0.5 border-l px-1.5 text-xs dark:border-slate-600 ${props.className}`}
    >
      {props.psi.match[0][props.competitor].value !== null && (
        <PsiScoreItem
          name="S"
          score={props.psi.match[0][props.competitor].value}
          category={props.psi.match[0][props.competitor].category}
        />
      )}
      {props.psi.match[1][props.competitor].value !== null && (
        <PsiScoreItem
          name="G"
          score={props.psi.match[1][props.competitor].value}
          category={props.psi.match[1][props.competitor].category}
        />
      )}
      {props.psi.match[2][props.competitor].value !== null && (
        <PsiScoreItem
          name="O"
          score={props.psi.match[2][props.competitor].value}
          category={props.psi.match[2][props.competitor].category}
        />
      )}
      {props.psi.match[3][props.competitor].value !== null && (
        <PsiScoreItem
          name="R"
          score={props.psi.match[3][props.competitor].value}
          category={props.psi.match[3][props.competitor].category}
        />
      )}
    </div>
  );
};

const BracketMatch = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const leftConnector = (
    <div className="flex flex-col">
      <div className="h-1/2 w-3 md:w-6 border-b dark:border-slate-600" />
    </div>
  );

  const rightConnector = (
    <div
      className={`flex flex-col ${
        props.isEven ? "justify-end" : "justify-start"
      }`}
    >
      <div
        className={`h-1/2 border-r w-3 md:w-6 dark:border-slate-600 ${
          props.isEven ? "rounded-tr-lg border-t" : "rounded-br-lg border-b"
        }`}
      />
    </div>
  );

  const filler = <div className="w-3 md:w-6" />;

  if (props.dummy)
    return (
      <div
        style={{
          height: `${props.matchContainerHeights[props.roundIndex]}rem`,
        }}
        className={`flex ${props.roundName}-r${props.roundIndex}`}
      >
        {props.roundIndex !== 0 ? leftConnector : filler}
        <div className="flex flex-col justify-center flex-grow">
          <div className="h-48 rounded-2xl border shadow-sm dark:border-slate-600" />
        </div>
        {props.isLastRound ? filler : rightConnector}
      </div>
    );

  const winner =
    props.competitors[0].totalScore > props.competitors[1].totalScore
      ? "home"
      : "away";

  const numSets = props.competitors[0].periodScores.filter(
    (i) => i.score !== ""
  ).length;

  const showMatchDetailModal = () => {
    dispatch(
      uiActions.openMatchDetailModal({ scoreId: props.id, navigate: navigate })
    );
  };

  return (
    <div
      style={{
        height: props.height,
      }}
      className={`flex ${props.roundName}-r${props.roundIndex}`}
    >
      {props.roundIndex !== 0 ? leftConnector : filler}

      <div className="flex flex-grow flex-col justify-center py-2">
        <div
          className={`flex h-48 cursor-pointer items-center overflow-hidden rounded-2xl border text-sm shadow-sm dark:border-slate-600 relative ${
            props.isActive ? "bg-slate-50 dark:bg-slate-800" : "bg-inherit"
          }`}
          onMouseEnter={() => {
            props.onMatchHovered(
              props.competitors[0].id,
              props.competitors[1].id
            );
          }}
          onMouseLeave={() => {
            props.onMatchUnhovered();
          }}
          state={{
            status: props.status,
            startTime: props.startTime,
            competitors: props.competitors,
            gameStats: props.gameStats,
            pointByPoint: props.pointByPoint,
          }}
          onClick={showMatchDetailModal}
        >
          <div className="grid h-full w-full grid-flow-col grid-cols-1 grid-rows-2">
            <CompetitorName
              doubleLines
              shortName
              withFlag
              withSeed
              withRank
              className="border-b pl-2 dark:border-slate-600"
              competitor={props.competitors[0]}
              isWinner={props.competitors[0].winner}
            />
            <CompetitorName
              doubleLines
              shortName
              withFlag
              withSeed
              withRank
              className="pl-2"
              competitor={props.competitors[1]}
              isWinner={props.competitors[1].winner}
            />
            <div
              key={`${props.competitors[0].id}-h2hWon`}
              className="flex items-center justify-center border-b border-l dark:border-slate-600"
            >
              <ScoreItem
                className={`flex w-6 justify-center sm:w-8 ${
                  props.competitors[0].h2hWon < props.competitors[1].h2hWon &&
                  "text-slate-400"
                }`}
                setScore={props.competitors[0].h2hWon}
              />
              <div className="bg-black dark:bg-white absolute text-white dark:text-black translate-y-1/2 w-6 sm:w-8 bottom-1/2 text-2xs p-0.5 sm:text-xs text-center">
                H2H
              </div>
            </div>
            <div
              key={`${props.competitors[1].id}-h2hWon`}
              className="flex items-center justify-center border-l dark:border-slate-600"
            >
              <ScoreItem
                className={`flex w-6 justify-center sm:w-8 ${
                  props.competitors[1].h2hWon < props.competitors[0].h2hWon &&
                  "text-slate-400"
                }`}
                setScore={props.competitors[1].h2hWon}
              />
            </div>
            {props.chartedData && (
              <PsiScoresList
                className="border-b dark:border-slate-600"
                competitor="home"
                psi={props.chartedData.psi}
              />
            )}
            {props.chartedData && (
              <PsiScoresList competitor="away" psi={props.chartedData.psi} />
            )}
            <div
              className={`flex w-7 items-center justify-center border-b border-l font-number dark:border-slate-600 md:hidden ${
                winner === "away" && "text-slate-400"
              }`}
            >
              {props.competitors[0].totalScore}
            </div>
            <div
              className={`flex w-7 items-center justify-center border-l font-number dark:border-slate-600 md:hidden ${
                winner === "home" && "text-slate-400"
              }`}
            >
              {props.competitors[1].totalScore}
            </div>
            {range(numSets).map((set, index) => {
              const periodWinner = getWinner({
                homeScore: props.competitors[0].periodScores[set].score,
                awayScore: props.competitors[1].periodScores[set].score,
                homeTiebreakScore:
                  props.competitors[0].periodScores[set].tiebreakScore,
                awayTiebreakScore:
                  props.competitors[1].periodScores[set].tiebreakScore,
              });
              return (
                <>
                  <div
                    key={`${props.competitors[0].id}-${index}`}
                    className="hidden items-center justify-center border-b border-l dark:border-slate-600 md:flex"
                  >
                    <ScoreItem
                      className={`flex w-6 justify-center sm:w-8 ${
                        periodWinner === "away" && "text-slate-400"
                      }`}
                      setScore={props.competitors[0].periodScores[set].score}
                      tiebreakScore={
                        props.competitors[0].periodScores[set].tiebreakScore
                      }
                    />
                  </div>
                  <div
                    key={`${props.competitors[1].id}-${index}`}
                    className={`hidden items-center justify-center border-l dark:border-slate-600 md:flex`}
                  >
                    <ScoreItem
                      className={`flex w-6 justify-center sm:w-8 ${
                        periodWinner === "home" && "text-slate-400"
                      }`}
                      setScore={props.competitors[1].periodScores[set].score}
                      tiebreakScore={
                        props.competitors[1].periodScores[set].tiebreakScore
                      }
                    />
                  </div>
                </>
              );
            })}
          </div>
          <div className="absolute left-2 flex gap-2 items-center">
            {props.status.includes("Set") && (
              <Label className="bg-green-500">{props.status}</Label>
            )}
            {(props.status === "Retired" || props.status === "Walk Over") && (
              <Label className="bg-slate-400 dark:bg-slate-600">
                {props.status}
              </Label>
            )}
            {props.dewPoint !== null && props.dewPoint !== undefined && (
              <Label className="bg-primary flex gap-1 items-center">
                <MdDewPoint className="text-lg" />
                <p>{`${props.dewPoint}°`}</p>
              </Label>
            )}
            {props.chartedData &&
              props.chartedData.note &&
              (props.chartedData.note.title ||
                props.chartedData.note.content) && (
                <MdInfo className="text-lg" />
              )}
          </div>
        </div>
        {/* <Link
          className={`flex h-48 cursor-pointer items-center overflow-hidden rounded-2xl border text-sm shadow-sm dark:border-slate-600 relative ${
            props.isActive ? "bg-slate-50 dark:bg-slate-800" : "bg-inherit"
          }`}
          onMouseEnter={() => {
            props.onMatchHovered(
              props.competitors[0].id,
              props.competitors[1].id
            );
          }}
          onMouseLeave={() => {
            props.onMatchUnhovered();
          }}
          state={{
            status: props.status,
            startTime: props.startTime,
            competitors: props.competitors,
            gameStats: props.gameStats,
            pointByPoint: props.pointByPoint,
          }}
          to={`/scores/${props.id}`}
        >
          <div className="grid h-full w-full grid-flow-col grid-cols-1 grid-rows-2">
            <CompetitorName
              doubleLines
              shortName
              withFlag
              withSeed
              className="border-b pl-2 dark:border-slate-600"
              competitor={props.competitors[0]}
              isWinner={props.competitors[0].winner}
            />
            <CompetitorName
              doubleLines
              shortName
              withFlag
              withSeed
              className="pl-2"
              competitor={props.competitors[1]}
              isWinner={props.competitors[1].winner}
            />
            {props.chartedData && (
              <PsiScoresList
                className="border-b dark:border-slate-600"
                competitor="home"
                psi={props.chartedData.psi}
              />
            )}
            {props.chartedData && (
              <PsiScoresList competitor="away" psi={props.chartedData.psi} />
            )}
            <div
              className={`flex w-7 items-center justify-center border-b border-l font-number dark:border-slate-600 md:hidden ${
                winner === "away" && "text-slate-400"
              }`}
            >
              {props.competitors[0].totalScore}
            </div>
            <div
              className={`flex w-7 items-center justify-center border-l font-number dark:border-slate-600 md:hidden ${
                winner === "home" && "text-slate-400"
              }`}
            >
              {props.competitors[1].totalScore}
            </div>
            {range(numSets).map((set, index) => {
              const periodWinner = getWinner({
                homeScore: props.competitors[0].periodScores[set].score,
                awayScore: props.competitors[1].periodScores[set].score,
                homeTiebreakScore:
                  props.competitors[0].periodScores[set].tiebreakScore,
                awayTiebreakScore:
                  props.competitors[1].periodScores[set].tiebreakScore,
              });
              return (
                <>
                  <div
                    key={`${props.competitors[0].id}-${index}`}
                    className="hidden items-center justify-center border-b border-l dark:border-slate-600 md:flex"
                  >
                    <ScoreItem
                      className={`flex w-6 justify-center sm:w-8 ${
                        periodWinner === "away" && "text-slate-400"
                      }`}
                      setScore={props.competitors[0].periodScores[set].score}
                      tiebreakScore={
                        props.competitors[0].periodScores[set].tiebreakScore
                      }
                    />
                  </div>
                  <div
                    key={`${props.competitors[1].id}-${index}`}
                    className={`hidden items-center justify-center border-l dark:border-slate-600 md:flex ${
                      periodWinner === "home" && "text-slate-400"
                    }`}
                  >
                    <ScoreItem
                      className={`flex w-6 justify-center sm:w-8 ${
                        periodWinner === "home" && "text-slate-400"
                      }`}
                      setScore={props.competitors[1].periodScores[set].score}
                      tiebreakScore={
                        props.competitors[1].periodScores[set].tiebreakScore
                      }
                    />
                  </div>
                </>
              );
            })}
          </div>
          <div className="absolute left-2 flex gap-2 items-center">
            {props.dewPoint !== null && props.dewPoint !== undefined && (
              <Label className="bg-primary flex gap-1 items-center">
                <MdDewPoint className="text-lg" />
                <p>{`${props.dewPoint}°`}</p>
              </Label>
            )}
            {props.chartedData &&
              props.chartedData.note &&
              (props.chartedData.note.title ||
                props.chartedData.note.content) && (
                <MdInfo className="text-lg" />
              )}
          </div>
        </Link> */}
      </div>

      {props.isLastRound ? filler : rightConnector}
    </div>
  );
};

export default BracketMatch;
