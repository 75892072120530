import { BiSearch } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";

const SearchButton = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const clickHandler = () => {
    navigate("/search?query=");
  };

  return (
    <button
      className="rounded-lg p-2.5 text-xl text-slate-500 hoverable:hover:bg-slate-100 dark:text-slate-400 dark:hoverable:hover:bg-slate-800 dark:hover:text-white"
      onClick={clickHandler}
    >
      <BiSearch
        className={
          location.pathname === "/search" ? "text-primary dark:text-white" : ""
        }
      />
    </button>
  );
};

export default SearchButton;
