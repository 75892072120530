import { useEffect, useState } from "react";
import TabsList from "./TabsList";

const Tabs = (props) => {
  const isLoading = !props.tabs;
  const [activeTab, setActiveTab] = useState();

  const tabChangeHandler = (newActiveTab) => {
    setActiveTab(newActiveTab);
  };

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(Object.keys(props.tabs)[0]);
    }
  }, [activeTab, props.tabs]);

  return (
    <div>
      <TabsList
        tabs={!isLoading && Object.keys(props.tabs)}
        activeTab={activeTab}
        tabChangeHandler={tabChangeHandler}
      />
      <hr className="dark:border-slate-700" />
      <div
        className={` ${props.className} ${
          activeTab !== "Draws"
            ? "py-6 px-4 sm:px-5 sm:py-7 md:px-6 md:py-8"
            : ""
        } `}
      >
        {isLoading ? props.skeletonTab : props.tabs[activeTab]}
      </div>
    </div>
  );
};

export default Tabs;
