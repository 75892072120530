import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    darkMode: true,
    showMenu: false,
    authModal: { isOpen: false },
    doublePlayerModal: { isOpen: false },
    matchSettingsModal: { isOpen: false },
    matchDetailModal: { isOpen: false },
    addNoteModal: { isOpen: false },
    alertModal: { isOpen: false },
    charterIdModal: { isOpen: false },
    scoresCategoryPicker: false,
    scoresDatePicker: false,
    tournamentMonthPicker: false,
    tournamentDetailYearPicker: false,
    tournamentDetailTypePicker: false,
    rankingsCategoryPicker: false,
    rankingsTypePicker: false,
    playerDetailTypePicker: false,
    playerDetailYearPicker: false,
    gameSetsPicker: false,
    firstServerPicker: false,
  },
  reducers: {
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
    },
    toggleMenu(state) {
      state.showMenu = !state.showMenu;
    },
    closeMenu(state) {
      state.showMenu = false;
    },
    openAuthModal(state, action) {
      state.authModal = { isOpen: true, ...action.payload };
    },
    openDoublePlayerModal(state, action) {
      state.doublePlayerModal = { isOpen: true, ...action.payload };
    },
    openMatchSettingsModal(state, action) {
      state.matchSettingsModal = { isOpen: true, ...action.payload };
    },
    openMatchDetailModal(state, action) {
      state.matchDetailModal = { isOpen: true, ...action.payload };
    },
    openAddNoteModal(state, action) {
      state.addNoteModal = { isOpen: true, ...action.payload };
    },
    openAlertModal(state, action) {
      state.alertModal = { isOpen: true, ...action.payload };
    },
    openCharterIdModal(state, action) {
      state.charterIdModal = { isOpen: true, ...action.payload };
    },
    togglePicker(state, action) {
      if (!state[action.payload]) {
        uiSlice.caseReducers.closeAllPickers(state);
        state[action.payload] = true;
      } else {
        state[action.payload] = false;
      }
    },
    closeModal(state) {
      state.authModal.isOpen = false;
      state.doublePlayerModal.isOpen = false;
      state.matchSettingsModal.isOpen = false;
      state.matchDetailModal.isOpen = false;
      state.addNoteModal.isOpen = false;
      state.alertModal.isOpen = false;
      state.charterIdModal.isOpen = false;
    },
    closeAllPickers(state) {
      state.scoresCategoryPicker = false;
      state.scoresDatePicker = false;
      state.tournamentMonthPicker = false;
      state.tournamentDetailYearPicker = false;
      state.tournamentDetailTypePicker = false;
      state.rankingsCategoryPicker = false;
      state.rankingsTypePicker = false;
      state.gameSetsPicker = false;
      state.firstServerPicker = false;
      state.playerDetailTypePicker = false;
      state.playerDetailYearPicker = false;
    },
  },
});

export const uiActions = uiSlice.actions;
export default uiSlice.reducer;
