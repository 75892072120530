import { useSelector } from "react-redux";
import CompetitorName from "../shared/CompetitorName";
import Card from "../UI/Card";
import ScoreItem from "../shared/ScoreItem";
import { IoTennisball } from "react-icons/io5";
import { GAME_SCORE_NUMBERS } from "../../constants";
import { getWinner } from "../../helpers";
import Skeleton from "../shared/Skeleton";

const ChartScores = (props) => {
  const {
    server,
    gameScores,
    periodScores,
    breakpoint,
    isTiebreaker,
    isSupertiebreaker,
  } = useSelector((state) => state.chart.present);
  const isLoading = !props.home;

  const getSetScore = (competitor) =>
    isTiebreaker || isSupertiebreaker
      ? gameScores[competitor]
      : gameScores[competitor] === 4
        ? "A"
        : GAME_SCORE_NUMBERS[gameScores[competitor]];

  return (
    <Card className="px-3 py-6 sm:px-4 md:px-6">
      <div className="grid auto-cols-max grid-flow-col grid-cols-1 grid-rows-2 items-center gap-y-2">
        <div className="flex items-center pr-4">
          <CompetitorName withFlag className="flex-1" competitor={props.home} />
          {!isLoading && breakpoint.home && (
            <p className="text-sm text-slate-400">BP</p>
          )}
          {!isLoading && server === "home" && <IoTennisball />}
        </div>
        <div className="flex items-center pr-4">
          <CompetitorName withFlag className="flex-1" competitor={props.away} />
          {!isLoading && breakpoint.away && (
            <p className="text-sm text-slate-400">BP</p>
          )}
          {!isLoading && server === "away" && <IoTennisball />}
        </div>
        {isLoading ? (
          <Skeleton className="rounded-full h-3 w-10" />
        ) : (
          <p className="w-6 text-center font-number font-bold text-primary sm:w-8">
            {getSetScore("home")}
          </p>
        )}
        {isLoading ? (
          <Skeleton className="rounded-full h-3 w-10" />
        ) : (
          <p className="w-6 text-center font-number font-bold text-primary sm:w-8">
            {getSetScore("away")}
          </p>
        )}
        {!isLoading &&
          periodScores.map((period) => {
            const winner = getWinner({
              homeScore: period.home_score,
              awayScore: period.away_score,
              homeTiebreakScore: period.home_tiebreak_score,
              awayTiebreakScore: period.away_tiebreak_score,
            });
            return (
              <>
                <ScoreItem
                  className={`flex w-6 justify-center sm:w-8 ${
                    winner === "away" ? "text-slate-400" : ""
                  }`}
                  setScore={period.home_score}
                  tiebreakScore={period.home_tiebreak_score}
                />
                <ScoreItem
                  className={`flex w-6 justify-center sm:w-8 ${
                    winner === "home" ? "text-slate-400" : ""
                  }`}
                  setScore={period.away_score}
                  tiebreakScore={period.away_tiebreak_score}
                />
              </>
            );
          })}
      </div>
    </Card>
  );
};

export default ChartScores;
