import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const PsiLogo = (props) => {
  const isDarkMode = useSelector((state) => state.ui.darkMode);
  return (
    <Link to="/" className={props.className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 892.98 498.74"
        className="w-16"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <rect
              fill={isDarkMode ? "white" : "black"}
              y="123.99"
              width="101"
              height="374.75"
            />
            <path
              fill={isDarkMode ? "white" : "black"}
              d="M179.5,396a99.09,99.09,0,0,1-57.62-17.87q-25.89-17.87-41.13-48T65.5,263.24v-5l35.5-.75v3.25q0,16.5,7.38,27.75a47.21,47.21,0,0,0,18.74,16.88A52.2,52.2,0,0,0,150.5,311q19,0,34.75-13.25T201,255q0-29.49-15.62-42.12T150.5,200.24a53.62,53.62,0,0,0-23.12,5.38,47.54,47.54,0,0,0-18.88,16.12q-7.5,10.75-7.5,27.5l-35.5-2.5q0-37,15.25-65.87t41.13-45.63a103.81,103.81,0,0,1,57.62-16.75,124.42,124.42,0,0,1,60.88,15.75Q269,150,287,180.49T305,255q0,46.5-17.5,77.88t-46,47.25A125.34,125.34,0,0,1,179.5,396Z"
            />
            <path
              fill={isDarkMode ? "white" : "black"}
              d="M457.5,396a195,195,0,0,1-46.5-5.62,149,149,0,0,1-41.88-17,93.17,93.17,0,0,1-30.25-28.63q-11.37-17.25-11.37-40.5v-2.5h99.75v2.5q0,10.5,9,14.75t24.25,4.25a69.27,69.27,0,0,0,12.75-1.37,36,36,0,0,0,12.62-4.75q5.37-3.37,5.38-8.88,0-8.25-11.38-11.62t-39.62-7.88a318.16,318.16,0,0,1-36-8.12,164.34,164.34,0,0,1-35.75-14.75A83.22,83.22,0,0,1,341.25,242q-10.5-14.49-10.5-35.75,0-29,17.62-48.5t46.75-29.37q29.13-9.87,63.63-9.88a169.09,169.09,0,0,1,61.5,11.13Q549,140.75,567,161.24t18,48.5v2.5H486.25v-2.5q0-7-4.38-11.12A27.36,27.36,0,0,0,471,192.49a44.16,44.16,0,0,0-13-2,75.06,75.06,0,0,0-11.88,1.13,33.31,33.31,0,0,0-11.87,4.12,9.6,9.6,0,0,0-5,8.75q0,5,6.37,8.5t17.5,5.88q11.13,2.38,24.88,4.37,14.25,2,33,6.5a170.77,170.77,0,0,1,36.5,13.38,83.72,83.72,0,0,1,29.37,24q11.63,15.13,11.63,38.62,0,25.26-11.63,42.5a86.1,86.1,0,0,1-31,27.75,153.4,153.4,0,0,1-42.37,15.25A226.73,226.73,0,0,1,457.5,396Z"
            />
            <rect
              fill={isDarkMode ? "white" : "black"}
              x="617.99"
              y="123.99"
              width="99.75"
              height="265.5"
            />
            <path
              fill={isDarkMode ? "white" : "black"}
              d="M822.14,336.08H804.75v53.84h-7V336.08H780.35V329.7h41.79Z"
            />
            <path
              fill={isDarkMode ? "white" : "black"}
              d="M893,389.92h-7v-40.4q0-4.78.59-11.72h-.17a37.65,37.65,0,0,1-1.8,5.84L864,389.92h-3.44L840,344a36.61,36.61,0,0,1-1.8-6.18h-.17q.33,3.61.34,11.8v40.32h-6.81V329.7h9.33l18.47,42a53.73,53.73,0,0,1,2.77,7.22h.26q1.8-4.95,2.89-7.39l18.86-41.82H893Z"
            />
            <path
              fill={isDarkMode ? "white" : "black"}
              d="M677.2,63c12.57-17,30.24-20.43,39.56-21-4.43-23.27-23.47-41-46.62-42.06,1,8.56,1.31,27.76-12.06,44-12.78,15.47-29.51,18.38-39.15,18.62,4.44,23.87,24.25,41.94,48.14,42.34C665.9,95.5,665.89,78.36,677.2,63Z"
            />
            <path
              fill={isDarkMode ? "white" : "black"}
              d="M651.93,38.34C663.72,24.06,662.88,7,662,.32c-24.77,3.07-44,25.22-44,52.12,0,.52,0,1,0,1.56C626,53.89,640.9,51.7,651.93,38.34Z"
            />
            <path
              fill={isDarkMode ? "white" : "black"}
              d="M683.63,68.28c-9.84,13.34-9.51,28.32-8.43,36.09,24.07-3.74,42.54-25.56,42.54-51.93,0-.61,0-1.22,0-1.83C710.09,51,694.42,53.66,683.63,68.28Z"
            />
          </g>
        </g>
      </svg>
    </Link>
  );
};

export default PsiLogo;
