import LiveTournamentsList from "../components/scores/LiveTournamentsList";
import { defer } from "react-router-dom";
import { getDailySummaries } from "../api/scores-api";
import EmptyPage from "./EmptyPage";
import { useQuery, useQueryClient } from "react-query";
import ScoresFilter from "../components/scores/ScoresFilter";
import { useDispatch, useSelector } from "react-redux";
import { pagesStatesActions } from "../store/pages-states-slice";
import { useEffect, useState } from "react";
import io from "socket.io-client";
import PageTitle from "../components/shared/PageTitle";

const dailySummariesQuery = (dateDiff, images) => ({
  queryKey: ["scores", dateDiff],
  queryFn: async () => getDailySummaries(dateDiff, images),
  staleTime: 300_000,
  // refetchOnWindowFocus: true,
});

const filterTournamentMatches = (allTournaments, status, category) => {
  if (allTournaments === undefined) return null;

  let filteredTournaments = structuredClone(allTournaments);
  if (category === "Challenger") {
    filteredTournaments = filteredTournaments.filter((tournament) =>
      tournament.category.includes("Challenger")
    );
  } else {
    filteredTournaments = filteredTournaments.filter(
      (tournament) => category === "All" || tournament.category === category
    );
  }
  if (status === "All") {
    return filteredTournaments;
  }
  const statusMapper = {
    Live: ["Set 1", "Set 2", "Set 3", "Set 4", "Set 5"],
    Completed: ["Finished", "Retired"],
    Upcoming: ["Not Started", "1"],
  };

  filteredTournaments.forEach(
    (tournament) =>
      (tournament.matches = tournament.matches.filter((match) =>
        statusMapper[status].includes(match.status)
      ))
  );
  filteredTournaments = filteredTournaments.filter(
    (tournament) => tournament.matches.length > 0
  );
  return filteredTournaments;
};

const ScoresPage = (props) => {
  const queryClient = useQueryClient();
  const subdomain = window.location.host.split(".")[0];
  const isCharter = subdomain.includes("charter");

  const {
    scoresStatus: tournamentStatus,
    scoresCategory: tournamentCategory,
    scoresDateIndex: dateIndex,
  } = useSelector((state) => state.pagesStates);
  const dispatch = useDispatch();
  const {
    data: allOngoingTournaments,
    isFetched,
    error,
    refetch,
  } = useQuery(dailySummariesQuery(dateIndex - 7, isCharter));
  const [needRefetch, setNeedRefetch] = useState(false);

  useEffect(() => {
    document.title = "Live Tennis Scores - PSI Tennis Analytics";
    console.log(`useEffect running with isFetched=${isFetched}`);
    if (isCharter && isFetched) refetch();
  }, [refetch]);

  useEffect(() => {
    if (!isFetched) return;
    // console.log("scores page useEffect running");
    if (needRefetch) {
      refetch();
      setNeedRefetch(false);
      return;
    }

    // const deviceIDHandler = (deviceID) => {
    //   // Store the received device ID in local storage
    //   localStorage.setItem("deviceID", deviceID);
    // };

    const dataChangeHandler = (updatedMatchData) => {
      if (updatedMatchData.id === "2423215") console.log(updatedMatchData);
      // queryClient.invalidateQueries(["scores", dateIndex - 7]);
      if (updatedMatchData.operationType === "insert" && !needRefetch) {
        setNeedRefetch(true);
      } else if (updatedMatchData.operationType === "update") {
        // Update the React Query cache with the new data
        queryClient.setQueryData(
          ["scores", dateIndex - 7],
          (liveScoresData) => {
            const updatedMatchId = updatedMatchData.id;
            for (const tournament of liveScoresData) {
              for (const match of tournament.matches) {
                if (match.id === updatedMatchId) {
                  // console.log(`${match.id} updated`);
                  if ("status" in updatedMatchData) {
                    match.status = updatedMatchData.status;
                  }
                  if ("chartingBy" in updatedMatchData) {
                    match.chartingBy = updatedMatchData.chartingBy;
                  }
                  if ("competitors" in updatedMatchData) {
                    for (const [
                      i,
                      competitor,
                    ] of updatedMatchData.competitors.entries()) {
                      for (const field of Object.keys(competitor)) {
                        if (["s1", "s2", "s3", "s4", "s5"].includes(field)) {
                          const setIndex = +field[1] - 1;
                          // if got tiebreak score
                          if (competitor[field].includes(".")) {
                            const [score, tiebreakScore] =
                              competitor[field].split(".");
                            match.competitors[i].periodScores[setIndex].score =
                              score;
                            match.competitors[i].periodScores[
                              setIndex
                            ].tiebreakScore = tiebreakScore;
                          } else {
                            match.competitors[i].periodScores[setIndex].score =
                              competitor[field];
                          }
                        } else {
                          match.competitors[i][field] = competitor[field];
                        }
                      }
                    }
                  }
                }
              }
            }
            return liveScoresData;
          }
        );
      }
    };

    const socket = io(process.env.REACT_APP_BACKEND_URL);

    // Retrieve the device ID from local storage
    // const deviceID = localStorage.getItem("deviceID");

    // // If device ID doesn't exist in local storage, emit an event to the server to request a new one
    // if (!deviceID) {
    //   socket.emit("requestDeviceID");
    // }

    // Listen for the deviceID event from the server
    // socket.on("deviceID", deviceIDHandler);

    // Listen for 'dataChange' events from the server
    socket.on("dataChange", dataChangeHandler);
    // Clean up the socket connection on unmount
    return () => {
      // console.log("disconnecting socket...");
      // socket.off("deviceID", deviceIDHandler);
      socket.off("dataChange", dataChangeHandler);
      socket.disconnect();
    };
  }, [queryClient, dateIndex, isFetched, refetch, needRefetch]);

  if (error) {
    throw new Error(error);
  }

  const tournamentStatusChangeHandler = (newStatus) => {
    if (newStatus === tournamentStatus) return;
    dispatch(pagesStatesActions.changeScoresStatus(newStatus));
  };

  const categoryChangeHandler = (categories, index) => {
    if (categories[index] === tournamentCategory) return;
    dispatch(pagesStatesActions.changeScoresCategory(categories[index]));
  };

  const dateIndexChangeHandler = (dates, index) => {
    if (index === dateIndex) return;
    dispatch(pagesStatesActions.changeScoresDateIndex(index));
  };

  const filteredTournaments = filterTournamentMatches(
    allOngoingTournaments,
    tournamentStatus,
    tournamentCategory
  );

  return (
    <>
      <PageTitle>
        {subdomain.includes("charter") ? "Chart" : "Live Scores"}
      </PageTitle>
      <ScoresFilter
        tournamentStatus={tournamentStatus}
        onTournamentStatusChange={tournamentStatusChangeHandler}
        category={tournamentCategory}
        categoryChangeHandler={categoryChangeHandler}
        dateIndex={dateIndex}
        dateIndexChangeHandler={dateIndexChangeHandler}
      />
      {isFetched && filteredTournaments.length === 0 ? (
        <EmptyPage text="Uh oh... No matches found" />
      ) : (
        <LiveTournamentsList ongoingTournaments={filteredTournaments} />
      )}
    </>
  );
};

export default ScoresPage;

export const loader = (queryClient, images) => {
  return async () => {
    return defer({
      promise:
        queryClient.getQueryData(dailySummariesQuery(0, images)) ??
        queryClient.fetchQuery(dailySummariesQuery(0, images)),
    });
  };
};
