const PsiBackground = (props) => {
  return (
    <div className="space-y-4 flex flex-col items-center text-base sm:text-lg">
      <p>
        The idea of PSI Tennis was developed by a coach while on tour on the ATP
        Challenger circuit in 2009. He wanted his players to know how many
        points were being lost in a game without them making up for it with
        winners or forced errors. The philosophical concept was quite simple, in
        order for a player to play “Solid,” there would have to be an equal or
        close to equal amount of positive results to negative results. The
        results being, the last shot executed or attempted in a point.
      </p>
      <p>
        In the United States, the idea was then taken a step further and an
        algorithm was developed that calculated the average number of points
        either WON or LOST per game based on Good Shot to Bad Shot ratios. Once
        the algorithm was developed, the next step was to create an Index to
        which the algorithm could be connected. The result was a 5-tier
        Performance Grade Index (PSI) that was generated from uniform
        parameters.
      </p>
      <p>
        The Performance Stat Index rates a player's performance in four
        categories: Serve, Return of Serve, Groundstrokes and Auxiliary, and
        Overall performance. The grades are as follows
      </p>
      <ul className="space-y-2 text-center">
        <li className="bg-excellentPSIColor py-2 px-6 rounded-md text-white">
          Excellent Performance
        </li>
        <li className="bg-aboveAveragePSIColor py-2 px-6 rounded-md text-white">
          Above Average Performance
        </li>
        <li className="text-black bg-solidPSIColor py-2 px-6 rounded-md">
          Solid Performance
        </li>
        <li className="text-black bg-belowAveragePSIColor py-2 px-6 rounded-md">
          Below Average Performance
        </li>
        <li className="bg-poorPSIColor py-2 px-6 rounded-md text-white">
          Poor Performance
        </li>
      </ul>
      <p>
        PSI Tennis employs analysts from all around the world, both full-time
        and part-time. PSI Analysts are former elite level junior and/or
        professional tennis players, as well as high-performance coaches, who
        have been instructed to assess points at the highest level required to
        chart and register matches for PSI Tennis. Our Senior Level Analysts
        have received at least six months of training and are responsible for
        charting and registering ATP and WTA Tour level competitions, as well as
        some ATP Challenger tournaments.
      </p>
    </div>
  );
};

export default PsiBackground;
