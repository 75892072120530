import { range } from "lodash";
import TabItem from "./TabItem";

const TabsList = (props) => {
  const isLoading = !props.tabs;
  return (
    <div
      className={`flex w-full overflow-auto pt-4 pl-3 no-scrollbar sm:pl-4 md:pl-8 ${props.className}`}
    >
      {(isLoading ? range(5) : props.tabs).map((tabItem, index) => (
        <TabItem
          key={isLoading ? index : tabItem}
          name={isLoading ? null : tabItem}
          isActive={props.activeTab === tabItem}
          tabChangeHandler={props.tabChangeHandler}
        />
      ))}
    </div>
  );
};

export default TabsList;
