import CompetitorName from "../shared/CompetitorName";
import ScoreItem from "../shared/ScoreItem";
import { Link } from "react-router-dom";
import { range } from "lodash";
import { getTimeAgoString, getWinner } from "../../helpers";
import Skeleton from "../shared/Skeleton";
import PSIStatsLabel from "../shared/PSIStatsLabel";

const MatchScores = (props) => {
  const isLoading = !props.competitors;
  const subdomain = window.location.host.split(".")[0];
  const isChartingSite = subdomain.includes("charter");

  const numSets = isLoading
    ? null
    : props.competitors[0].periodScores.filter((i) => i.score !== "").length;

  const matchStatus = props.status === "1" ? "Not Started" : props.status;

  const content = (
    <div
      className={`space-y-2 rounded-2xl xs:px-2 sm:px-3 md:px-4 ${!props.isInMatchDetail && "py-3"} ${props.className}`}
    >
      <div className="flex justify-between text-xs text-slate-400 sm:text-sm">
        {isLoading ? (
          <Skeleton className="h-3 w-14 rounded-full" />
        ) : (
          <p>{props.isInMatchDetail ? props.court : props.label}</p>
        )}
        {!props.isInMatchDetail &&
          (isLoading ? (
            <Skeleton className="h-3 w-24 rounded-full" />
          ) : (
            <p>{matchStatus}</p>
          ))}
      </div>
      <div
        key={isLoading}
        className="grid auto-cols-max grid-flow-col grid-cols-1 grid-rows-2 items-center gap-y-2"
      >
        <CompetitorName
          withFlag
          withSeed
          key={props.competitors && props.competitors[0].id}
          competitor={props.competitors && props.competitors[0]}
          isServing={
            !["Retired", "Finished"].includes(matchStatus) &&
            props.competitors &&
            props.competitors[0].serve
          }
          isWinner={
            matchStatus === "Not Started" ||
            (props.competitors && props.competitors[0].winner)
          }
        />
        <CompetitorName
          withFlag
          withSeed
          key={props.competitors && props.competitors[1].id}
          competitor={props.competitors && props.competitors[1]}
          isServing={
            !["Retired", "Finished"].includes(matchStatus) &&
            props.competitors &&
            props.competitors[1].serve
          }
          isWinner={
            matchStatus === "Not Started" ||
            (props.competitors && props.competitors[1].winner)
          }
        />
        {(isChartingSite || !["Retired", "Finished"].includes(matchStatus)) &&
          props.competitors &&
          props.competitors[0].gameScore !== null && (
            <>
              <div className="w-6 text-center font-number font-bold text-primary sm:w-8">
                {props.competitors[0].gameScore}
              </div>
              <div className="w-6 text-center font-number font-bold text-primary sm:w-8">
                {props.competitors[1].gameScore}
              </div>
            </>
          )}
        {isLoading && (
          <>
            <Skeleton className="h-3 w-14 rounded-full" />
            <Skeleton className="h-3 w-14 rounded-full" />
          </>
        )}
        {!isLoading &&
          range(numSets).map((set) => {
            const winner = getWinner({
              homeScore: props.competitors[0].periodScores[set].score,
              awayScore: props.competitors[1].periodScores[set].score,
              homeTiebreakScore:
                props.competitors[0].periodScores[set].tiebreakScore,
              awayTiebreakScore:
                props.competitors[1].periodScores[set].tiebreakScore,
            });
            return (
              <>
                <ScoreItem
                  key={`homeSet${set}`}
                  className={`flex w-6 justify-center sm:w-8 ${
                    winner === "away" && "text-slate-400"
                  }`}
                  setScore={props.competitors[0].periodScores[set].score}
                  tiebreakScore={
                    props.competitors[0].periodScores[set].tiebreakScore
                  }
                />
                <ScoreItem
                  key={`awaySet${set}`}
                  className={`flex w-6 justify-center sm:w-8 ${
                    winner === "home" && "text-slate-400"
                  }`}
                  setScore={props.competitors[1].periodScores[set].score}
                  tiebreakScore={
                    props.competitors[1].periodScores[set].tiebreakScore
                  }
                />
              </>
            );
          })}
        {!isChartingSite && matchStatus === "Not Started" && (
          <div className="row-span-2 font-number">
            {props.startTime.toLocaleTimeString("en-US", {
              hour12: true,
              hour: "numeric",
              minute: "numeric",
            })}
          </div>
        )}
        {matchStatus === "Cancelled" && (
          <div className="row-span-2">Cancelled</div>
        )}
      </div>
      {!isLoading && props.showTimeAgo && (
        <div className="text-xs text-slate-400 sm:text-sm">
          {getTimeAgoString(props.startTime)}
        </div>
      )}
      {!isLoading && props.showStartDate && (
        <div className="text-xs text-slate-400 sm:text-sm">
          {props.startTime.toLocaleTimeString("en-US", {
            day: "numeric",
            month: "short",
            hour12: true,
            hour: "numeric",
            minute: "numeric",
          })}
        </div>
      )}
    </div>
  );

  if (props.isInMatchDetail || props.isOnMatchDetailModal || isLoading) {
    return content;
  }

  return (
    <Link
      className={`${props.className} block cursor-pointer rounded-xl hoverable:hover:bg-slate-50 hoverable:dark:hover:bg-slate-800`}
      to={`/scores/${props.id}`}
    >
      {!props.isInMatchDetail && props.charted && (
        <div className="flex justify-center pt-3">
          <PSIStatsLabel />
        </div>
      )}
      {content}
    </Link>
  );
};

export default MatchScores;
