import { useDispatch, useSelector } from "react-redux";
import useViewport from "../../hooks/use-viewport";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import { chartActions } from "../../store/chart-slice";
import { useState } from "react";
import { uiActions } from "../../store/ui-slice";

const AddNoteModal = (props) => {
  const { width } = useViewport();
  const breakpoint = 640;
  const isMobile = width < breakpoint;

  const { present } = useSelector((state) => state.chart);
  const { note } = present;

  const dispatch = useDispatch();
  const [titleValue, setTitleValue] = useState(note.title);
  const [contentValue, setContentValue] = useState(note.content);

  const handleTitleChange = (event) => {
    setTitleValue(event.target.value);
  };
  const handleContentChange = (event) => {
    setContentValue(event.target.value);
  };

  const saveNote = () => {
    dispatch(
      chartActions.addNote({ title: titleValue, content: contentValue })
    );
    dispatch(uiActions.closeModal());
  };

  return (
    <Modal
      bottomModal={isMobile}
      header={note.title || note.content ? "Edit Note" : "Add Note"}
    >
      <input
        className="w-full sm:px-5 px-3 text-xl font-bold placeholder:text-slate-300 dark:bg-slate-900 dark:placeholder:text-slate-500 focus:outline-none"
        type="text"
        placeholder="Title"
        value={titleValue}
        onChange={handleTitleChange}
      />
      <hr className="dark:border-slate-700 sm:mx-5 mx-3 my-3" />
      <textarea
        rows="18"
        className="w-full sm:px-5 px-3 placeholder:text-slate-300 dark:bg-slate-900 dark:placeholder:text-slate-500 focus:outline-none resize-none text-base"
        value={contentValue}
        placeholder="Write your notes here..."
        onChange={handleContentChange}
      />
      <div className="sm:px-5 px-3 pb-5">
        <Button className="w-full" onClick={saveNote}>
          Save Note
        </Button>
      </div>
    </Modal>
  );
};

export default AddNoteModal;
