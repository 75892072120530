import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import ScoresPage, { loader as liveSummariesLoader } from "./pages/ScoresPage";
import TournamentsPage, {
  loader as tournamentsLoader,
} from "./pages/TournamentsPage";
import { useDispatch, useSelector } from "react-redux";
import MatchDetailPage, {
  loader as matchDetailLoader,
} from "./pages/MatchDetailPage";
import AuthModal from "./components/auth/AuthModal";
import TournamentDetailPage, {
  loader as tournamentDetailLoader,
} from "./pages/TournamentDetailPage";
import RankingsPage, { loader as rankingsLoader } from "./pages/RankingsPage";
import RootLayout from "./pages/RootLayout";
import ErrorPage from "./pages/ErrorPage";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import NotFoundPage from "./pages/NotFoundPage";
import MatchSettingsModal from "./components/charter/MatchSettingsModal";
import ChartingPage from "./pages/ChartingPage";
import { loader as chartingMatchDetailLoader } from "./pages/ChartingPage";
import { loader as chartingMatchResultDetailLoader } from "./pages/ChartingMatchResultPage";
import MatchDetailModal from "./components/charter/MatchDetailModal";
import ChartingMatchResultPage from "./pages/ChartingMatchResultPage";
import AlertModal from "./components/shared/AlertModal";
import { AnimatePresence } from "framer-motion";
import PlayerDetailPage from "./pages/PlayerDetailPage";
import { loader as playerDetailLoader } from "./pages/RankingsPage";
import SearchPage from "./pages/SearchPage";
import { loader as searchResultLoader } from "./pages/RankingsPage";
import { useEffect } from "react";
import CharterIdModal from "./components/charter/CharterIdModal";
import { uiActions } from "./store/ui-slice";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";
import BlogsPage from "./pages/BlogsPage";
import BlogContentPage from "./pages/BlogContentPage";
import { validateCharterId } from "./api/chart-api";
import AddNoteModal from "./components/charter/AddNoteModal";
import DoublePlayerModal from "./components/shared/DoublePlayerModal";

const queryClient = new QueryClient();

const mainRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<HomePage />} />
      <Route
        path="/scores"
        element={<ScoresPage />}
        loader={liveSummariesLoader(queryClient, false)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/scores/:scoreId"
        element={<MatchDetailPage />}
        loader={matchDetailLoader(queryClient)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/tournaments"
        element={<TournamentsPage />}
        loader={tournamentsLoader(queryClient)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/tournaments/:seasonId"
        element={<TournamentDetailPage />}
        loader={tournamentDetailLoader(queryClient)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/rankings"
        element={<RankingsPage />}
        loader={rankingsLoader(queryClient)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/rankings/:playerId"
        element={<PlayerDetailPage />}
        loader={playerDetailLoader(queryClient)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/search"
        element={<SearchPage />}
        loader={searchResultLoader(queryClient)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/blogs"
        element={<BlogsPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/blogs/:blogUrl"
        element={<BlogContentPage />}
        errorElement={<ErrorPage />}
      />
      {/* <Route
        path="/psi-background"
        element={<PsiBackground />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/psi-metrics"
        element={<PsiMetricsPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/psi-performance-measurements"
        element={<PsiPerformanceMeasurementsPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/for-sport-bettors"
        element={<ForSportBettorsPage />}
        errorElement={<ErrorPage />}
      /> */}
      <Route
        path="/terms"
        element={<TermsPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/privacy"
        element={<PrivacyPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/*"
        element={<NotFoundPage />}
        errorElement={<ErrorPage />}
      />
    </Route>
  )
);

const charterRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<ScoresPage />} />
      <Route
        path="/chart"
        element={<ScoresPage />}
        loader={liveSummariesLoader(queryClient, true)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/chart/:scoreId"
        element={<ChartingPage />}
        loader={chartingMatchDetailLoader(queryClient)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/chart/result/:scoreId"
        element={<ChartingMatchResultPage />}
        loader={chartingMatchResultDetailLoader(queryClient)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/tournaments"
        element={<TournamentsPage />}
        loader={tournamentsLoader(queryClient)}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/tournaments/:seasonId"
        element={<TournamentDetailPage />}
        loader={tournamentDetailLoader(queryClient)}
        errorElement={<ErrorPage />}
      />
      {/* <Route
        path="/history"
        element={<CharterHistoryPage />}
        errorElement={<ErrorPage />}
      /> */}
    </Route>
  )
);

const App = () => {
  const dispatch = useDispatch();
  const {
    authModal,
    doublePlayerModal,
    matchSettingsModal,
    matchDetailModal,
    addNoteModal,
    alertModal,
    charterIdModal,
  } = useSelector((state) => state.ui);
  const subdomain = window.location.host.split(".")[0];

  useEffect(() => {
    const charterId = localStorage.getItem("charterID");
    if (subdomain.includes("charter")) {
      if (!charterId) {
        dispatch(uiActions.openCharterIdModal());
      } else {
        validateCharterId(charterId).then((isValid) => {
          if (!isValid) {
            dispatch(uiActions.openCharterIdModal());
          }
        });
      }
    }
  }, [subdomain, dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider
        router={subdomain.includes("charter") ? charterRouter : mainRouter}
      />
      <AnimatePresence>{authModal.isOpen && <AuthModal />}</AnimatePresence>
      <AnimatePresence>
        {doublePlayerModal.isOpen && <DoublePlayerModal />}
      </AnimatePresence>
      <AnimatePresence>
        {matchSettingsModal.isOpen && <MatchSettingsModal />}
      </AnimatePresence>
      <AnimatePresence>
        {matchDetailModal.isOpen && <MatchDetailModal />}
      </AnimatePresence>
      <AnimatePresence>
        {addNoteModal.isOpen && <AddNoteModal />}
      </AnimatePresence>
      <AnimatePresence>{alertModal.isOpen && <AlertModal />}</AnimatePresence>
      <AnimatePresence>
        {charterIdModal.isOpen && <CharterIdModal />}
      </AnimatePresence>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
