import { useState } from "react";
import { BiCalendar } from "react-icons/bi";
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useIsFetching } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { MONTHS } from "../../constants";
import { uiActions } from "../../store/ui-slice";
import Skeleton from "../shared/Skeleton";
import { motion } from "framer-motion";

const YearChangeButton = (props) => (
  <button
    className="rounded-lg p-2 hover:bg-slate-100 dark:border-slate-600 dark:hover:bg-slate-700"
    onClick={props.onClick}
    disabled={props.disabled}
  >
    {props.decrement ? <FaChevronLeft /> : <FaChevronRight />}
  </button>
);

const MonthPickerSelection = (props) => {
  const [activeMonth, activeYear] = props.activeMonthYear.split(" ");
  const [currentYear, setCurrentYear] = useState(activeYear);

  const increaseCurrentYear = () => {
    setCurrentYear((year) => (+year + 1).toString());
  };
  const decreaseCurrentYear = () => {
    setCurrentYear((year) => (+year - 1).toString());
  };
  const monthClickHandler = (chosenMonth) => {
    props.onMonthYearChange(chosenMonth + " " + currentYear);
  };

  const pickerVariants = {
    closed: {
      scale: 0,
      opacity: 0,
    },
    open: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.4,
        delayChildren: 0.2,
        staggerChildren: 0.05,
      },
    },
  };

  return (
    <motion.div
      className="absolute top-12 left-0 flex w-72 origin-top flex-col items-center space-y-2 rounded-xl bg-white p-4 shadow-lg dark:divide-slate-500 dark:bg-slate-800"
      onClick={(e) => {
        e.stopPropagation();
      }}
      variants={pickerVariants}
      animate={props.isExpanded ? "open" : "closed"}
      initial="closed"
      exit="closed"
    >
      <div className="flex w-full items-center">
        <YearChangeButton decrement onClick={decreaseCurrentYear} />
        <div className="flex-grow text-center">{currentYear}</div>
        <YearChangeButton increment onClick={increaseCurrentYear} />
      </div>
      <div className="grid w-full grid-cols-3 grid-rows-4 gap-2 pt-2">
        {MONTHS.map((month) => {
          const isActive = month === activeMonth && activeYear === currentYear;
          return (
            <div
              key={month}
              className={`${
                isActive
                  ? "bg-primary text-white"
                  : "hover:bg-slate-100 dark:hover:bg-slate-700"
              } w-full cursor-pointer rounded-lg py-2 text-center`}
              onClick={() => {
                monthClickHandler(month);
              }}
            >
              {month}
            </div>
          );
        })}
      </div>
    </motion.div>
  );
};

const MonthPicker = (props) => {
  const isLoading = useIsFetching({
    predicate: (query) => query.state.status === "loading",
  });
  const isExpanded = useSelector((state) => state.ui[props.name]);
  const dispatch = useDispatch();

  // skeleton state
  if (isLoading) {
    return <Skeleton baseBackground className="h-10 w-36 rounded-lg" />;
  }

  const toggleExpandedState = (e) => {
    e.stopPropagation();
    dispatch(uiActions.togglePicker(props.name));
  };

  const monthYearChangeHandler = (newMonthYear) => {
    dispatch(uiActions.togglePicker(props.name));
    props.onMonthYearChange(newMonthYear);
  };

  return (
    <div className="relative" onClick={toggleExpandedState}>
      <div className="flex h-10 w-44 cursor-pointer items-center justify-center gap-3 rounded-lg bg-white py-2 dark:bg-slate-900">
        <BiCalendar />
        {props.activeMonthYear}
        <motion.div
          variants={{ open: { rotate: 180 }, closed: { rotate: 0 } }}
          animate={isExpanded ? "open" : "closed"}
          transition={{ duration: 0.2 }}
        >
          {<FaChevronDown />}
        </motion.div>
      </div>
      {
        <MonthPickerSelection
          isExpanded={isExpanded}
          activeMonthYear={props.activeMonthYear}
          onMonthYearChange={monthYearChangeHandler}
        />
      }
    </div>
  );
};

export default MonthPicker;
