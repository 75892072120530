const TipsForSportsBettor1 = (props) => {
  return (
    <div>
      <p>
        Understand that PSI Tennis Analytics provides player performance data
        accumulated through a new and innovative way of measuring tennis player
        performance. This information can be used to compare against the next
        round opponent’s data. There are 4 areas where player performance data
        is collected and can be used for comparison; Serve, Groundstrokes and
        Auxiliary (G&A), Overall, and Return of Serve (RoS).
        <br />
        <br />
        PSI Tennis Analytics is considered “raw data” in the world of sports
        betting. The reason it is considered “raw” is because more details need
        to be given thought to before relying solely on the data provided to
        place bets. Of course, the easiest way to choose the winner of the next
        round would be to simply compare Overall Performances from their
        previous matches, but we recommend thinking about some of the following
        details before going “All In”.
        <br />
        <br />
        <span className="font-bold">
          1. Strength of Previous Opponent:
        </span>{" "}
        Imagine two top 20 players playing each other. In the next round, the
        winner faces another top 20 player who played and beat a player ranked
        outside the top 100. Playing a player of lesser level or ranking could
        result in more impressive performance measurements than the opponent who
        played a player of similar level or ranking.
        <br />
        <br />
        <span className="font-bold">2. H2H (Head to Head):</span> Analyzing the
        past results of both players can help in deciding who to bet on. The
        players are familiar with each other’s games and will know each other’s
        strengths and weaknesses.
        <br />
        <br />
        <span className="font-bold">3. Trending Strength:</span> Take the time
        to look at the player’s previous match performances. You may notice that
        their performance improves from round to round, gets worse from round to
        round, or is inconsistent from round to round.
        <br />
        <br />
        <span className="font-bold">
          4. Court Surface and Climate Conditions:
        </span>{" "}
        Most hard courts offer a consistent bounce and ball speed, but be aware
        of tournaments played in humid conditions. Relative humidity tends to
        increase at night and this makes it much more difficult to hit through
        the court. There are fewer aces and winners. Clay court tournaments in
        humid conditions can sometimes be described as being played in two
        different settings. A ball bouncing on clay will slow down; the question
        is, to what extent? During the day, the clay may not slow the ball down
        much, but when the sun goes down and the evening matches begin, the
        heavy conditions caused by an increase in humidity can slow the ball
        down exponentially, making it much more difficult to hit winners and
        unreturned serves.
        <br />
        <br />
        <span className="font-bold">5. Opponent Playing Style:</span> If you
        know tennis, you know that there are many different styles of play among
        professionals. Some grinders thrive playing far behind the baseline on
        clay courts, first-strike players love to take the ball early looking to
        attack the net on hard courts, and everything in between. The difference
        between these polar opposite types of players might be the number of
        notches in their PSI Ratio results. Baseline grinders will sometimes
        have fewer notches whereas aggressive first-strike players will tend to
        have more notches. That is because baseline grinders tend to rely more
        on opponent mistakes for the majority of their points. They are still
        aggressive, but you should know that most risks are usually more
        calculated. Aggressive players know that they make mistakes, but rely on
        hitting winners and force errors to make up for those mistakes.
        <br />
        <br />
        <span className="font-bold">6. Ranking and Rating:</span> There is a
        reason why some players have higher rankings and ratings, they are
        proven. They have the experience of having gone far in draws and along
        the way beaten other good players. When looking at rankings, take a
        quick look at ranking and rating history and you can see if the player
        is on the way up, on the way down, or is a constant mainstay in the
        rankings and ratings.
        <br />
        <br />
        <span className="font-bold">7. Effort History:</span> When considering
        effort history, there are three points to consider. The first is, is the
        player known for tanking matches? Unfortunately, some players will not
        perform at 100% all the time. Second, consider the player’s tournament
        schedule. Maybe the player has played non-stop tournament after
        tournament and gone far in the draw in each. The player might not just
        be tired physically, but mentally as well. When challenged by a tough
        opponent, the player might decide not to play with 100% effort. And
        third, did the player play any three-set matches, and if they did, how
        did they perform during the losing sets? You can see the performance
        results of each set played and determine if they tanked the losing set
        or fought tooth and nail throughout all sets.
        <br />
        <br />
        <span className="font-bold">8. PSI Performance Measurements:</span>{" "}
        Overall Performance is a great indicator of how the player is playing in
        general. But, maybe the tournament is being played in fast conditions
        where you might weigh Service Performance a bit more than Groundstrokes
        and Auxiliary. Maybe the tournament is being played in slow conditions,
        where you might want to weigh them the opposite. Then there is Return of
        Serve Performance where you see the average number of failed return
        attempts per returning game. It is very difficult to break serve
        averaging three (-3) failed returns per game.
        <br />
        <br />
        In a perfect world, we would just look at and compare each player’s PSI
        Overall Ratings and choose the player with the highest rating. But we
        recommend using PSI Analytics as a starting point AND THEN, consider the
        details mentioned. Good luck and good betting!
      </p>
    </div>
  );
};

export default TipsForSportsBettor1;
