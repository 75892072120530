import PercentageBar from "./PercentageBar";

const StatItem = (props) => {
  const homePercentage = props.home.value.includes("%")
    ? +props.home.value.replace("%", "")
    : +props.home.value + +props.away.value !== 0
    ? (+props.home.value / (+props.home.value + +props.away.value)) * 100
    : 0;
  const awayPercentage = props.away.value.includes("%")
    ? +props.away.value.replace("%", "")
    : +props.home.value + +props.away.value !== 0
    ? (+props.away.value / (+props.home.value + +props.away.value)) * 100
    : 0;

  return (
    <li className="space-y-1">
      <div className="flex justify-between">
        <div className="flex gap-1 font-number">
          <p>{props.home.value}</p>
          {props.home.won && (
            <p className="text-slate-300 dark:text-slate-500">
              ({props.home.won}/{props.home.total})
            </p>
          )}
        </div>
        <div>{props.name}</div>
        <div className="flex gap-1 font-number">
          {props.away.won && (
            <p className="text-slate-300 dark:text-slate-500">
              ({props.away.won}/{props.away.total})
            </p>
          )}
          <p>{props.away.value}</p>
        </div>
      </div>

      <div className="flex gap-3">
        <PercentageBar
          percentage={homePercentage}
          align="right"
          winner={homePercentage >= awayPercentage}
        />
        <PercentageBar
          percentage={awayPercentage}
          align="left"
          winner={awayPercentage >= homePercentage}
        />
      </div>
    </li>
  );
};

export default StatItem;
