import ExpandableCard from "../shared/ExpandableCard";
import TournamentItemsList from "./TournamentItemsList";

const TournamentsGroupItem = (props) => {
  // const currentDate = new Date();
  // const months = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "Jun",
  //   "Jul",
  //   "Aug",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];
  // const currentMonth = months[currentDate.getMonth()];
  // const itemMonth = props.date.split(" ")[0].slice(0, 3);
  // const isItemCurrentMonth = itemMonth === currentMonth;
  return (
    <ExpandableCard
      header={<p className="font-bold">{props.category}</p>}
      details={<TournamentItemsList tournaments={props.tournaments} />}
      initialExpanded={true}
    />
  );
};

export default TournamentsGroupItem;
