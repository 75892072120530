import { BiWorld } from "react-icons/bi";
import { findFlagUrlByCountryName } from "country-flags-svg";
import Skeleton from "./Skeleton";
import { MdFlag } from "react-icons/md";

const CountryFlag = (props) => {
  const isLoading = props.country === null;

  if (isLoading) {
    return <Skeleton className="h-4 w-6" />;
  }

  return props.country ? (
    props.country === "World" ? (
      <BiWorld className="h-5 w-5" />
    ) : (
      <img
        className={`${props.className} object-cover`}
        src={findFlagUrlByCountryName(props.country).replace(
          "USA",
          "United States"
        )}
        alt={props.country}
      />
    )
  ) : (
    <MdFlag className="h-5 w-5" />
  );
};

export default CountryFlag;
