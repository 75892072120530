const Button = (props) => {
  return (
    <button
      className={`${props.className} ${
        props.rounded ? "rounded-full px-2 py-2" : "rounded-lg px-4 py-2.5"
      } ${
        props.color || "bg-primary hover:bg-primaryFocused"
      } text-center text-sm font-medium text-white disabled:bg-slate-300 disabled:dark:bg-slate-700 disabled:dark:text-slate-500`}
      onClick={props.onClick}
      type={props.type || "button"}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default Button;
