import { motion } from "framer-motion";
import PlayerItem from "./PlayerItem";
import { useNavigate } from "react-router-dom";

const PlayersList = (props) => {
  const navigate = useNavigate();

  return (
    <motion.div
      layout
      className={`grid grid-cols-1 gap-x-5 gap-y-5 sm:grid-cols-2 md:gap-y-6 ${
        props.isDouble ? "md:grid-cols-2" : "md:grid-cols-3"
      }`}
    >
      {props.players.map((player) => (
        <PlayerItem
          player={player}
          isDouble={props.isDouble}
          navigate={navigate}
        />
      ))}
    </motion.div>
  );
};

export default PlayersList;
