import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pagesStatesActions } from "../../store/pages-states-slice";
import Filters from "../UI/Filters";
import BracketMatchesList from "./BracketMatchesList";
import {
  convertRemToPx,
  transformRoundName,
  sortMatchesForDraws,
  titlelize,
} from "../../helpers";
import { AnimatePresence, useAnimate } from "framer-motion";
import useViewport from "../../hooks/use-viewport";
import { useInView } from "react-intersection-observer";
import { range } from "lodash";
import TabsList from "../UI/TabsList";
import { motion } from "framer-motion";
import EmptyText from "../shared/EmptyText";

const RoundTabs = (props) => {
  return (
    <TabsList
      className="justify-center"
      tabs={props.tabs}
      activeTab={props.activeTab}
      tabChangeHandler={props.tabChangeHandler}
    />
  );
};

const TournamentBracket = (props) => {
  const [hoveredPlayers, setHoveredPlayers] = useState([]);

  const matchHoveredHandler = (homeId, awayId) => {
    setHoveredPlayers([homeId, awayId]);
  };

  const matchUnhoveredHandler = () => {
    setHoveredPlayers([]);
  };

  return (
    <ul className={`flex pb-6 ${props.roundName}`} key={props.roundName}>
      {props.rounds.map((round, index) => (
        <li className="shrink-0 w-full sm:w-max sm:shrink">
          <BracketMatchesList
            isDouble={props.isDouble}
            matches={round.matches}
            matchContainerHeights={props.matchContainerHeights}
            currentIndex={props.currentIndex}
            roundIndex={index}
            roundName={props.roundName}
            onMatchHovered={matchHoveredHandler}
            onMatchUnhovered={matchUnhoveredHandler}
            hoveredPlayers={hoveredPlayers}
            isLastRound={index === props.rounds.length - 1}
            surface={props.surface}
          />
        </li>
      ))}
    </ul>
  );
};

const TournamentDrawsTab = (props) => {
  const { width } = useViewport();
  const { ref, inView } = useInView({
    threshold: 0,
    initialInView: true,
    rootMargin: `-${convertRemToPx(3.5)}px 0px 0px 0px`,
  });

  const activeRound = useSelector(
    (state) => state.pagesStates.tournamentDrawsActiveRound
  ).toLowerCase();

  const dispatch = useDispatch();
  const [scope, animate] = useAnimate();
  const [currentIndex, setCurrentIndex] = useState({
    qualification: 0,
    main: 0,
  });

  const rounds = useMemo(
    () => sortMatchesForDraws(props.rounds),
    [props.rounds]
  );

  const showRoundFilter = props.rounds.qualification.length > 0;
  const isRoundEmpty = rounds[activeRound].length === 0;

  const changeMatchHeight = async (index) => {
    await Promise.all(
      range(index, rounds[activeRound].length).map((i) =>
        animate(`.${activeRound}-r${i}`, {
          height: `${matchContainerHeights[i - index]}rem`,
        })
      )
    );
  };

  const getOffset = (newIndex, containerWidth) => {
    const matchLength =
      width >= 768 ? 31 : width >= 640 ? 96 / 4 : containerWidth;
    const offset = `${-newIndex * matchLength}${width < 640 ? "px" : "rem"}`;
    return offset;
  };

  const getContainerHeight = (currentRound, newIndex) => {
    const matchHeight = 12;
    const bottomPadding = showRoundFilter
      ? width >= 768
        ? 12
        : 11
      : width >= 768
        ? 7
        : 6;
    console.log(rounds[currentRound]);
    const height =
      rounds[currentRound][newIndex].matches.length *
        (matchHeight + 2 * matchPadding) +
      bottomPadding;
    return height;
  };

  const navigateDraws = async (currentRound, newIndex) => {
    if (newIndex < 0 || newIndex >= rounds[currentRound].length) return;
    const offset = getOffset(newIndex, scope.current.offsetWidth);
    const containerHeight = getContainerHeight(currentRound, newIndex);

    if (newIndex > currentIndex[currentRound]) {
      // next
      if (newIndex < rounds[currentRound].length) {
        await animate(
          `ul.${currentRound}`,
          { x: offset },
          { ease: "easeInOut" }
        );
        animate(
          scope.current,
          { height: `${containerHeight}rem` },
          { ease: "easeInOut" }
        );
        await changeMatchHeight(newIndex);
        setCurrentIndex((existingIndex) => ({
          ...existingIndex,
          [currentRound]: newIndex,
        }));
      }
    } else {
      // previous
      animate(
        scope.current,
        { height: `${containerHeight}rem` },
        { ease: "easeInOut" }
      );
      await changeMatchHeight(newIndex);
      await animate(`ul.${currentRound}`, { x: offset }, { ease: "easeInOut" });
      setCurrentIndex((existingIndex) => ({
        ...existingIndex,
        [currentRound]: newIndex,
      }));
    }
  };

  const roundChangeHandler = async (newRound) => {
    if (rounds[newRound.toLowerCase()].length > 0) {
      const containerHeight = getContainerHeight(newRound.toLowerCase(), 0);
      animate(
        scope.current,
        { height: `${containerHeight}rem` },
        { ease: "easeInOut" }
      );
    } else {
      animate(scope.current, { height: "15rem" }, { ease: "easeInOut" });
    }
    setCurrentIndex((existingIndex) => ({
      ...existingIndex,
      [newRound.toLowerCase()]: 0,
    }));
    dispatch(
      pagesStatesActions.changeActiveRound({
        tab: "draws",
        activeRound: newRound,
      })
    );
  };

  const matchContainerHeights = [];
  const connectorHeights = [];
  const matchPadding = 0.5;
  const matchHeight = 12;
  let containerHeight = matchHeight + 2 * matchPadding;
  for (let i = 0; i < 8; i++) {
    matchContainerHeights.push(containerHeight);
    containerHeight = 2 * containerHeight;
    connectorHeights.push(containerHeight);
  }

  return (
    <div ref={scope} className="overflow-hidden">
      {!isRoundEmpty && (
        <AnimatePresence>
          {!inView && (
            <motion.div
              className="fixed left-0 top-14 z-10 w-full border-b border-gray-200 bg-white bg-opacity-50 backdrop-blur-lg backdrop-filter dark:border-slate-700 dark:bg-slate-800 dark:bg-opacity-5"
              initial={{ y: "-3.5rem" }}
              animate={{ y: 0 }}
              exit={{ y: "-3.5rem" }}
              transition={{ ease: "easeInOut" }}
            >
              {
                <RoundTabs
                  tabs={rounds[activeRound].map((round) =>
                    transformRoundName(round.name, width < 768)
                  )}
                  activeTab={transformRoundName(
                    rounds[activeRound][currentIndex[activeRound]].name,
                    width < 768
                  )}
                  tabChangeHandler={(roundName) => {
                    navigateDraws(
                      activeRound,
                      rounds[activeRound].findIndex(
                        (r) =>
                          transformRoundName(r.name, width < 768) === roundName
                      )
                    );
                  }}
                />
              }
            </motion.div>
          )}
        </AnimatePresence>
      )}
      {showRoundFilter && (
        <div className="px-4 py-6 pt-6 sm:pt-7 md:pt-8 sm:px-5 md:px-6">
          <Filters
            filtersList={["Qualification", "Main"]}
            activeItem={titlelize(activeRound)}
            onActiveItemChange={roundChangeHandler}
            className="justify-start sm:justify-center"
          />
        </div>
      )}
      {!isRoundEmpty ? (
        <>
          <div
            ref={ref}
            className={`mb-6 dark:border-slate-700 ${
              showRoundFilter ? "border-y" : " border-b"
            }`}
          >
            <RoundTabs
              tabs={rounds[activeRound].map((round) =>
                transformRoundName(round.name, width < 768)
              )}
              activeTab={transformRoundName(
                rounds[activeRound][currentIndex[activeRound]].name,
                width < 768
              )}
              tabChangeHandler={(roundName) => {
                navigateDraws(
                  activeRound,
                  rounds[activeRound].findIndex(
                    (r) => transformRoundName(r.name, width < 768) === roundName
                  )
                );
              }}
            />
          </div>
          <TournamentBracket
            isDouble={props.isDouble}
            rounds={rounds[activeRound]}
            roundName={activeRound}
            currentIndex={currentIndex[activeRound]}
            matchContainerHeights={matchContainerHeights}
            surface={props.surface}
          />
        </>
      ) : (
        <div className="h-36">
          <EmptyText>{`${titlelize(activeRound)} round has not started yet`}</EmptyText>
        </div>
      )}
    </div>
  );
};

export default TournamentDrawsTab;
