import { createPortal } from "react-dom";
import Card from "./Card";
import { useDispatch } from "react-redux";
import { uiActions } from "../../store/ui-slice";
import { MdOutlineClose } from "react-icons/md";
import { motion } from "framer-motion";
import { BiChevronRight } from "react-icons/bi";

const Modal = (props) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(uiActions.closeModal());
    dispatch(uiActions.closeAllPickers());
    dispatch(uiActions.closeMenu());
  };

  let className = "dark:text-white relative ";
  if (props.bottomModal) {
    className +=
      "h-[92dvh] w-screen sm:h-fit sm:max-h-[90dvh] sm:w-11/12 sm:max-w-7xl sm:top-1/2 sm:left-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2";
  } else {
    className += `max-h-[92dvh] ${props.width || "w-11/12"} ${
      props.maxWidth || "max-w-7xl"
    }`;
  }

  const modal = (
    <motion.div
      className={`fixed top-0 left-0 z-50 flex h-dvh w-full bg-black/20 text-sm backdrop-blur-sm dark:bg-slate-950/80 sm:text-base ${
        props.isMenu
          ? "items-start justify-end p-3"
          : props.bottomModal
            ? "items-end"
            : "items-center justify-center"
      }`}
      onClick={!props.undismissable ? closeModal : () => {}}
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
    >
      <Card
        animated
        bottomModal={props.bottomModal}
        className={`${className} ${props.className}`}
        // radius={props.bottomModal && ""}
        onClick={(e) => {
          e.stopPropagation();
          dispatch(uiActions.closeAllPickers());
        }}
        initial={
          props.bottomModal
            ? { y: "100vh", opacity: 0 }
            : {
                opacity: 0,
                scale: 0.75,
                // y: "100vh",
              }
        }
        animate={
          props.bottomModal
            ? {
                y: "0",
                opacity: 1,
                scale: 1,
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 30,
                },
              }
            : {
                opacity: 1,
                scale: 1,
                // y: 0,
                transition: {
                  ease: "easeOut",
                  duration: 0.15,
                },
              }
        }
        exit={
          props.bottomModal
            ? { y: "100vh", opacity: 0 }
            : {
                opacity: 0,
                scale: 0.75,
                // y: "100vh",
                transition: {
                  ease: "easeIn",
                  duration: 0.15,
                },
              }
        }
      >
        {props.header && (
          <div className="sticky top-0 left-0 right-0 mb-3 z-20 border-b border-gray-200 bg-white bg-opacity-50 backdrop-blur-lg backdrop-filter dark:border-slate-700 dark:bg-slate-800 dark:bg-opacity-5">
            <div className="grid grid-cols-4 grid-rows-1 items-center px-4 py-3">
              <MdOutlineClose
                className="cursor-pointer justify-self-start text-2xl col-span-1"
                onClick={closeModal}
              />
              <h2 className="text-center text-lg min-w-max sm:text-xl font-bold col-span-2">
                {props.header}
              </h2>
              {props.action ? (
                <div
                  className="justify-self-end"
                  onClick={props.actionCallback}
                >
                  {props.action}
                </div>
              ) : (
                <div className="col-span-1" />
              )}
            </div>
          </div>
        )}
        {props.children}
      </Card>
    </motion.div>
  );
  return createPortal(modal, document.getElementById("modal"));
};

export default Modal;
