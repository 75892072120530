const Paragraph = (props) => {
  return (
    <div className="space-y-2">
      <h3 className="font-bold text-2xl">{props.subtitle}</h3>
      {props.content}
    </div>
  );
};

export default Paragraph;
