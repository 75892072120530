import { BiCalendar } from "react-icons/bi";
import FormMatches from "../shared/FormMatches";
import Picker from "../UI/Picker";

const PlayerFormTab = (props) => {
  return (
    <div className="space-y-8">
      <div className="flex justify-end">
        <Picker
          name="playerDetailYearPicker"
          icon={<BiCalendar />}
          activeOption={props.matchYear}
          options={props.years}
          onOptionChange={props.matchYearChangeHandler}
          pickerBgColor="bg-slate-100 dark:bg-slate-800"
          maxDropdownHeight="max-h-64"
        />
      </div>
      <FormMatches key={props.matchYear} form={props.form} />
    </div>
  );
};

export default PlayerFormTab;
