import Skeleton from "../shared/Skeleton";

const TabItem = (props) => {
  const isLoading = !props.name;

  if (isLoading) {
    return (
      <div className="px-3 pb-4">
        <Skeleton className="h-4 w-16 rounded-full sm:w-20" />
      </div>
    );
  }

  const changeActiveTab = () => {
    props.tabChangeHandler(props.name);
  };

  const activeClassName =
    "border-b-2 border-b-primary text-primary dark:text-white dark:border-b-white";
  const hoverClassName =
    "hoverable:hover:border-b-2 hoverable:hover:border-b-primary hoverable:hover:text-primary dark:hoverable:hover:text-white dark:hoverable:hover:border-b-white";

  return (
    <div
      className={`${
        props.isActive ? activeClassName : "text-slate-500 dark:text-slate-400"
      } min-w-max cursor-pointer px-4 pb-2 ${hoverClassName}`}
      onClick={changeActiveTab}
    >
      {props.name}
    </div>
  );
};

export default TabItem;
