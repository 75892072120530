import { IoTennisballSharp } from "react-icons/io5";
import ScoreItem from "../ScoreItem";

const PeriodScoreItem = (props) => {
  return (
    <div className={`${props.className} flex gap-0.5`}>
      <div
        className={`flex h-10 w-10 items-center justify-center rounded-l-lg bg-slate-100 dark:bg-slate-700 ${
          props.winner === "away" && "text-slate-400"
        }`}
      >
        <ScoreItem
          setScore={props.homeScore}
          tiebreakScore={props.homeTiebreakScore}
        />
      </div>
      <div
        className={`flex h-10 w-10 items-center justify-center rounded-r-lg bg-slate-100 dark:bg-slate-700 ${
          props.winner === "home" && "text-slate-400"
        }`}
      >
        <ScoreItem
          setScore={props.awayScore}
          tiebreakScore={props.awayTiebreakScore}
        />
      </div>
    </div>
  );
};

const PointByPointItem = (props) => {
  const gameScores = props.games
    .map(
      (game, index) =>
        `${game.homeScore}:${game.awayScore}${
          game.homeBreakpoint || game.awayBreakpoint ? " BP" : ""
        }`
    )
    .join(", ");

  let content;
  if (!props.homeTiebreakScore) {
    content = (
      <div className="space-y-1">
        {/* <div className="flex justify-center gap-1 font-number text-sm font-light text-slate-400">
          {props.games.map((game, index) => (
            <p key={`${game.homeScore}:${game.awayScore}`}>
              {game.homeScore}:{game.awayScore}
              <span className="text-white">
                {(game.homeBreakpoint || game.awayBreakpoint) && " BP"}
              </span>
              {index < props.games.length - 1 && ","}
            </p>
          ))}
        </div> */}
        <p className="text-center font-number text-sm font-light text-slate-400">
          {gameScores}
        </p>
        {props.homeScore !== null && props.awayScore !== null && (
          <div className="flex gap-5">
            <div className="flex flex-1 items-center justify-end gap-2">
              {props.server === "home" && props.homeLostServe && (
                <p>Lost Serve</p>
              )}
              {props.server === "home" && (
                <IoTennisballSharp className="text-xl" />
              )}
            </div>
            <PeriodScoreItem
              homeScore={props.homeScore}
              awayScore={props.awayScore}
              winner={props.winner}
            />
            <div className="flex flex-1 items-center justify-start gap-2">
              {props.server === "away" && (
                <IoTennisballSharp className="text-xl" />
              )}
              {props.server === "away" && props.awayLostServe && (
                <p>Lost Serve</p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  } else {
    content = (
      <div className="space-y-3">
        <div className="flex justify-center">
          <div className="w-min rounded-md bg-primary px-3 py-1 text-white">
            Tiebreaker
          </div>
        </div>
        <PeriodScoreItem
          className="justify-center"
          homeScore={props.homeScore}
          awayScore={props.awayScore}
          homeTiebreakScore={props.homeTiebreakScore}
          awayTiebreakScore={props.awayTiebreakScore}
          winner={props.winner}
        />
        {props.games.map((game) => (
          <div className="flex">
            <div className="flex flex-1 items-center justify-end gap-2">
              {game.server === "home" && game.homeLostServe && (
                <p>Lost Serve</p>
              )}
              {game.server === "home" && (
                <IoTennisballSharp className="text-xl" />
              )}
            </div>
            <p className="px-4 font-number text-slate-400">
              {game.homeScore} - {game.awayScore}
            </p>
            <div className="flex flex-1 items-center justify-start gap-2">
              {game.server === "away" && (
                <IoTennisballSharp className="text-xl" />
              )}
              {game.server === "away" && game.awayLostServe && (
                <p>Lost Serve</p>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return <li className={props.className}>{content}</li>;
};

export default PointByPointItem;
