import { useSelector } from "react-redux";
import Skeleton from "./Skeleton";

const TournamentLogo = (props) => {
  const isLoading = !props.name;
  const isDarkMode = useSelector((state) => state.ui.darkMode);

  if (isLoading)
    return (
      <Skeleton
        className={
          props.isDetail ? "h-24 w-24 rounded-2xl" : "h-16 w-16 rounded-lg"
        }
      />
    );

  let imgLink;

  if (
    props.name.toLowerCase().includes("french open") ||
    props.name.toLowerCase().includes("roland garros")
  ) {
    imgLink = `${process.env.REACT_APP_BACKEND_URL}/images/frenchOpen.png`;
  } else if (props.name.toLowerCase().includes("australian open")) {
    imgLink = `${process.env.REACT_APP_BACKEND_URL}/images/australianOpen.png`;
  } else if (props.name.toLowerCase().includes("us open")) {
    imgLink = `${process.env.REACT_APP_BACKEND_URL}/images/usOpen.jpeg`;
  } else if (props.name.toLowerCase().includes("wimbledon")) {
    imgLink = `${process.env.REACT_APP_BACKEND_URL}/images/wimbledon.png`;
  } else if (props.name.toLowerCase().includes("united cup")) {
    imgLink = isDarkMode
      ? `${process.env.REACT_APP_BACKEND_URL}/images/unitedCupDark.png`
      : `${process.env.REACT_APP_BACKEND_URL}/images/unitedCup.png`;
  } else if (props.name.toLowerCase().includes("davis cup")) {
    imgLink = isDarkMode
      ? `${process.env.REACT_APP_BACKEND_URL}/images/davisCupDark.png`
      : `${process.env.REACT_APP_BACKEND_URL}/images/davisCup.png`;
  } else if (props.name.toLowerCase().includes("atp cup")) {
    imgLink = `${process.env.REACT_APP_BACKEND_URL}/images/atpCup.png`;
  } else if (props.category === "ATP") {
    if (props.level === "ATP 250") {
      imgLink = `${process.env.REACT_APP_BACKEND_URL}/images/atp250.png`;
    } else if (props.level === "ATP 500") {
      imgLink = `${process.env.REACT_APP_BACKEND_URL}/images/atp500.png`;
    } else if (props.level === "ATP 1000") {
      imgLink = `${process.env.REACT_APP_BACKEND_URL}/images/atp1000.png`;
    } else {
      imgLink = `${process.env.REACT_APP_BACKEND_URL}/images/atp.png`;
    }
  } else if (props.category === "WTA") {
    if (props.level === "WTA 250") {
      imgLink = isDarkMode
        ? `${process.env.REACT_APP_BACKEND_URL}/images/wta250Dark.png`
        : `${process.env.REACT_APP_BACKEND_URL}/images/wta250.png`;
    } else if (props.level === "WTA 500") {
      imgLink = isDarkMode
        ? `${process.env.REACT_APP_BACKEND_URL}/images/wta500Dark.png`
        : `${process.env.REACT_APP_BACKEND_URL}/images/wta500.png`;
    } else if (props.level === "WTA 1000") {
      imgLink = isDarkMode
        ? `${process.env.REACT_APP_BACKEND_URL}/images/wta1000Dark.png`
        : `${process.env.REACT_APP_BACKEND_URL}/images/wta1000.png`;
    } else {
      imgLink = isDarkMode
        ? `${process.env.REACT_APP_BACKEND_URL}/images/wtaDark.png`
        : `${process.env.REACT_APP_BACKEND_URL}/images/wta.png`;
    }
  } else if (props.category.includes("Challenger Men")) {
    imgLink = isDarkMode
      ? `${process.env.REACT_APP_BACKEND_URL}/images/atpChallengerDark.png`
      : `${process.env.REACT_APP_BACKEND_URL}/images/atpChallenger.png`;
  } else if (props.category.includes("Challenger Women")) {
    imgLink = isDarkMode
      ? `${process.env.REACT_APP_BACKEND_URL}/images/wta125Dark.png`
      : `${process.env.REACT_APP_BACKEND_URL}/images/wta125.png`;
  } else if (props.name.includes("M15") || props.name.includes("W15")) {
    imgLink = `${process.env.REACT_APP_BACKEND_URL}/images/itfW15s.png`;
  } else if (
    props.name.includes("M25") ||
    props.name.includes("W25") ||
    props.name.includes("W35") ||
    props.name.includes("W40") ||
    props.name.includes("W50") ||
    props.name.includes("W60") ||
    props.name.includes("W75") ||
    props.name.includes("W80") ||
    props.name.includes("W100")
  ) {
    imgLink = `${process.env.REACT_APP_BACKEND_URL}/images/itfW25s.png`;
  } else if (props.category.includes("ITF")) {
    imgLink = `${process.env.REACT_APP_BACKEND_URL}/images/itf.png`;
  }

  if (imgLink) {
    return (
      <img
        src={imgLink}
        alt={props.name}
        className={`${props.className} object-contain`}
      ></img>
    );
  } else {
    return <div className={props.className} />;
  }
};

export default TournamentLogo;
