const Skeleton = (props) => {
  // users to define the width, height and border radius in the className themselves
  return (
    <div
      className={`animate-pulse bg-slate-200 ${
        props.baseBackground ? "dark:bg-slate-900" : "dark:bg-slate-800"
      } ${props.className}`}
    />
  );
};

export default Skeleton;
