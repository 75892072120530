import { createSlice } from "@reduxjs/toolkit";
import { MONTHS } from "../constants";

const today = new Date();

const pagesStatesSlice = createSlice({
  name: "pagesStates",
  initialState: {
    scoresStatus: "All",
    scoresCategory: "All",
    scoresDateIndex: 7,
    tournamentsDate: MONTHS[today.getMonth()] + " " + today.getFullYear(),
    rankingsCategory: "ATP",
    rankingsType: "Singles",
    rankingsPage: 1,
    psiActiveSet: "Match",
    pastPsiCategory: "One Match",
    statisticsActiveSet: "Match",
    ratioActiveSet: "Match",
    pointByPointActiveSet: "Set 1",
    formActivePlayerIndex: 0,
    tournamentResultsActiveRound: "Main",
    tournamentDrawsActiveRound: "Main",
    tournamentPlayersActiveRound: "Main",
  },
  reducers: {
    changeScoresStatus(state, action) {
      state.scoresStatus = action.payload;
    },
    changeScoresCategory(state, action) {
      state.scoresCategory = action.payload;
    },
    changeScoresDateIndex(state, action) {
      state.scoresDateIndex = action.payload;
    },
    changeTournamentsDate(state, action) {
      state.tournamentsDate = action.payload;
    },
    changeRankingsCategory(state, action) {
      state.rankingsCategory = action.payload;
    },
    changeRankingsType(state, action) {
      state.rankingsType = action.payload;
    },
    changeRankingsPage(state, action) {
      state.rankingsPage = action.payload;
    },
    changeActiveSet(state, action) {
      if (action.payload.tab === "psi") {
        state.psiActiveSet = action.payload.activeSet;
      } else if (action.payload.tab === "statistics") {
        state.statisticsActiveSet = action.payload.activeSet;
      } else if (action.payload.tab === "ratio") {
        state.ratioActiveSet = action.payload.activeSet;
      } else if (action.payload.tab === "pointByPoint") {
        state.pointByPointActiveSet = action.payload.activeSet;
      }
    },
    changePastPsiCategory(state, action) {
      state.pastPsiCategory = action.payload;
    },
    changeFormActivePlayer(state, action) {
      state.formActivePlayerIndex = action.payload;
    },
    resetAllActiveSet(state) {
      state.psiActiveSet = "Match";
      state.statisticsActiveSet = "Match";
      state.ratioActiveSet = "Match";
      state.pointByPointActiveSet = "Set 1";
      state.pastPsiCategory = "One Match";
    },
    changeActiveRound(state, action) {
      if (action.payload.tab === "results") {
        state.tournamentResultsActiveRound = action.payload.activeRound;
      } else if (action.payload.tab === "draws") {
        state.tournamentDrawsActiveRound = action.payload.activeRound;
      } else if (action.payload.tab === "players") {
        state.tournamentPlayersActiveRound = action.payload.activeRound;
      }
    },
    resetAllActiveRound(state) {
      state.tournamentResultsActiveRound = "Main";
      state.tournamentDrawsActiveRound = "Main";
    },
  },
});

export const pagesStatesActions = pagesStatesSlice.actions;
export default pagesStatesSlice.reducer;
