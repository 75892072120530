import CompetitorName from "./CompetitorName";
import CompetitorPhoto from "./CompetitorPhoto";

const CompetitorPhotoAndName = (props) => {
  const isDouble = Array.isArray(props.competitor && props.competitor.image);

  return (
    <div className={`flex flex-col items-center space-y-3 ${props.className}`}>
      <CompetitorPhoto
        id={props.competitor && props.competitor.id}
        image={props.competitor ? props.competitor.image : null}
        alt={props.competitor && props.competitor.name}
        className={`sm:h-24 sm:w-24 lg:h-28 lg:w-28 ${
          isDouble ? "h-14 w-14" : "h-20 w-20"
        }`}
        clickable={props.clickable}
      />
      <CompetitorName centered competitor={props.competitor} />
    </div>
  );
};

export default CompetitorPhotoAndName;
