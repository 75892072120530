import { range } from "lodash";
import { BiCalendar, BiCategory } from "react-icons/bi";
import Filters from "../UI/Filters";
import Picker from "../UI/Picker";

const ScoresFilter = (props) => {
  const categoryOptions = [
    "All",
    "ATP",
    "WTA",
    "Challenger",
    "ITF Men",
    "ITF Women",
  ];

  const today = new Date();
  const dateOptions = [];
  const dateRange = range(-7, 2);
  dateRange.forEach((i) => {
    let option;
    if (i === -1) {
      option = "Yesterday";
    } else if (i === 0) {
      option = "Today";
    } else if (i === 1) {
      option = "Tomorrow";
    } else {
      const [dayName, month, day] = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + i
      )
        .toDateString()
        .split(" ");
      option = `${dayName} ${day} ${month}`;
    }
    dateOptions.push(option);
  });

  return (
    <div className="flex flex-col items-center justify-between gap-3 md:flex-row md:gap-0">
      <Filters
        filtersList={["All", "Live", "Completed", "Upcoming"]}
        activeItem={props.tournamentStatus}
        onActiveItemChange={props.onTournamentStatusChange}
        className="w-full justify-start md:w-auto"
      />
      <div className="flex w-full gap-3 md:w-auto">
        <Picker
          baseBackground
          name="scoresCategoryPicker"
          icon={<BiCategory />}
          activeOption={props.category}
          options={categoryOptions}
          onOptionChange={props.categoryChangeHandler}
        />
        <Picker
          baseBackground
          name="scoresDatePicker"
          icon={<BiCalendar />}
          activeOption={dateOptions[props.dateIndex]}
          options={dateOptions}
          onOptionChange={props.dateIndexChangeHandler}
        />
      </div>
    </div>
  );
};

export default ScoresFilter;
