import Button from "../components/UI/Button";
import Card from "../components/UI/Card";
import {
  MdAspectRatio,
  MdDifference,
  MdNumbers,
  MdOutlineSelectAll,
} from "react-icons/md";
import { GiTennisBall, GiTennisCourt, GiTennisRacket } from "react-icons/gi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useViewport from "../hooks/use-viewport";

const FeatureCard = (props) => {
  return (
    <Card className={`p-6 space-y-2 ${props.className}`}>
      <h3 className="font-bold text-lg text-primary">{props.title}</h3>
      {props.content}
    </Card>
  );
};

const HomePage = (props) => {
  // const [caseStudiesIndex, setCaseStudiesIndex] = useState(0);
  const { darkMode } = useSelector((state) => state.ui);
  const { width } = useViewport();
  const navigate = useNavigate();
  const breakpoint = 640;
  const isMobile = width < breakpoint;

  // const caseStudiesContents = [
  //   <>
  //     <div className="space-y-2">
  //       <h3 className="font-bold text-lg text-center text-primary">
  //         ATP Challenger, Quimper, France, 2nd Half Draw to Semis
  //       </h3>
  //       <p>
  //         In this sample, player performance data was collected for the second
  //         half of the draw of the ATP Challenger in Quimper, France in January
  //         of 2022. When the winning player plays the other winning player in the
  //         next round, PSI users can compare performance data points for their up
  //         and coming match.
  //       </p>
  //       <p>
  //         For example, performance data points were taken in the first round
  //         match, Pospisil vs Bourgue. Because Pospisil won the match, we will
  //         use his performance data points to use and compare against his second
  //         round match against Lacko. Lacko defeated Moraing in the first round
  //         so we will use Lacko’s performance data points to compare with
  //         Popspisil’s data points. In this sample, we can see all the data
  //         points from the winning player from the first round to the semifinals.
  //       </p>
  //       <p>
  //         Here we use performance data points from the previous match. Our plans
  //         include offering previous match data points (as seen here), 2 previous
  //         match cumulative data points, and all won matches in the same
  //         tournament cumulative data points as well as losing player raw data
  //         points.
  //       </p>
  //     </div>
  //     <img src={process.env.PUBLIC_URL + "draws.png"} alt="showcase" />
  //   </>,

  //   <>
  //     <div className="space-y-2">
  //       <h3 className="font-bold text-lg text-center text-primary">
  //         ATP Tour 250, Montpellier, France, 2nd Half Draw to Semis
  //       </h3>
  //       <p>
  //         At the 2022 ATP Tour 250 in Montpellier, France we were able to chart
  //         Quarterfinal matches to use player performance data points to use in
  //         the Semifinals and Finals.
  //       </p>
  //       <p>
  //         UTR Insights predictability model had the final match at Zverev 89%
  //         win probability and Bublik 11% win probability.
  //       </p>
  //       <p>
  //         Bovada Las Vegas Sports Betting had Zverev at -667 while BetUS had
  //         Alexander Bublik at +550.
  //       </p>
  //       <p>
  //         PSI Analytics had both players going into the final with similar Serve
  //         Performance and Return of Serve Performance. Bublik, on the other
  //         hand, was exhibiting much better Groundstrokes and Auxiliary
  //         Performance.
  //       </p>
  //       <p>Alexander Bublik won the final with a score of 6-4, 6-3.</p>
  //     </div>
  //     <img src={process.env.PUBLIC_URL + "draws.png"} alt="showcase" />
  //   </>,

  //   <>
  //     <div className="space-y-2">
  //       <h3 className="font-bold text-lg text-center text-primary">
  //         2022 Australian Open, Women's Draw, Quartfinals to Final
  //       </h3>
  //       <p>
  //         Not 100% perfect, but PSI Analytics is better than any other data
  //         provider out there.
  //       </p>
  //       <p>
  //         At the 2022 Australian Open, after showing solid player performance
  //         data in the Quarterfinals for success in the Semifinals, we noticed
  //         Collins returning serve better and out-performing Barty in
  //         Groundstrokes and Auxiliary heading into the final.
  //       </p>
  //       <p>
  //         Although PSI Analytics does not predict clear winners, performance
  //         data showed Collins having a shot at an upset. PSI Analytics does not
  //         take into account crowd favorite or home court advantage (which Barty
  //         clearly had), it just provides reliable data for the user based on
  //         results and tested algorithms. In a neutral court, we believe Collins
  //         would have had a better chance.
  //       </p>
  //       <p>
  //         After looking over the data of the Australian Women’s Final, Collins
  //         did in fact, out-perform Barty in Groundstrokes and Auxiliary +.2 to
  //         -.2. Unfortunately, Collins Serve Performance was nowhere close to
  //         overcoming Barty’s Serve Performance. Barty out-performed Collins in
  //         Serve +2.6 to +.8.
  //       </p>
  //     </div>
  //     <img src={process.env.PUBLIC_URL + "draws.png"} alt="showcase" />
  //   </>,
  // ];

  return (
    <div className="space-y-6">
      <div className="flex flex-col lg:flex-row pt-2 items-center">
        <div className="space-y-4 lg:space-y-6 flex flex-col items-center lg:items-start">
          <div className="space-y-2 lg:text-left text-center">
            <h1 className="font-bold text-4xl md:text-5xl text-center lg:text-left text-primary">
              PSI Tennis Analytics
            </h1>
            <h2 className="font-bold text-2xl md:text-3xl">
              Innovation Beyond Tradition
            </h2>
          </div>
          <div className="space-y-2">
            <p className="text-base lg:text-lg text-slate-500 dark:text-slate-300 text-center lg:text-left">
              Tennis player ratings and grades calculated using Performance Stat
              Index patent pending technology and formula.{" "}
            </p>
            <p className="text-base lg:text-lg text-slate-500 dark:text-slate-300 text-center lg:text-left">
              Join the ranks of professional tennis coaches, players, and sport
              bettors that rely on PSI Data for player performance breakdown and
              insights.{" "}
              {/* <span className="inline-block">
                <NavLink
                  className="flex items-center gap-0.5 text-primary hover:text-primaryFocused"
                  to="/psi-background"
                >
                  <p>Learn More</p>
                  <FaChevronRight />
                </NavLink>
              </span> */}
            </p>
          </div>
          <Button
            onClick={() => {
              navigate("/tournaments");
            }}
          >
            Get Started
          </Button>
        </div>
        <img
          src={
            isMobile
              ? darkMode
                ? process.env.PUBLIC_URL + "/home_showcase_sm_dark.png"
                : process.env.PUBLIC_URL + "/home_showcase_sm.png"
              : darkMode
                ? process.env.PUBLIC_URL + "/home_showcase_dark.png"
                : process.env.PUBLIC_URL + "/home_showcase.png"
          }
          alt="tennis-prediction"
          className="lg:w-7/12 scale-105 origin-top"
        />
      </div>

      <div className="space-y-4 md:space-y-6">
        <h2 className="font-bold text-3xl md:text-4xl">
          What is <span className="text-primary">PSI</span>?
        </h2>
        <p className="text-base md:text-lg text-slate-500 dark:text-slate-300">
          Welcome to the revolutionary world of the Performance Stats Index
          (PSI), meticulously crafted by a professional tennis coach with the
          assistance of professional coaches and tour players.
        </p>
        <div className="grid md:grid-cols-2 gap-6">
          <FeatureCard
            title="How PSI Works"
            content={
              <>
                <p>
                  PSI employs a sophisticated algorithm that evaluates player
                  performance based on several key metrics, providing a
                  comprehensive overview of their strengths and weaknesses. The
                  grading system takes into account:
                </p>
                <ul className="space-y-2 pt-4">
                  <li className="flex items-center gap-2">
                    <MdAspectRatio className="w-10 text-xl" />
                    <div className="flex-1">
                      <p className="font-bold">Good Shot to Bad Shot Ratios</p>
                      <p className="text-slate-400 text-sm">
                        Measures the quality of shots played by a player.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-center gap-2">
                    <MdDifference className="w-10 text-xl" />
                    <div className="flex-1">
                      <p className="font-bold">Ratio Differences</p>
                      <p className="text-slate-400 text-sm">
                        Analyzes the variance in performance ratios,
                        highlighting consistency or fluctuations.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-center gap-2">
                    <MdNumbers className="w-10 text-xl" />
                    <div className="flex-1">
                      <p className="font-bold">Games Played</p>
                      <p className="text-slate-400 text-sm">
                        Factors in the overall impact of games played during a
                        match or tournament.
                      </p>
                    </div>
                  </li>
                </ul>
                {/* <NavLink
                  className="flex items-center gap-1 text-primary hover:text-primaryFocused pt-2"
                  to="/psi-metrics"
                >
                  <p>Learn More</p>
                  <FaChevronRight />
                </NavLink> */}
              </>
            }
          />
          <FeatureCard
            className="row-span-2"
            title="PSI Ratings and Grades"
            content={
              <>
                <p>
                  PSI provides users with a simple and accurate measure of a
                  player's performance in terms of four areas
                </p>
                <ul className="space-y-2 pt-4">
                  <li className="flex items-center gap-2">
                    <GiTennisRacket className="w-10 text-xl" />
                    <div className="flex-1">
                      <p className="font-bold">Serve</p>
                      <p className="text-slate-400 text-sm">
                        Evaluates a player's service performance.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-center gap-2">
                    <GiTennisCourt className="w-10 text-xl" />
                    <div className="flex-1">
                      <p className="font-bold">Groundstroke and Auxiliary</p>
                      <p className="text-slate-400 text-sm">
                        Gauges a player's performance in groundstrokes and other
                        key facets of the game.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-center gap-2">
                    <MdOutlineSelectAll className="w-10 text-xl" />
                    <div className="flex-1">
                      <p className="font-bold">Overall</p>
                      <p className="text-slate-400 text-sm">
                        Final comprehensive measurement of a player's
                        performance.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-center gap-2">
                    <GiTennisBall className="w-10 text-xl" />
                    <div className="flex-1">
                      <p className="font-bold">Return of Serve</p>
                      <p className="text-slate-400 text-sm">
                        Assesses a player's effectiveness in returning serves.
                      </p>
                    </div>
                  </li>
                </ul>
                <p className="pt-4">
                  For each area, performance is graded in terms of
                </p>
                <ul className="space-y-2">
                  <li className="bg-excellentPSIColor py-2 px-4 rounded-md text-white">
                    Excellent Performance
                  </li>
                  <li className="bg-aboveAveragePSIColor py-2 px-4 rounded-md text-white">
                    Above Average Performance
                  </li>
                  <li className="text-black bg-solidPSIColor py-2 px-4 rounded-md">
                    Solid Performance
                  </li>
                  <li className="text-black bg-belowAveragePSIColor py-2 px-4 rounded-md">
                    Below Average Performance
                  </li>
                  <li className="bg-poorPSIColor py-2 px-4 rounded-md text-white">
                    Poor Performance
                  </li>
                </ul>
                {/* <NavLink
                  className="flex items-center gap-1 text-primary hover:text-primaryFocused pt-2"
                  to="/psi-performance-measurements"
                >
                  <p>Learn More</p>
                  <FaChevronRight />
                </NavLink> */}
              </>
            }
          />
          <FeatureCard
            title="Near Real-Time Performance Evaluation"
            content={
              <>
                <p>
                  Unlike traditional rankings and ratings, which reflect
                  accumulated past performances, PSI focuses on the present
                  moment. It recognizes that player performance can vary from
                  tournament to tournament, match to match, and even within a
                  set. PSI simplifies this complexity, offering you valuable
                  insights into a player's current form after each set.
                </p>
                {/* <NavLink
                  className="flex items-center gap-1 text-primary hover:text-primaryFocused pt-2"
                  to="/for-sport-bettors"
                >
                  <p>Learn More</p>
                  <FaChevronRight />
                </NavLink> */}
              </>
            }
          />
        </div>
      </div>

      {/* <div className="space-y-6">
        <h2 className="font-bold text-3xl md:text-4xl">note
          <span className="text-primary">PSI</span> Case Studies
        </h2>
        <p className="md:text-lg text-base text-slate-500 dark:text-slate-300">
          Examples of PSI providing better performance indicators in tennis
          tournaments.
        </p>
        <Card className="p-6 space-y-2 flex flex-col items-center relative">
          <button
            className="dark:bg-slate-800 bg-slate-100 rounded-full p-2 absolute top-1/2 left-6 disabled:bg-slate-50 disabled:text-slate-400"
            disabled={caseStudiesIndex === 0}
            onClick={() => {
              setCaseStudiesIndex((currentIndex) => currentIndex - 1);
            }}
          >
            <MdOutlineNavigateBefore className="text-xl" />
          </button>
          <button
            className="dark:bg-slate-800 bg-slate-100 rounded-full p-2 absolute top-1/2 right-6 disabled:bg-slate-50 disabled:text-slate-400"
            disabled={caseStudiesIndex === caseStudiesContents.length - 1}
            onClick={() => {
              setCaseStudiesIndex((currentIndex) => currentIndex + 1);
            }}
          >
            <MdOutlineNavigateNext className="text-xl" />
          </button>
          {caseStudiesContents[caseStudiesIndex]}
        </Card>
      </div> */}

      {/* <div className="space-y-4 md:space-y-6">
        <h2 className="font-bold text-3xl md:text-4xl">
          Elevate Your <span className="text-primary">Betting Experience</span>
        </h2>
        <p className="md:text-lg text-base text-slate-500 dark:text-slate-300">
          Step into the future of tennis analytics. Unlock the power of PSI and
          redefine the way you perceive and analyze tennis player performance.
          Elevate your betting game with near real-time insights that go beyond
          rankings and ratings. Experience tennis analytics at its finest.
          Experience PSI.
        </p>
        <div className="dark:bg-slate-900 bg-white rounded-2xl p-8 flex gap-2 overflow-clip justify-center">
          <img
            className="w-7/12 md:w-64 rounded-xl md:relative md:bottom-20"
            src={`${process.env.PUBLIC_URL}${
              darkMode ? "psi4.png" : "psi4_white.png"
            }`}
            alt="psi"
          />
          <img
            className="w-7/12 md:w-64 rounded-xl relative top-6 md:top-20"
            src={`${process.env.PUBLIC_URL}${
              darkMode ? "psi2.png" : "psi2_white.png"
            }`}
            alt="psi"
          />
          <img
            className="w-7/12 md:w-64 rounded-xl"
            src={`${process.env.PUBLIC_URL}${
              darkMode ? "psi1.png" : "psi1_white.png"
            }`}
            alt="psi"
          />
          <img
            className="w-7/12 md:w-64 rounded-xl relative bottom-4 md:top-20"
            src={`${process.env.PUBLIC_URL}${
              darkMode ? "psi3.png" : "psi3_white.png"
            }`}
            alt="psi"
          />
          <img
            className="w-7/12 md:w-64 rounded-xl md:relative md:bottom-20"
            src={`${process.env.PUBLIC_URL}${
              darkMode ? "psi5.png" : "psi5_white.png"
            }`}
            alt="psi"
          />
        </div>
      </div> */}
    </div>
  );
};

export default HomePage;
