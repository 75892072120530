import { createSlice } from "@reduxjs/toolkit";
import { GAME_SCORE_NUMBERS } from "../constants";

const pr = new Intl.PluralRules("en-US", { type: "ordinal" });
const suffixes = new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"],
]);
const formatOrdinals = (n) => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

const chartSlice = createSlice({
  name: "chart",
  initialState: {
    chartingMatchId: null,
    isBestOf5: null,
    tournamentType: null,
    winningPeriodScore: null,
    winningSetScore: null,
    gameScores: null,
    periodScores: null,
    setScores: null,
    breakpoint: null,
    server: null,
    isSecondServe: null,
    isTiebreaker: null,
    isSupertiebreaker: null,
    withAd: null,
    withAdvancedCoachOption: null,
    withTiebreaker: null,
    tiebreakerAt: null,
    withSupertiebreaker: null,
    firstTiebreakerServe: null,
    tiebreakerServingTimes: null,
    tiebreakerFirstPointServer: null,
    currentSet: null,
    isFinished: null,
    timeline: [],
    note: null,
  },
  reducers: {
    initMatchStates(state, action) {
      state.chartingMatchId = action.payload.matchId;
      state.isBestOf5 = action.payload.isBestOf5;
      state.tournamentType = action.payload.tournamentType;
      state.winningPeriodScore = 6;
      state.winningSetScore = action.payload.isBestOf5 ? 3 : 2;
      state.gameScores = { home: 0, away: 0 };
      state.periodScores = [
        {
          home_score: 0,
          away_score: 0,
          type: "set",
          number: 1,
        },
      ];
      state.setScores = { home: 0, away: 0 };
      state.breakpoint = { home: false, away: false };
      state.server = action.payload.firstServer;
      state.isSecondServe = false;
      state.isTiebreaker = false;
      state.isSupertiebreaker = false;
      state.withAd = true;
      state.withAdvancedCoachOption = true;
      state.withTiebreaker = true;
      state.tiebreakerAt = 6;
      state.withSupertiebreaker = false;
      state.firstTiebreakerServe = false;
      state.tiebreakerServingTimes = 1;
      state.tiebreakerFirstPointServer = null;
      state.currentSet = 1;
      state.isFinished = false;
      state.timeline.push({
        type: "first_serve",
        competitor: action.payload.firstServer,
      });
      state.timeline.push({
        type: "match_started",
      });
      state.timeline.push({
        type: "period_start",
        period_name: "1st_set",
      });
      state.note = { title: "", content: "" };
    },

    addPointEvent(state, action) {
      state.timeline.push({
        type: "point",
        competitor: action.payload.winner,
        home_score:
          state.isTiebreaker || state.isSupertiebreaker
            ? state.gameScores.home
            : GAME_SCORE_NUMBERS[state.gameScores.home],
        away_score:
          state.isTiebreaker || state.isSupertiebreaker
            ? state.gameScores.away
            : GAME_SCORE_NUMBERS[state.gameScores.away],
        server: state.server,
        result: action.payload.result,
        first_serve_fault: state.isSecondServe,
        way: action.payload.way,
      });
    },

    addPeriodScoreEvent(state, action) {
      state.timeline.push({
        type: "period_score",
        period: state.currentSet,
        home_score: state.periodScores.at(-1).home_score,
        away_score: state.periodScores.at(-1).away_score,
        server: state.server,
        result: action.payload.result,
        first_serve_fault: action.payload.firstServeFault,
        way: action.payload.way,
      });
    },

    addPeriodStartEvent(state, action) {
      state.timeline.push({
        type: "period_start",
        period_name: `${formatOrdinals(state.currentSet)}_set`,
        isSupertiebreaker: state.isSupertiebreaker,
      });
    },

    addMatchEndEvent(state, action) {
      state.timeline.push({
        type: "match_ended",
      });
    },

    setIsSecondServe(state, action) {
      state.isSecondServe = action.payload;
    },

    addGameScore(state, action) {
      const winner = action.payload.winner;
      const loser = action.payload.winner === "home" ? "away" : "home";
      if (!state.isTiebreaker && !state.isSupertiebreaker) {
        // if winner has game score of less than or equal to 30
        if (state.gameScores[winner] <= 2) {
          state.gameScores[winner] += 1;
          chartSlice.caseReducers.addPointEvent(state, action);
        }
        // if winner is at ad
        else if (state.gameScores[winner] === 4) {
          chartSlice.caseReducers.addPeriodScore(state, action);
        }
        // if winner has a game score of 40
        else {
          if (state.gameScores[loser] <= 2) {
            chartSlice.caseReducers.addPeriodScore(state, action);
          } else if (state.gameScores[loser] === 4) {
            state.gameScores[loser] -= 1;
            chartSlice.caseReducers.addPointEvent(state, action);
          } else {
            if (state.withAd) {
              state.gameScores[winner] += 1;
              chartSlice.caseReducers.addPointEvent(state, action);
            } else {
              chartSlice.caseReducers.addPeriodScore(state, action);
            }
          }
        }
        // check break point
        chartSlice.caseReducers.checkBreakpoint(state, action);
      } else {
        state.gameScores[winner] += 1;
        state.periodScores.at(-1).home_tiebreak_score = state.gameScores.home;
        state.periodScores.at(-1).away_tiebreak_score = state.gameScores.away;
        chartSlice.caseReducers.addPointEvent(state, action);

        // tiebreaker serve switching logic
        if (!state.firstTiebreakerServe) {
          state.firstTiebreakerServe = true;
          state.tiebreakerFirstPointServer = state.server;
          state.server = state.server === "home" ? "away" : "home";
        } else {
          if (state.tiebreakerServingTimes === 2) {
            state.server = state.server === "home" ? "away" : "home";
            state.tiebreakerServingTimes = 1;
          } else {
            state.tiebreakerServingTimes += 1;
          }
        }

        if (
          (state.isTiebreaker &&
            state.gameScores[winner] >=
              (state.tournamentType === "Grand Slam" &&
              state.currentSet === (state.isBestOf5 ? 5 : 3)
                ? 10
                : 7) &&
            state.gameScores[winner] - state.gameScores[loser] >= 2) ||
          (state.isSupertiebreaker &&
            state.gameScores[winner] >= 10 &&
            state.gameScores[winner] - state.gameScores[loser] >= 2)
        ) {
          state.periodScores.at(-1)[`${winner}_score`] += 1;
          chartSlice.caseReducers.endSet(state, action);
        }
      }
      state.isSecondServe = false;
    },

    addPeriodScore(state, action) {
      const winner = action.payload.winner;
      const loser = action.payload.winner === "home" ? "away" : "home";
      state.periodScores.at(-1)[`${winner}_score`] += 1;
      if (
        state.periodScores.at(-1)[`${winner}_score`] >=
          state.winningPeriodScore &&
        state.periodScores.at(-1)[`${winner}_score`] -
          state.periodScores.at(-1)[`${loser}_score`] >=
          2
      ) {
        chartSlice.caseReducers.endSet(state, action);
      } else if (
        state.periodScores.at(-1)[`${winner}_score`] === state.tiebreakerAt &&
        state.periodScores.at(-1)[`${loser}_score`] === state.tiebreakerAt
      ) {
        state.isTiebreaker = true;
        chartSlice.caseReducers.addPeriodScoreEvent(state, action);
      } else {
        chartSlice.caseReducers.addPeriodScoreEvent(state, action);
      }
      state.gameScores.home = 0;
      state.gameScores.away = 0;
      state.server = state.server === "home" ? "away" : "home";
    },

    endSet(state, action) {
      const winner = action.payload.winner;
      chartSlice.caseReducers.addPeriodScoreEvent(state, action);
      state.setScores[winner] += 1;
      if (!state.isSupertiebreaker) {
        const hasWon = state.setScores[winner] === state.winningSetScore;
        if (state.isTiebreaker) {
          state.periodScores.at(-1)["home_tiebreak_score"] =
            state.gameScores.home;
          state.periodScores.at(-1)["away_tiebreak_score"] =
            state.gameScores.away;
          if (state.tiebreakerFirstPointServer === "home") {
            state.server = "away";
          } else {
            state.server = "home";
          }
        }
        if (hasWon) {
          // chartSlice.caseReducers.endMatch();
          chartSlice.caseReducers.addMatchEndEvent(state, action);
          state.isFinished = true;
        } else {
          state.currentSet += 1;
          state.gameScores.home = 0;
          state.gameScores.away = 0;
          state.firstTiebreakerServe = false;
          state.tiebreakerServingTimes = 1;
          state.isTiebreaker = false;
          state.isSecondServe = false;
          state.tiebreakerFirstPointServer = null;
          state.periodScores.push({
            home_score: 0,
            away_score: 0,
            type: "set",
            number: state.currentSet,
          });
          if (
            state.setScores.home === state.winningSetScore - 1 &&
            state.setScores.away === state.winningSetScore - 1 &&
            state.withSupertiebreaker
          ) {
            state.isSupertiebreaker = true;
          }
          chartSlice.caseReducers.addPeriodStartEvent(state, action);
        }
      } else {
        // chartSlice.caseReducers.endMatch();
        state.periodScores.at(-1)["home_tiebreak_score"] =
          state.gameScores.home;
        state.periodScores.at(-1)["away_tiebreak_score"] =
          state.gameScores.away;
        chartSlice.caseReducers.addMatchEndEvent(state, action);
        state.isFinished = true;
      }
    },

    checkBreakpoint(state, action) {
      if (
        (state.server === "away" &&
          state.gameScores.home === 3 &&
          state.gameScores.away <= 2) ||
        (state.server === "away" &&
          !state.withAd &&
          state.gameScores.away === 3 &&
          state.gameScores.home === 3) ||
        (state.server === "away" &&
          state.withAd &&
          state.gameScores.away === 3 &&
          state.gameScores.home === 4)
      ) {
        state.breakpoint.home = true;
      } else if (
        (state.server === "home" &&
          state.gameScores.away === 3 &&
          state.gameScores.home <= 2) ||
        (state.server === "home" &&
          !state.withAd &&
          state.gameScores.home === 3 &&
          state.gameScores.away === 3) ||
        (state.server === "home" &&
          state.withAd &&
          state.gameScores.home === 3 &&
          state.gameScores.away === 4)
      ) {
        state.breakpoint.away = true;
      } else {
        state.breakpoint.home = false;
        state.breakpoint.away = false;
      }
    },

    addNote(state, action) {
      state.note.title = action.payload.title;
      state.note.content = action.payload.content;
    },

    endMatch(state, action) {
      state.chartingMatchId = null;
      state.isBestOf5 = null;
      state.tournamentType = null;
      state.winningPeriodScore = null;
      state.winningSetScore = null;
      state.gameScores = null;
      state.periodScores = null;
      state.setScores = null;
      state.breakpoint = null;
      state.server = null;
      state.isSecondServe = null;
      state.isTiebreaker = null;
      state.tiebreakerAt = null;
      state.isSupertiebreaker = null;
      state.withAd = null;
      state.withTiebreaker = null;
      state.withSupertiebreaker = null;
      state.firstTiebreakerServe = null;
      state.tiebreakerServingTimes = null;
      state.tiebreakerFirstPointServer = null;
      state.currentSet = null;
      state.isFinished = null;
      state.timeline = [];
      state.note = null;
    },
  },
});

export const ballInHandlerThunk =
  (winner, isSecondServe, result, way) => (dispatch) => {
    dispatch(
      chartActions.addGameScore({
        winner: winner,
        firstServeFault: isSecondServe,
        result: result,
        way: way,
      })
    );
  };

export const aceHandlerThunk = (server, isSecondServe) => (dispatch) => {
  dispatch(
    chartActions.addGameScore({
      winner: server,
      firstServeFault: isSecondServe,
      result: "ace",
    })
  );
};

export const faultHandlerThunk = (server, isSecondServe) => (dispatch) => {
  if (isSecondServe) {
    dispatch(
      chartActions.addGameScore({
        winner: server === "home" ? "away" : "home",
        firstServeFault: true,
        result: "double_fault",
      })
    );
  } else {
    dispatch(chartActions.setIsSecondServe(true));
  }
};

export const pointPenaltyHandlerThunk =
  (winner, isSecondServe) => (dispatch) => {
    dispatch(
      chartActions.addGameScore({
        winner: winner,
        firstServeFault: isSecondServe,
        result: winner === "home" ? "home_missed" : "away_missed",
      })
    );
  };

export const gamePenaltyHandlerThunk =
  (winner, isSecondServe) => (dispatch) => {
    dispatch(
      chartActions.addPeriodScore({
        winner: winner,
        firstServeFault: isSecondServe,
        result: winner === "home" ? "home_missed" : "away_missed",
      })
    );
  };

export const chartActions = chartSlice.actions;
export default chartSlice.reducer;
