import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chartActions } from "../../store/chart-slice";
import { uiActions } from "../../store/ui-slice";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import CompetitorName from "../shared/CompetitorName";
import CompetitorPhoto from "../shared/CompetitorPhoto";
import io from "socket.io-client";

const socket = io(process.env.REACT_APP_BACKEND_URL);

const FirstServerOption = (props) => {
  return (
    <div
      className={`flex cursor-pointer gap-2 rounded-xl p-6 ${
        props.isActive
          ? "border-2 border-primary"
          : "border-2 dark:border-slate-600"
      }`}
      onClick={props.onFirstServerChange}
    >
      <CompetitorPhoto
        id={props.competitor.id}
        image={props.competitor.image}
        alt={props.competitor.name}
        className="h-16 w-16"
      />
      <CompetitorName competitor={props.competitor} />
    </div>
  );
};

const MatchSettingsModal = (props) => {
  const [firstServer, setFirstServer] = useState("home");
  const { matchId, bestOf, tournamentType, competitors, navigate } =
    useSelector((state) => state.ui.matchSettingsModal);
  const dispatch = useDispatch();

  const startMatchHandler = () => {
    navigate(`/chart/${matchId}`);
    dispatch(
      chartActions.initMatchStates({
        matchId: matchId,
        firstServer: firstServer,
        isBestOf5: bestOf === 5,
        tournamentType: tournamentType,
      })
    );
    socket.emit("startCharting", {
      matchId: matchId,
      charterId: localStorage.getItem("charterID"),
    });
    dispatch(uiActions.closeModal());
  };

  return (
    <Modal height="h-fit" header="First Server">
      {/* <div className="grid grid-cols-3 grid-rows-1 items-center">
        <div />
        <h2 className="text-center text-lg sm:text-xl font-bold min-w-max">
          First Server
        </h2>
        <MdOutlineClose
          className="cursor-pointer justify-self-end text-2xl"
          onClick={closeModalHandler}
        />
      </div> */}
      <div className="space-y-3 px-6 pb-6">
        <FirstServerOption
          competitor={competitors[0]}
          isActive={firstServer === "home"}
          onFirstServerChange={() => {
            setFirstServer("home");
          }}
        />
        <FirstServerOption
          competitor={competitors[1]}
          isActive={firstServer === "away"}
          onFirstServerChange={() => {
            setFirstServer("away");
          }}
        />
        <div className="flex flex-1 flex-col justify-end">
          <Button className="w-full" onClick={startMatchHandler}>
            Start Match
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MatchSettingsModal;
