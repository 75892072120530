import { range } from "lodash";

const Pagination = (props) => {
  return (
    <div className="mb-5">
      <ul className="flex justify-center gap-4 sm:gap-6 md:gap-7">
        {range(1, 11).map((i) => (
          <li
            className={`cursor-pointer font-number hover:text-black dark:hover:text-white ${
              props.currentPage !== i && "text-slate-400"
            }`}
            onClick={() => {
              props.onPageChange(i);
            }}
          >
            {i}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Pagination;
