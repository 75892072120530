const Label = (props) => {
  return (
    <div
      className={`w-max rounded-md py-0.5 px-2 text-xs text-white sm:text-sm ${props.className}`}
    >
      {props.children}
    </div>
  );
};

export default Label;
