import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import CompetitorName from "../shared/CompetitorName";
import CompetitorPhoto from "../shared/CompetitorPhoto";
import CountryFlag from "../shared/CountryFlag";
import Card from "../UI/Card";
import Skeleton from "../shared/Skeleton";
import { Link } from "react-router-dom";

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const RankingsList = (props) => {
  return (
    <Card className="my-5 p-6 sm:p-7 md:p-8">
      <div className="grid grid-cols-[3rem_15fr_2fr] items-center gap-y-5 gap-x-1 sm:grid-cols-[4rem_15fr_3fr_2fr]">
        <p className="font-bold">Rank</p>
        <p className="font-bold">Player</p>
        <p className="hidden sm:block sm:font-bold">Country</p>
        <p className="font-bold">Points</p>
        {props.rankings.map((rankItem) =>
          rankItem.rank ? (
            <>
              <div className="grid grid-cols-2 gap-1 items-center font-number">
                <p>{rankItem.rank}</p>
                {rankItem.movement !== 0 && (
                  <div
                    className={`flex items-center text-xs ${
                      rankItem.movement === "down"
                        ? "text-green-500"
                        : rankItem.movement === "up" && "text-red-500"
                    }`}
                  >
                    {rankItem.movement === "down" ? (
                      <GoTriangleUp />
                    ) : (
                      rankItem.movement === "up" && <GoTriangleDown />
                    )}
                  </div>
                )}
              </div>
              <Link
                className="flex flex-1 items-center gap-4 hover:opacity-70"
                to={`/rankings/${rankItem.competitor.id}`}
              >
                <CompetitorPhoto
                  image={rankItem.competitor.image}
                  alt={rankItem.competitor.name}
                  className="h-12 w-12"
                />
                <CompetitorName
                  competitor={rankItem.competitor}
                  // className="text-lg font-semibold"
                />
              </Link>
              <div className="hidden sm:flex sm:items-center sm:gap-2">
                <CountryFlag
                  className="w-6"
                  country={rankItem.competitor.country}
                />
                <p>
                  {rankItem.competitor.country === "World"
                    ? "WOR"
                    : countries.getAlpha3Code(
                        rankItem.competitor.country,
                        "en"
                      )}
                </p>
              </div>
              <p className="font-number">{rankItem.points}</p>
            </>
          ) : (
            <>
              <Skeleton className="h-4 w-1/2 rounded-full" />
              <div className="flex items-center gap-4">
                <Skeleton className="h-12 w-12 rounded-full" />
                <Skeleton className="h-4 w-1/2 rounded-full" />
              </div>
              <div className="hidden sm:flex sm:gap-2">
                <Skeleton className="h-4 w-6" />
                <Skeleton className="h-4 w-12 rounded-full" />
              </div>
              <Skeleton className="h-4 w-4/5 rounded-full" />
            </>
          )
        )}
      </div>
    </Card>
  );
};

export default RankingsList;
