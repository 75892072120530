import { Link } from "react-router-dom";
import Card from "../UI/Card";

const BlogItem = (props) => {
  return (
    <Card>
      <Link to={props.to} className="p-4 flex flex-col gap-4 h-full">
        <img
          src={process.env.PUBLIC_URL + props.photo}
          alt={props.alt}
          className="rounded-xl object-cover flex-1"
        />
        <div className="space-y-2">
          <p className="font-bold text-xl">{props.title}</p>
          <p className="text-slate-400 text-sm">{props.content}</p>
        </div>
      </Link>
    </Card>
  );
};

export default BlogItem;
