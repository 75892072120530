import CardSubheader from "../../UI/CardSubheader";
import MatchesList from "../../scores/MatchesList";

const H2HTab = (props) => {
  return (
    <div className="space-y-8">
      <div>
        <CardSubheader name="Bios" />
        <div className="grid grid-cols-3 items-center gap-y-3">
          {props.h2h.bios.map((biosItem) => (
            <>
              <p>{biosItem.values[0]}</p>
              <p className="text-center text-slate-600 dark:text-slate-400">
                {biosItem.name}
              </p>
              <p className="text-right">{biosItem.values[1]}</p>
            </>
          ))}
        </div>
      </div>
      {props.h2h.pastMatches.length > 0 && (
        <div>
          <CardSubheader name="Past Meetings" />
          <MatchesList
            showTimeAgo
            showTournament
            isOnMatchDetailModal={props.isOnMatchDetailModal}
            matches={props.h2h.pastMatches}
          />
        </div>
      )}
    </div>
  );
};

export default H2HTab;
