import Card from "../../UI/Card";
import ScoresSummary from "./ScoresSummary";
import CompetitorPhotoAndName from "../CompetitorPhotoAndName";
import Skeleton from "../Skeleton";
import { Link } from "react-router-dom";
import { transformRoundName } from "../../../helpers";
import Label from "../../UI/Label";
import { MdDewPoint } from "react-icons/md";
import MatchScores from "../../scores/MatchScores";

const PSIIndicator = (props) => {
  const statisticalEdgeLabel = (
    <Label className="bg-green-700">Statistical Edge</Label>
  );
  const statisticalLeanLabel = (
    <Label className="bg-green-500">Statistical Lean</Label>
  );
  const statisticalTieLabel = (
    <Label className="bg-slate-300 dark:bg-slate-700 text-black">
      Statistical Tie
    </Label>
  );

  const pastPsiDiff = props.pastPsi && {
    ...(props.pastPsi.oneMatch.length !== 0 && {
      oneMatch: {
        home:
          props.pastPsi.oneMatch.find((psiData) => psiData.name === "Overall")
            .home.value -
          props.pastPsi.oneMatch.find((psiData) => psiData.name === "Overall")
            .away.value,
        away:
          props.pastPsi.oneMatch.find((psiData) => psiData.name === "Overall")
            .away.value -
          props.pastPsi.oneMatch.find((psiData) => psiData.name === "Overall")
            .home.value,
      },
    }),
    ...(props.pastPsi.twoMatch.length !== 0 && {
      twoMatch: {
        home:
          props.pastPsi.twoMatch.find((psiData) => psiData.name === "Overall")
            .home.value -
          props.pastPsi.twoMatch.find((psiData) => psiData.name === "Overall")
            .away.value,
        away:
          props.pastPsi.twoMatch.find((psiData) => psiData.name === "Overall")
            .away.value -
          props.pastPsi.twoMatch.find((psiData) => psiData.name === "Overall")
            .home.value,
      },
    }),
    ...(props.pastPsi.tournament.length !== 0 && {
      tournament: {
        home:
          props.pastPsi.tournament.find((psiData) => psiData.name === "Overall")
            .home.value -
          props.pastPsi.tournament.find((psiData) => psiData.name === "Overall")
            .away.value,
        away:
          props.pastPsi.tournament.find((psiData) => psiData.name === "Overall")
            .away.value -
          props.pastPsi.tournament.find((psiData) => psiData.name === "Overall")
            .home.value,
      },
    }),
  };

  return (
    <div className="space-y-2">
      <p className="text-center font-bold">PSI Indicator</p>
      <div className="grid grid-rows-3 grid-cols-3 gap-y-1">
        {pastPsiDiff && pastPsiDiff.oneMatch && (
          <>
            <div className="place-self-end">
              {pastPsiDiff &&
                (Math.abs(pastPsiDiff.oneMatch.home) <= 0.1
                  ? statisticalTieLabel
                  : pastPsiDiff.oneMatch.home > 0.2
                    ? statisticalEdgeLabel
                    : pastPsiDiff.oneMatch.home > 0.1 && statisticalLeanLabel)}
            </div>
            <p className="text-slate-400 text-center">One Match</p>
            <div className="place-self-start">
              {pastPsiDiff &&
                (Math.abs(pastPsiDiff.oneMatch.away) <= 0.1
                  ? statisticalTieLabel
                  : pastPsiDiff.oneMatch.away > 0.2
                    ? statisticalEdgeLabel
                    : pastPsiDiff.oneMatch.away > 0.1 && statisticalLeanLabel)}
            </div>
          </>
        )}
        {pastPsiDiff && pastPsiDiff.twoMatch && (
          <>
            <div className="place-self-end">
              {pastPsiDiff &&
                (Math.abs(pastPsiDiff.twoMatch.home) <= 0.1
                  ? statisticalTieLabel
                  : pastPsiDiff.twoMatch.home > 0.2
                    ? statisticalEdgeLabel
                    : pastPsiDiff.twoMatch.home > 0.1 && statisticalLeanLabel)}
            </div>
            <p className="text-slate-400 text-center">Two Match</p>
            <div className="place-self-start">
              {pastPsiDiff &&
                (Math.abs(pastPsiDiff.twoMatch.away) <= 0.1
                  ? statisticalTieLabel
                  : pastPsiDiff.twoMatch.away > 0.2
                    ? statisticalEdgeLabel
                    : pastPsiDiff.twoMatch.away > 0.1 && statisticalLeanLabel)}
            </div>
          </>
        )}
        {pastPsiDiff && pastPsiDiff.tournament && (
          <>
            <div className="place-self-end">
              {pastPsiDiff &&
                (Math.abs(pastPsiDiff.tournament.home) <= 0.1
                  ? statisticalTieLabel
                  : pastPsiDiff.tournament.home > 0.2
                    ? statisticalEdgeLabel
                    : pastPsiDiff.tournament.home > 0.1 &&
                      statisticalLeanLabel)}
            </div>
            <p className="text-slate-400 text-center">Tournament</p>
            <div className="place-self-start">
              {pastPsiDiff &&
                (Math.abs(pastPsiDiff.tournament.away) <= 0.1
                  ? statisticalTieLabel
                  : pastPsiDiff.tournament.away > 0.2
                    ? statisticalEdgeLabel
                    : pastPsiDiff.tournament.away > 0.1 &&
                      statisticalLeanLabel)}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const MatchDetailBanner = (props) => {
  const isLoading = !props.competitors;
  const startDatetime = new Date(props.startTime);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const time = startDatetime.toLocaleTimeString(undefined, options);

  const seasonDetail = !isLoading && (
    <p className="text-center font-bold">
      {props.season.name}
      {props.season.qualification ? " - Qualification" : ""} -{" "}
      {transformRoundName(props.season.round)}
    </p>
  );

  const content = (
    <>
      <div className="flex flex-col items-center space-y-1">
        {isLoading ? (
          <Skeleton className="h-5 w-20 rounded-full" />
        ) : props.isOnMatchDetailModal ? (
          seasonDetail
        ) : (
          <Link
            className="hover:opacity-70"
            to={`/tournaments/${props.season.id}`}
          >
            {seasonDetail}
          </Link>
        )}
        {isLoading ? (
          <Skeleton className="h-4 w-32 rounded-full" />
        ) : (
          <p className="text-center text-sm text-slate-600 dark:text-slate-400">
            {`${startDatetime.toDateString()} - ${time}`}
          </p>
        )}
        {!isLoading &&
          props.season.surface &&
          props.season.surface.toLowerCase() === "clay" &&
          props.dewPoint && (
            <Label className="bg-primary flex gap-1 items-center">
              <MdDewPoint className="text-lg" />
              <p>{`${props.dewPoint}°`}</p>
            </Label>
          )}
      </div>
      <div className="flex items-center justify-around">
        <CompetitorPhotoAndName
          competitor={props.competitors && props.competitors[0]}
          className="flex-1"
          clickable={props.competitorsClickable}
        />
        <ScoresSummary
          matchStatus={props.status}
          homeMatchScore={props.competitors && props.competitors[0].totalScore}
          awayMatchScore={props.competitors && props.competitors[1].totalScore}
        />
        <CompetitorPhotoAndName
          competitor={props.competitors && props.competitors[1]}
          className="flex-1"
          clickable={props.competitorsClickable}
        />
      </div>
      {props.status === "Not Started" || props.status === "1" ? (
        props.pastPsi && (
          <>
            <hr className="dark:border-slate-700" />
            <div
              className={props.isOnMatchDetailModal && "px-4 sm:px-5 md:px-6"}
            >
              <PSIIndicator pastPsi={props.pastPsi} />
            </div>
          </>
        )
      ) : (
        <>
          <hr className="dark:border-slate-700" />
          <div className={props.isOnMatchDetailModal && "px-4 sm:px-5 md:px-6"}>
            <MatchScores
              isInMatchDetail
              status={props.status}
              court={props.court}
              startTime={new Date(props.startTime)}
              competitors={props.competitors}
              gameStats={props.gameStats}
              pointByPoint={props.pointByPoint}
            />
          </div>
        </>
      )}
    </>
  );

  // for match detail modal
  if (props.isOnMatchDetailModal) {
    return <div className="space-y-5 pb-5">{content}</div>;
  }

  return (
    <Card className="max-w-7xl space-y-5 py-6 px-3 sm:px-4 md:px-8">
      {content}
    </Card>
  );
};

export default MatchDetailBanner;
