import axios from "axios";
import { MONTHS } from "../constants";
import { getErrorMessage } from "../helpers";

export const getTournaments = async (monthYear) => {
  try {
    const [month, year] = monthYear.split(" ");
    const monthNum = MONTHS.findIndex((monthStr) => monthStr === month) + 1;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/tournaments?month=${monthNum}&year=${year}&timezone=${timezone}`
    );
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export const getTournamentDetail = async (tournamentId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/tournaments/` + tournamentId
    );
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};
