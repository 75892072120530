const PlayerRank = (props) => {
  return (
    <div className="text-xs border border-slate-800 dark:border-white rounded-md overflow-hidden text-center">
      <div className="dark:bg-white bg-slate-800 text-white dark:text-black px-1">
        {props.rank.name}
      </div>
      <div className="px-1 font-number">{props.rank.rank}</div>
    </div>
  );
};

export default PlayerRank;
