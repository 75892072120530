import TournamentsFilter from "../components/tournaments/TournamentsFilter";
import TournamentsGroupList from "../components/tournaments/TournamentsGroupList";
import { defer } from "react-router-dom";
import { getTournaments } from "../api/tournaments-api";
import { useQuery } from "react-query";
import EmptyPage from "./EmptyPage";
import { useDispatch, useSelector } from "react-redux";
import { pagesStatesActions } from "../store/pages-states-slice";
import { MONTHS } from "../constants";
import { random, range } from "lodash";
import { useEffect } from "react";
import PageTitle from "../components/shared/PageTitle";

const today = new Date();
const currentMonthYear = MONTHS[today.getMonth()] + " " + today.getFullYear();

const tournamentsQuery = (monthYear) => ({
  queryKey: ["tournaments", monthYear],
  queryFn: async () => getTournaments(monthYear),
});

const TournamentsPage = (props) => {
  const { tournamentsDate: activeMonthYear } = useSelector(
    (state) => state.pagesStates
  );
  const dispatch = useDispatch();
  const {
    data: allTournamentGroups,
    isLoading,
    error,
    refetch,
  } = useQuery(tournamentsQuery(activeMonthYear));

  // const scroll = useCallback((node) => {
  //   if (node !== null) {
  //     window.scrollTo({
  //       top: node.getBoundingClientRect().top - 90,
  //       behavior: "smooth",
  //     });
  //   }
  // }, []);
  useEffect(() => {
    document.title = "Tennis Tournaments - PSI Tennis Analytics";
  }, []);

  if (error) {
    throw new Error(error);
  }

  const monthYearChangeHandler = (newMonthYear) => {
    dispatch(pagesStatesActions.changeTournamentsDate(newMonthYear));
    refetch();
  };

  const tournamentsData = isLoading
    ? range(random(3, 10))
    : allTournamentGroups;

  // const filteredTournaments = filterTournaments(
  //   allTournamentGroups,
  //   activeTournamentType
  // );

  return (
    <>
      <PageTitle>Tournaments</PageTitle>
      <TournamentsFilter
        activeMonthYear={activeMonthYear}
        onMonthYearChange={monthYearChangeHandler}
      />
      {tournamentsData.length === 0 ? (
        <EmptyPage text="Uh oh... No tournaments found" />
      ) : (
        <TournamentsGroupList tournaments={tournamentsData} />
      )}
    </>
  );
};

export default TournamentsPage;

export const loader = (queryClient) => {
  return async () => {
    return defer({
      promise:
        queryClient.getQueryData(tournamentsQuery(currentMonthYear)) ??
        queryClient.fetchQuery(tournamentsQuery(currentMonthYear)),
    });
  };
};
