import PSIItem from "./PSIItem";

const PSIList = (props) => {
  return (
    <ul className="space-y-8">
      {(props.psiList ? props.psiList : [null, null, null]).map(
        (psi, index) => {
          if (psi === null) return <PSIItem />;
          else {
            return psi.home.value !== null && psi.away.value !== null ? (
              <PSIItem
                key={psi.name}
                name={psi.name}
                home={psi.home}
                away={psi.away}
              />
            ) : (
              <div key={index} />
            );
          }
        }
      )}
    </ul>
  );
};

export default PSIList;
