import Card from "../UI/Card";
import TournamentLogo from "../shared/TournamentLogo";
import { startCase } from "lodash";
import { BiCalendar, BiMoney } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import { GiTennisCourt } from "react-icons/gi";
import { formatDate } from "../../helpers";
import Skeleton from "../shared/Skeleton";

const TournamentSummary = (props) => {
  const isLoading = !props.name;

  const location = isLoading ? (
    <Skeleton className="h-4 w-24 rounded-full" />
  ) : (
    <div className="flex items-center justify-start gap-2">
      <MdLocationPin />
      <p>
        {props.city}, {props.location}
      </p>
    </div>
  );

  const dates = isLoading ? (
    <Skeleton className="h-4 w-20 rounded-full" />
  ) : (
    <div className="flex items-center justify-start gap-2">
      <BiCalendar />
      <p className="text-slate-700 dark:text-slate-400">
        {formatDate(new Date(props.startDate))} -{" "}
        {formatDate(new Date(props.endDate))}
      </p>
    </div>
  );

  const surface = isLoading ? (
    <Skeleton className="h-4 w-20 rounded-full" />
  ) : (
    <div className="flex items-center justify-start gap-2 text-slate-700 dark:text-slate-400">
      <GiTennisCourt />
      <p className="text-slate-700 dark:text-slate-400">
        {startCase(props.surface)}
      </p>
    </div>
  );

  const prizeMoney = isLoading ? (
    <Skeleton className="h-4 w-20 rounded-full" />
  ) : (
    <div className="flex items-center justify-start gap-2 text-slate-700 dark:text-slate-400">
      <BiMoney />
      <p className="text-slate-700 dark:text-slate-400">{props.prizeMoney}</p>
    </div>
  );

  return (
    <Card key={isLoading} className="p-5 md:p-8">
      <div className="flex flex-row items-center justify-center gap-4 sm:gap-8">
        <TournamentLogo
          isDetail
          name={props.name}
          category={props.category}
          level={props.level}
          className="h-auto md:w-32 w-24"
        />
        <div className="space-y-1 text-start flex-1">
          {isLoading ? (
            <Skeleton className="h-8 w-36 rounded-md" />
          ) : (
            <p className="text-xl font-bold sm:text-2xl">{props.name}</p>
          )}
          <div className="space-y-0.5 text-slate-700 dark:text-slate-400">
            {location}
            {dates}
            {surface}
            {props.prizeMoney && prizeMoney}
          </div>
        </div>
      </div>
      {/* 
      <div className="mt-8 hidden justify-center space-x-8 divide-x text-center dark:divide-slate-600 sm:flex">
        <div>
          <p className="font-bold">Surface</p>
          <p>{startCase(props.surface)}</p>
        </div>
        <div className="pl-8">
          <p className="font-bold">Singles Draw</p>
          <p>32</p>
        </div>
        <div className="pl-8">
          <p className="font-bold">Doubles Draw</p>
          <p>16</p>
        </div>
      </div> */}
    </Card>
  );
};

export default TournamentSummary;
