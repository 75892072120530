import RankingsList from "../components/rankings/RankingsList";
import { defer } from "react-router-dom";
import { getRankings } from "../api/rankings-api";
import { useQuery } from "react-query";
import RankingsFilter from "../components/rankings/RankingsFilter";
import EmptyPage from "./EmptyPage";
import { useDispatch, useSelector } from "react-redux";
import { pagesStatesActions } from "../store/pages-states-slice";
import Pagination from "../components/rankings/Pagination";
import { range } from "lodash";
import { useEffect } from "react";
import PageTitle from "../components/shared/PageTitle";

const rankingsQuery = (tournamentCategory, tournamentType, page) => ({
  queryKey: ["rankings", tournamentCategory, tournamentType, page],
  queryFn: async () => getRankings(tournamentCategory, tournamentType, page),
});

const RankingsPage = (props) => {
  const {
    rankingsCategory: tournamentCategory,
    rankingsType: tournamentType,
    rankingsPage,
  } = useSelector((state) => state.pagesStates);
  const dispatch = useDispatch();
  const {
    data: rankings,
    isLoading,
    error,
    refetch,
  } = useQuery(rankingsQuery(tournamentCategory, tournamentType, rankingsPage));

  useEffect(() => {
    document.title = "Tennis Rankings - PSI Tennis Analytics";
  }, []);

  if (error) {
    throw new Error(error);
  }

  const tournamentCategoryChangeHandler = (tournamentCategories, index) => {
    dispatch(
      pagesStatesActions.changeRankingsCategory(tournamentCategories[index])
    );
    // refetch();
  };

  const tournamentTypeChangeHandler = (tournamentTypes, index) => {
    dispatch(pagesStatesActions.changeRankingsType(tournamentTypes[index]));
    // refetch();
  };

  const pageChangeHandler = (newPage) => {
    dispatch(pagesStatesActions.changeRankingsPage(newPage));
    refetch();
  };

  const rankingsData = isLoading ? range(100) : rankings;
  // const rankingsData = range(100);

  return (
    <>
      <PageTitle>Rankings</PageTitle>
      <RankingsFilter
        tournamentCategory={tournamentCategory}
        tournamentCategoryChangeHandler={tournamentCategoryChangeHandler}
        tournamentType={tournamentType}
        tournamentTypeChangeHandler={tournamentTypeChangeHandler}
      />
      {rankingsData.length > 0 ? (
        <>
          <RankingsList rankings={rankingsData} />
          <Pagination
            currentPage={rankingsPage}
            onPageChange={pageChangeHandler}
          />
        </>
      ) : (
        <EmptyPage />
      )}
    </>
  );
};

export default RankingsPage;

export const loader = (queryClient) => {
  return async () => {
    return defer({
      promise:
        queryClient.getQueryData(rankingsQuery("ATP", "Singles", 1)) ??
        queryClient.fetchQuery(rankingsQuery("ATP", "Singles", 1)),
    });
  };
};
