import { useSelector } from "react-redux";
import Modal from "../UI/Modal";
import PlayerItem from "./PlayerItem";
import { range } from "lodash";

const DoublePlayerModal = (props) => {
  const { player, navigate } = useSelector(
    (state) => state.ui.doublePlayerModal
  );

  return (
    <Modal className="p-6">
      <ul className="space-y-4">
        {range(0, 2).map((i) => (
          <PlayerItem
            player={{
              id: player.id.split("-")[i],
              name: player.name[i],
              image: player.image[i],
              country: player.country[i],
            }}
            isDouble={false}
            navigate={navigate}
          />
        ))}
      </ul>
    </Modal>
  );
};

export default DoublePlayerModal;
