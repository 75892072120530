import { forwardRef, useState } from "react";
import Card from "../UI/Card";
import { FaChevronDown } from "react-icons/fa";
import Skeleton from "./Skeleton";
import { useIsFetching } from "react-query";
import { motion } from "framer-motion";

const ExpandableCard = forwardRef((props, ref) => {
  const isLoading = useIsFetching({
    predicate: (query) => query.state.status === "loading",
  });

  const [showDetails, setShowDetails] = useState(props.initialExpanded);

  const triggerShowDetails = () => {
    if (props.details) {
      setShowDetails((currentShowDetails) => !currentShowDetails);
    }
  };

  return (
    <Card
      animated
      className="p-3 sm:p-5"
      overflow="overflow-hidden"
      innerRef={ref}
      initial={false}
      animate={showDetails ? "open" : "closed"}
    >
      <motion.div layout className="flex items-center justify-between gap-1">
        {isLoading ? (
          <Skeleton className="h-3 w-2/4 rounded-full" />
        ) : (
          props.header
        )}
        {isLoading ? (
          <Skeleton className="h-7 w-7 rounded-full" />
        ) : (
          <motion.button
            type="button"
            onClick={triggerShowDetails}
            className="rounded-full p-2 hover:bg-slate-50 dark:hover:bg-slate-800"
            variants={{ open: { rotate: 180 }, closed: { rotate: 0 } }}
            transition={{ duration: 0.2 }}
          >
            {props.details && <FaChevronDown />}
          </motion.button>
        )}
      </motion.div>
      {showDetails && props.details && (
        <motion.hr layout className="my-3 dark:border-slate-600 sm:my-4" />
      )}
      {showDetails && props.details}
    </Card>
  );
});

export default ExpandableCard;
