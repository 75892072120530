const NoteTab = (props) => {
  return (
    <div>
      <p className="w-full text-xl font-bold dark:bg-slate-900">
        {props.note.title}
      </p>
      <hr className="dark:border-slate-700 my-3" />
      <p className="w-full dark:bg-slate-900 whitespace-pre-wrap text-base">
        {props.note.content}
      </p>
    </div>
  );
};

export default NoteTab;
