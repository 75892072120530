import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { chartActions } from "../../store/chart-slice";
import { uiActions } from "../../store/ui-slice";
import CompetitorName from "../shared/CompetitorName";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import io from "socket.io-client";
import Label from "../UI/Label";

const socket = io(process.env.REACT_APP_BACKEND_URL);

const MatchItem = (props) => {
  const isLoading = !props.competitors;

  const { chartingMatchId } = useSelector((state) => state.chart.present);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const backToMatchHandler = () => {
    navigate(`/chart/${chartingMatchId}`);
    dispatch(uiActions.closeModal());
  };

  const clearMatchHandler = () => {
    socket.emit("stopCharting", chartingMatchId);
    dispatch(uiActions.closeModal());
    dispatch(chartActions.endMatch());
    dispatch(UndoActionCreators.clearHistory());
  };

  const matchClickHandler = () => {
    if (chartingMatchId === props.id) {
      backToMatchHandler();
    } else if (chartingMatchId !== null) {
      dispatch(
        uiActions.openAlertModal({
          header: "Uh-oh!",
          message: `You still haven't completed charting match ${chartingMatchId}.
      Please complete it before charting another one.`,
          actions: [
            {
              name: "Back to my current match",
              onClick: backToMatchHandler,
            },
            {
              name: "Clear my current match states and chart another one.",
              onClick: clearMatchHandler,
            },
          ],
        })
      );
    } else if (props.status === "Cancelled") {
      dispatch(
        uiActions.openAlertModal({
          header: "Uh-oh!",
          message:
            "This match has been cancelled. Please chart another match instead.",
        })
      );
    }
    // else if (
    //   props.status === "not_started" ||
    //   props.status === "match_about_to_start" ||
    //   props.status === "start_delayed"
    // ) {
    //   dispatch(
    //     uiActions.openAlertModal({
    //       header: "This match is not started yet.",
    //       message: "Try again in a few moments.",
    //     })
    //   );
    // }
    else if (props.charted) {
      dispatch(
        uiActions.openAlertModal({
          header: "Uh-oh!",
          message:
            "This match has already been charted. Please try another match.",
        })
      );
    } else if (props.chartingBy) {
      if (props.chartingBy !== localStorage.getItem("charterID")) {
        dispatch(
          uiActions.openAlertModal({
            header: "Uh-oh!",
            message:
              "This match is currently being charted by another charter. Please try another match.",
          })
        );
      } else {
        dispatch(
          uiActions.openAlertModal({
            header: "Uh-oh!",
            message:
              "You are currently charting this match on another device. Please continue charting on that device.",
          })
        );
      }
    } else {
      dispatch(
        uiActions.openMatchSettingsModal({
          matchId: props.id,
          bestOf: props.bestOf,
          tournamentType: props.tournamentType,
          competitors: props.competitors,
          navigate: navigate,
        })
      );
    }
  };

  const matchStatus =
    props.status === "Not Started" || props.status === "1"
      ? props.startTime.toLocaleTimeString("en-US", {
          hour12: true,
          hour: "numeric",
          minute: "numeric",
        })
      : props.status;

  return (
    <div
      className={`cursor-pointer space-y-2 rounded-2xl py-3 xs:px-2 sm:px-3 md:px-4 ${
        props.className
      } ${
        !isLoading &&
        "hoverable:hover:bg-slate-50 hoverable:dark:hover:bg-slate-800"
      }`}
      onClick={matchClickHandler}
    >
      {props.charted && (
        <div className="flex justify-center">
          <Label className="bg-primary">Charted</Label>
        </div>
      )}
      {props.chartingBy && (
        <div className="flex justify-center">
          <Label className="bg-primary">Charting</Label>
        </div>
      )}
      <div className="text-center text-xs text-slate-400 sm:text-sm">
        {matchStatus}
      </div>
      <div className="flex items-center">
        <div className="flex-1">
          <CompetitorName
            withFlag
            competitor={!isLoading && props.competitors[0]}
            // className="w-max"
            isWinner={
              props.status === "Not Started" ||
              (props.competitors && props.competitors[0].winner)
            }
          />
        </div>
        {!isLoading && <p className="text-slate-400">vs</p>}
        <div className="flex flex-1 justify-end">
          <CompetitorName
            withFlag
            competitor={!isLoading && props.competitors[1]}
            // className="w-max"
            isWinner={
              props.status === "Not Started" ||
              (props.competitors && props.competitors[1].winner)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MatchItem;
