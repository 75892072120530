import Number from "./Number";

const RatioList = (props) => {
  const resultList = [];
  props.waysList.forEach((way) => {
    const homeRatio = +way.home.win - +way.home.lose;
    const awayRatio = +way.away.win - +way.away.lose;
    resultList.push(
      <Number sign={+way.home.win > 0 && "+"}>{+way.home.win}</Number>
    );
    resultList.push(
      <Number sign={-way.home.lose < 0 && "-"}>{-way.home.lose}</Number>
    );
    resultList.push(
      <Number background sign={homeRatio > 0 ? "+" : homeRatio < 0 ? "-" : ""}>
        {homeRatio > 0 && "+"}
        {homeRatio}
      </Number>
    );
    resultList.push(<div className="px-2 text-center">{way.name}</div>);
    resultList.push(
      <Number sign={+way.away.win > 0 && "+"}>{+way.away.win}</Number>
    );
    resultList.push(
      <Number sign={-way.away.lose < 0 && "-"}>{-way.away.lose}</Number>
    );
    resultList.push(
      <Number background sign={awayRatio > 0 ? "+" : awayRatio < 0 ? "-" : ""}>
        {awayRatio > 0 && "+"}
        {awayRatio}
      </Number>
    );
  });
  return (
    <div className="grid grid-flow-row grid-cols-ratio grid-rows-[9] justify-center items-center justify-items-center gap-x-0.5 gap-y-5">
      {resultList}
    </div>
  );
};

export default RatioList;
