import { useDispatch, useSelector } from "react-redux";
import { pagesStatesActions } from "../../store/pages-states-slice";
import Filters from "../UI/Filters";
import TournamentResultItem from "./TournamentResultItem";
import { range } from "lodash";
import EmptyText from "../shared/EmptyText";

const TournamentResultsTab = (props) => {
  const isLoading = !props.rounds;

  const activeRound = useSelector(
    (state) => state.pagesStates.tournamentResultsActiveRound
  );
  const dispatch = useDispatch();
  const roundsList = isLoading
    ? range(3)
    : [...props.rounds[activeRound.toLowerCase()]].reverse();

  const roundChangeHandler = (newRound) => {
    dispatch(
      pagesStatesActions.changeActiveRound({
        tab: "results",
        activeRound: newRound,
      })
    );
  };

  return (
    <div className="space-y-6">
      {!isLoading &&
        (props.rounds.group.length > 0 ||
          props.rounds.qualification.length > 0) && (
          <Filters
            filtersList={
              props.rounds.group.length > 0
                ? ["Group", "Main"]
                : ["Qualification", "Main"]
            }
            activeItem={activeRound}
            onActiveItemChange={roundChangeHandler}
            className="justify-start sm:justify-center"
          />
        )}
      {roundsList.length === 0 ? (
        <EmptyText>{`${activeRound} round has not started yet`}</EmptyText>
      ) : (
        <ul className="space-y-6">
          {roundsList.map((round, index) => (
            <li key={index}>
              <TournamentResultItem
                bestOf={!isLoading && props.bestOf}
                roundName={!isLoading && round.name}
                matches={!isLoading && round.matches}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TournamentResultsTab;
