const ForSportBettors = (props) => {
  return (
    <div className="space-y-4 flex flex-col text-base sm:text-lg">
      <div>
        <p className="font-bold text-xl sm:text-3xl bg-gradient-to-r from-blue-400 to-emerald-400 inline-block text-transparent bg-clip-text">
          "Rankings and Ratings measure ability, the PSI Tennis App measures
          performance"
        </p>
        <p className="text-slate-400">- PSI Tennis App Beta Tester</p>
      </div>

      <p>
        In the past, recreational sports betting was as easy as looking at
        professional tennis organization rankings and labeling the higher ranked
        player as “the favorite.” The process of choosing the favorite through
        ranking is still practiced today. We see it when a professional
        tournament releases the draw and the seeded players are identified.
      </p>
      <p>
        With the rise in popularity and effectiveness of the rating system such
        as the Universal Rating System (UTR) and the newly released World Tennis
        Number (WTN), sports bettors have another source of valuable information
        that will quickly identify “the favorite.” In the case of UTR, “UTR's
        algorithm calculates ratings from the last 30 eligible matches played
        within the preceding 12 months.”
      </p>
      <p>
        Rankings and Ratings are an invaluable tool for a sports bettor,
        recreational or professional. Both measure the accumulation of
        performances from previous results. They are needed to compare players
        and establish FAVORITE and UNDERDOG status.
      </p>
      <p>
        Where Rankings and Ratings fall short is, IN THE NOW. In tennis speaking
        terms, it means how the player is playing in this tournament, under
        these conditions, on this surface and, in many cases, the player’s
        current mindset and attitude. What happened six or seven months ago
        between two similar skilled players on a slow surface and humid
        conditions may not matter as much IN THE NOW that sees the same two
        players play on a faster court, dry conditions, and one player with a
        new coach. PSI will provide IN THE NOW data after every match in the
        tournament.
      </p>
      <p>
        We invite you to step into the future with professionally curated tennis
        analytics by a tour and developmental coach with tennis match and player
        statistics, tennis player performance comparisons, and men’s and women’s
        tennis scores that will self-guide you to your very own conclusions and
        tennis predictions. Share your knowledge and betting tips with friends
        and family by unlocking the power of PSI as it will redefine the way you
        examine tennis player performance. Increase betting odds with near
        real-time insights that go beyond rankings and ratings. Experience
        tennis analytics in its sophistication, yet simple and easy to
        understand data, experience PSI.
      </p>
      <p></p>
    </div>
  );
};

export default ForSportBettors;
