import { transformRoundName } from "../../helpers";
import MatchesList from "../scores/MatchesList";
import CardSubheader from "../UI/CardSubheader";

const TournamentResultItem = (props) => {
  return (
    <div>
      <CardSubheader name={transformRoundName(props.roundName)} />
      <MatchesList
        showStartDate
        tournamentDetail
        matches={props.matches}
        bestOf={props.bestOf}
      />
    </div>
  );
};

export default TournamentResultItem;
