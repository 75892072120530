import AuthArt from "../../assets/AuthArt";
import Modal from "../UI/Modal";

const AuthModal = (props) => {
  return (
    <Modal className="flex h-96 flex-col items-center justify-center gap-5">
      <AuthArt className="h-60 w-min" />
      <p>
        This is the authentication modal. User authentication is still a work in
        progress...
      </p>
    </Modal>
  );
};

export default AuthModal;
