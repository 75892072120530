const PercentageBar = (props) => {
  return (
    <div className="h-2 w-full rounded-full bg-slate-100 dark:bg-slate-700">
      <div
        className={`h-2 rounded-full ${props.align === "right" && "ml-auto"} ${
          props.winner ? "bg-primary" : "bg-primaryLight"
        }`}
        style={{ width: `${props.percentage}%` }}
      />
    </div>
  );
};

export default PercentageBar;
