import FilterItem from "./FilterItem";

const Filters = (props) => {
  return (
    <div
      className={`flex w-full gap-3 overflow-x-auto no-scrollbar ${props.className}`}
    >
      {props.filtersList.map((filterItem) => (
        <FilterItem
          key={filterItem}
          name={filterItem}
          isActive={props.activeItem === filterItem}
          onActiveItemChange={props.onActiveItemChange}
        />
      ))}
    </div>
  );
};

export default Filters;
