import { IoTennisballSharp } from "react-icons/io5";
import useViewport from "../../hooks/use-viewport";
import { range } from "lodash";
import SinglePlayerName from "./SinglePlayerName";
import PlayerRank from "./PlayerRank";

const CompetitorName = (props) => {
  const isLoading = !props.competitor;
  const { width } = useViewport();

  if (isLoading) {
    return <SinglePlayerName withFlag={props.withFlag} />;
  }

  const breakpoint = 1024;
  const isMobile = width < breakpoint;
  const isDouble = props.competitor.isDouble;

  let name;
  if (isDouble) {
    const players = range(2).map((i) => ({
      name: props.competitor.name[i],
      country: props.competitor.country[i],
      image: props.competitor.image[i],
    }));
    if (props.doubleLines || isMobile) {
      name = (
        <div className={`flex flex-col ${props.centered && "items-center"}`}>
          <SinglePlayerName
            centered={props.centered}
            competitor={players[0]}
            isMobile={isMobile}
            shortName={props.shortName}
            withFlag={props.withFlag}
            isWinner={props.isWinner}
          />
          <SinglePlayerName
            centered={props.centered}
            competitor={players[1]}
            isMobile={isMobile}
            shortName={props.shortName}
            withFlag={props.withFlag}
            isWinner={props.isWinner}
          />
        </div>
      );
    } else {
      name = (
        <div className="flex items-center gap-3">
          <SinglePlayerName
            centered={props.centered}
            competitor={players[0]}
            isMobile={isMobile}
            shortName={props.shortName}
            withFlag={props.withFlag}
            isWinner={props.isWinner}
          />
          /
          <SinglePlayerName
            centered={props.centered}
            competitor={players[1]}
            isMobile={isMobile}
            shortName={props.shortName}
            withFlag={props.withFlag}
            isWinner={props.isWinner}
          />
        </div>
      );
    }
  } else {
    name = (
      <SinglePlayerName
        centered={props.centered}
        competitor={props.competitor}
        isMobile={isMobile}
        shortName={props.shortName}
        withFlag={props.withFlag}
        isWinner={props.isWinner}
      />
    );
  }

  return (
    <div
      className={`flex items-center justify-between pr-2 relative ${props.className}`}
    >
      <div className="flex items-center gap-2">
        {name}
        {props.withSeed && (
          <p className="text-xs font-normal text-slate-400 md:text-sm">
            {props.competitor.seed}
          </p>
        )}
      </div>
      {props.isServing && <IoTennisballSharp />}
      {props.withRank && props.competitor.rank && (
        <PlayerRank rank={props.competitor.rank} />
      )}
    </div>
  );
};

export default CompetitorName;
