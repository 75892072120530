import { useParams } from "react-router-dom";
import ForSportBettorsPage from "../components/blogs/ForSportBettors";
import PsiBackground from "../components/blogs/PsiBackground";
import TipsForSportsBettor1 from "../components/blogs/TipsForSportsBettor1";
import TipsForSportsBettor2 from "../components/blogs/TipsForSportsBettor2";
import NotFoundPage from "./NotFoundPage";

const BlogContentPage = (props) => {
  const { blogUrl } = useParams();

  let title;
  let image;
  let alt;
  let content;
  if (blogUrl === "for-sport-bettors") {
    title = "For Sport Bettors";
    image = "/for_sport_bettors.jpeg";
    alt = "";
    content = <ForSportBettorsPage />;
  } else if (blogUrl === "psi-background") {
    title = "PSI Background";
    image = "/psi_background.jpeg";
    alt = "";
    content = <PsiBackground />;
  }
  // else if (blogUrl === "psi-metrics") {
  //   title = "PSI Metrics";
  //   image = "/psi_metrics.jpeg";
  //   alt = "";
  //   content = <PsiMetricsPage />;
  // } else if (blogUrl === "psi-performance-measurements") {
  //   title = "PSI Performance Measurements";
  //   image = "/psi_performance_measurements.jpeg";
  //   alt = "";
  //   content = <PsiPerformanceMeasurementsPage />;
  // }
  else if (blogUrl === "tips-for-sports-bettor-1") {
    title =
      "Using PSI Analytics with Details in ATP, WTA, and ITF Tennis Betting";
    image = "/tips_for_sports_bettor_1.jpeg";
    alt = "";
    content = <TipsForSportsBettor1 />;
  } else if (blogUrl === "tips-for-sports-bettor-2") {
    title =
      "A Guide to Professional ATP, WTA, and ITF Tennis Betting: Odds and Tips";
    image = "/tips_for_sports_bettor_2.jpeg";
    alt = "";
    content = <TipsForSportsBettor2 />;
  } else {
    return <NotFoundPage />;
  }

  return (
    <div className="space-y-6 max-w-3xl mx-auto">
      <h1 className="font-bold text-3xl md:text-4xl">{title}</h1>
      <img
        src={process.env.PUBLIC_URL + image}
        alt={alt}
        className="rounded-xl"
      />
      {content}
    </div>
  );
};

export default BlogContentPage;
