import { useDispatch, useSelector } from "react-redux";
import { pagesStatesActions } from "../../../store/pages-states-slice";
import Filters from "../../UI/Filters";
import FormMatches from "../FormMatches";

const TournamentFormTab = (props) => {
  const activePlayerIndex = useSelector(
    (state) => state.pagesStates.formActivePlayerIndex
  );
  const dispatch = useDispatch();

  const players = Object.keys(props.form);
  const activePlayer = players[activePlayerIndex];

  const activePlayerChangeHandler = (newPlayer) => {
    const newPlayerIndex = players.findIndex((player) => player === newPlayer);
    dispatch(pagesStatesActions.changeFormActivePlayer(newPlayerIndex));
  };

  return (
    <div>
      <Filters
        filtersList={players}
        activeItem={activePlayer}
        onActiveItemChange={activePlayerChangeHandler}
        className="mb-8 justify-start sm:justify-center"
      />
      <FormMatches
        isOnMatchDetailModal={props.isOnMatchDetailModal}
        key={activePlayer}
        form={props.form[activePlayer]}
      />
    </div>
  );
};

export default TournamentFormTab;
