import Nothing from "../assets/Nothing";
import EmptyText from "../components/shared/EmptyText";

const EmptyPage = (props) => {
  return (
    <div className="flex flex-col items-center justify-center p-8">
      <Nothing className="h-32 md:h-40 w-full" />
      <EmptyText>{props.text}</EmptyText>
    </div>
  );
};

export default EmptyPage;
