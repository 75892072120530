import Card from "../../UI/Card";
import Tabs from "../../UI/Tabs";
import TournamentFormTab from "./TournamentFormTab";
import H2HTab from "./H2HTab";
import MissesTab from "./MissesTab";
import PointByPointTab from "./PointByPointTab";
import PSITab from "./PSITab";
import RatioTab from "./RatioTab";
import StatisticsTab from "./StatisticsTab";
import NoteTab from "./NoteTab";
import PastPSITab from "./PastPSITab";

const MatchDetailTabs = (props) => {
  const isLoading = !props.competitors;

  const tabs = !isLoading && {
    // ...(props.status !== "Not Started" && {
    //   Scores: (
    //     <ScoresTab
    //       status={props.status}
    //       startTime={new Date(props.startTime)}
    //       court={props.court}
    //       competitors={props.competitors}
    //       gameStats={props.gameStats}
    //       pointByPoint={props.pointByPoint}
    //     />
    //   ),
    // }),
    ...(props.psi && {
      PSI: <PSITab psi={props.psi} />,
    }),
    ...(props.pastPsi && {
      "Past PSI": <PastPSITab pastPsi={props.pastPsi} />,
    }),
    ...(props.gameStats && {
      Statistics: <StatisticsTab statistics={props.gameStats} />,
    }),
    ...(props.ways && {
      Ratio: <RatioTab ways={props.ways} />,
    }),
    ...(props.misses && {
      Misses: <MissesTab misses={props.misses} />,
    }),
    ...(props.pointByPoint && {
      "Point by Point": <PointByPointTab pointByPoint={props.pointByPoint} />,
    }),
    ...(props.h2h && {
      H2H: (
        <H2HTab
          h2h={props.h2h}
          isOnMatchDetailModal={props.isOnMatchDetailModal}
        />
      ),
    }),
    ...(props.form && {
      Form: (
        <TournamentFormTab
          form={props.form}
          isOnMatchDetailModal={props.isOnMatchDetailModal}
        />
      ),
    }),
    ...(props.note &&
      (props.note.title || props.note.content) && {
        Note: <NoteTab note={props.note} />,
      }),
  };

  // let content;
  // if (props.status === "Not Started") {
  //   content = (
  //     <div className="flex h-60 items-center justify-center">
  //       <p>The match has not started yet. Comes back later.</p>
  //     </div>
  //   );
  // } else {
  //   content = <Tabs tabs={tabs} />;
  // }

  return (
    <Card key={isLoading} className="max-w-7xl">
      <Tabs tabs={tabs} skeletonTab={<PSITab />} />
    </Card>
  );
};

export default MatchDetailTabs;
