import MonthPicker from "../UI/MonthPicker";
import Picker from "../UI/Picker";
import { MdSportsTennis } from "react-icons/md";

const TournamentsFilter = (props) => {
  return (
    <div className="flex gap-5">
      <MonthPicker
        name="tournamentMonthPicker"
        activeMonthYear={props.activeMonthYear}
        onMonthYearChange={props.onMonthYearChange}
      />
      {/* <Picker
        name="tournamentTypePicker"
        icon={<MdSportsTennis />}
        activeOption={props.activeTournamentType}
        options={["All", "Singles", "Doubles", "Mixed"]}
        onOptionChange={props.onActiveTournamentTypeChange}
      /> */}
    </div>
  );
};

export default TournamentsFilter;
