import DarkModeButton from "../shared/main-header/DarkModeButton";
import Modal from "./Modal";
import NavItem from "./NavItem";

const NavItems = (props) => {
  if (props.isMobile) {
    return (
      <Modal
        isMenu
        className="origin-top-right rounded-lg bg-white p-6 shadow-lg dark:border-slate-700 dark:bg-slate-800"
        width="w-72"
        onClick={(e) => e.stopPropagation()}
      >
        <ul className="flex flex-col space-y-2">
          {props.navItems.map((navItem) => (
            <NavItem
              isMobile
              key={navItem.name}
              name={navItem.name}
              to={navItem.to}
            />
          ))}
        </ul>
        <hr className="my-3 dark:border-slate-600" />
        <div className="flex items-center justify-between">
          <p className="text-base sm:text-lg">Theme</p>
          <DarkModeButton />
        </div>
      </Modal>
    );
  } else {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <ul className="flex w-min space-x-8">
          {props.navItems.map((navItem) => (
            <NavItem key={navItem.name} name={navItem.name} to={navItem.to} />
          ))}
          {/* {!isCharterSite && <NavItem dropdown key="Explore" name="Explore" />} */}
        </ul>
      </div>
    );
  }
};

export default NavItems;
