import { BiCalendar } from "react-icons/bi";
import Picker from "../UI/Picker";
import WinLossPercent from "./WinLossPercent";
import { useState } from "react";
import { reverse } from "lodash";
import { MdSportsTennis } from "react-icons/md";
import { customSortArray } from "../../helpers";

const WinLossTab = (props) => {
  const [tournamentType, setTournamentType] = useState("Singles");

  const defaultYearOptions = reverse(
    Object.keys(props.winLoss[tournamentType]).sort()
  );
  const [year, setYear] = useState(defaultYearOptions[0]);
  const [yearOptions, setYearOptions] = useState(defaultYearOptions);
  const [tournamentTypeOptions, setTournamentTypeOptions] = useState(
    customSortArray(
      Object.keys(props.winLoss).filter((t) =>
        Object.keys(props.winLoss[t]).includes(year)
      ),
      ["Singles", "Doubles"]
    )
  );

  const tournamentTypeChangeHandler = (options, index) => {
    const newTournamentType = options[index];
    const newYearOptions = reverse(
      Object.keys(props.winLoss[tournamentType]).sort()
    );
    const newYear = newYearOptions[0];
    setTournamentType(newTournamentType);
    setYear(newYear);
    setYearOptions(newYearOptions);
  };

  const tournamentYearChangeHandler = (options, index) => {
    const newYear = options[index];
    const newTournamentTypeOptions = customSortArray(
      Object.keys(props.winLoss).filter((t) =>
        Object.keys(props.winLoss[t]).includes(year)
      ),
      ["Singles", "Doubles"]
    );
    const newTournamentType = newTournamentTypeOptions[0];
    setYear(newYear);
    setTournamentType(newTournamentType);
    setTournamentTypeOptions(newTournamentTypeOptions);
  };

  return (
    <div className="space-y-8">
      <div className="flex justify-between gap-4">
        <Picker
          name="playerDetailTypePicker"
          icon={<MdSportsTennis />}
          activeOption={tournamentType}
          options={tournamentTypeOptions}
          onOptionChange={tournamentTypeChangeHandler}
          pickerBgColor="bg-slate-100 dark:bg-slate-800"
          maxDropdownHeight="max-h-52"
        />
        <Picker
          name="playerDetailYearPicker"
          icon={<BiCalendar />}
          activeOption={year}
          options={yearOptions}
          onOptionChange={tournamentYearChangeHandler}
          pickerBgColor="bg-slate-100 dark:bg-slate-800"
          maxDropdownHeight="max-h-52"
        />
      </div>
      <div className="grid grid-cols-2 grid-rows-2 justify-items-center gap-6 md:flex md:justify-between">
        {props.winLoss[tournamentType][year].map((value) => (
          <WinLossPercent
            surface={value.name}
            percent={value.percent}
            win={value.win}
            loss={value.loss}
          />
        ))}
      </div>
    </div>
  );
  // return (
  //   <ul className="space-y-12">
  //     {props.winLoss.map((item) => (
  //       <li key={item.year}>
  //         <CardSubheader name={item.year} />
  //         <div className="flex justify-around">
  //           {item.values.map((value) => (
  //             <WinLossPercent
  //               surface={value.name}
  //               percent={value.percent}
  //               win={value.win}
  //               loss={value.loss}
  //             />
  //           ))}
  //         </div>
  //         {/* <div className="grid grid-cols-2 gap-y-4">
  //           {item.values.map((value) => (
  //             <>
  //               <p className="text-slate-400">{value.name}</p>
  //               <p>{value.value}</p>
  //             </>
  //           ))}
  //         </div> */}
  //       </li>
  //     ))}
  //   </ul>
  // );
};

export default WinLossTab;
