import { range } from "lodash";
import Skeleton from "../shared/Skeleton";

const BioTab = (props) => {
  const isLoading = !props.bio;

  return (
    <div className="grid grid-cols-2 gap-y-4">
      {(isLoading ? range(9) : props.bio).map((bioItem) =>
        isLoading ? (
          <>
            <Skeleton className="h-4 w-16 rounded-full" />
            <Skeleton className="h-4 w-36 rounded-full" />
          </>
        ) : (
          <>
            <p className="text-slate-400">{bioItem.name}</p>
            <p>{bioItem.value}</p>
          </>
        )
      )}
    </div>
  );
};

export default BioTab;
