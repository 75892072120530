import { BiSearch } from "react-icons/bi";

const SearchInput = (props) => {
  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
        <BiSearch className="sm:text-xl text-lg dark:text-slate-500 text-slate-300" />
      </div>
      <input
        className="w-full sm:p-4 sm:pl-11 p-3 pl-10 rounded-xl text-lg placeholder:text-slate-300 dark:bg-slate-800 dark:placeholder:text-slate-500 focus:outline-none"
        type="text"
        placeholder="Search tournaments and players"
        value={props.value}
        onChange={props.onChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            props.onSubmit();
          }
        }}
      />
    </div>
  );
};

export default SearchInput;
