import { useIsFetching } from "react-query";
import Skeleton from "../shared/Skeleton";

const FilterItem = (props) => {
  const isLoading = useIsFetching({
    predicate: (query) => query.state.status === "loading",
  });

  const changeActiveStatus = () => {
    props.onActiveItemChange(props.name);
  };

  if (isLoading) {
    return (
      <Skeleton baseBackground className="h-10 w-full rounded-lg md:w-24" />
    );
  }

  return (
    <div
      className={`flex h-10 w-full min-w-max cursor-pointer items-center justify-center rounded-lg px-4 py-1 sm:w-max border ${
        props.isActive
          ? "bg-primary text-white border-primary"
          : "border-slate-300 hover:bg-slate-200 dark:hover:bg-slate-800 dark:border-slate-700"
      }`}
      onClick={changeActiveStatus}
    >
      {props.name}
    </div>
  );
};

export default FilterItem;
