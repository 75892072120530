import { Link } from "react-router-dom";
import TournamentLogo from "../shared/TournamentLogo";
import { formatDate } from "../../helpers";
import Skeleton from "../shared/Skeleton";
import PSIStatsLabel from "../shared/PSIStatsLabel";
import OngoingLabel from "../shared/OngoingLabel";

const TournamentItem = (props) => {
  const isLoading = !props.id;

  const [startDate, endDate, year] = [
    formatDate(new Date(props.startDate)),
    formatDate(new Date(props.endDate)),
    new Date(props.startDate).getFullYear(),
  ];

  const content = (
    <>
      <TournamentLogo
        name={props.name}
        category={props.category}
        level={props.level}
        className="h-auto w-16"
      />
      <div className="space-y-1">
        <div className="flex gap-2">
          {props.isCharted && <PSIStatsLabel />}
          {props.isOngoing && <OngoingLabel />}
        </div>
        {isLoading ? (
          <Skeleton className="h-4 w-48 rounded-full" />
        ) : (
          <div className="space-y-1">
            {props.isSearchResult && (
              <div className="w-max bg-slate-200 dark:bg-slate-700 dark:text-slate-400 text-slate-500 text-2xs md:text-sm px-2 rounded-md">
                {props.category}
              </div>
            )}
            <p className="font-bold">{props.name}</p>
          </div>
        )}
        <div className="space-y-1 text-xs text-slate-700 dark:text-slate-400 sm:text-sm">
          {isLoading ? (
            <Skeleton className="h-2 w-28 rounded-full" />
          ) : (
            <p>
              {startDate} - {endDate}, {year}
            </p>
          )}
          {isLoading ? (
            <Skeleton className="h-2 w-24 rounded-full" />
          ) : (
            <p>{`${props.city} · ${props.location} · ${props.surface}`}</p>
          )}
        </div>
      </div>
    </>
  );

  return isLoading ? (
    <div className={`${props.className} flex items-center gap-5 px-4 py-3`}>
      {content}
    </div>
  ) : (
    <Link
      className={`flex w-full cursor-pointer items-center gap-5 rounded-2xl px-4 py-3 hoverable:hover:bg-slate-50 hoverable:dark:hover:bg-slate-800 ${props.className}`}
      to={`/tournaments/${props.defaultSeason.id}`}
    >
      {content}
    </Link>
  );
};

export default TournamentItem;
