import MatchDetailBanner from "../components/shared/match-details/MatchDetailBanner";
import MatchDetailTabs from "../components/shared/match-details/MatchDetailTabs";
import { defer, useParams } from "react-router-dom";
import { getMatchDetail } from "../api/scores-api";
import { useQuery, useQueryClient } from "react-query";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { pagesStatesActions } from "../store/pages-states-slice";
import io from "socket.io-client";
import { matchDetailDataChangeHandlerFactory } from "../helpers";

const matchDetailQuery = (scoreId) => ({
  queryKey: ["scores", scoreId],
  queryFn: async () => getMatchDetail(scoreId),
  staleTime: 300_000,
});

const MatchDetailPage = (props) => {
  const queryClient = useQueryClient();
  const { scoreId } = useParams();
  const dispatch = useDispatch();
  const {
    data: matchDetail,
    isFetched,
    isLoading,
    error,
  } = useQuery(matchDetailQuery(scoreId));

  useEffect(() => {
    if (!isFetched) return;
    document.title = `${matchDetail.competitors[0].name} vs ${matchDetail.competitors[1].name} - ${matchDetail.season.name} - PSI Tennis Analytics`;

    const dataChangeHandler = matchDetailDataChangeHandlerFactory(
      queryClient,
      scoreId
    );

    const socket = io(process.env.REACT_APP_BACKEND_URL);

    // Listen for 'dataChange' events from the server
    socket.on("dataChange", dataChangeHandler);
    // Clean up the socket connection on unmount
    return () => {
      socket.off("dataChange", dataChangeHandler);
      socket.disconnect();
    };
  }, [queryClient, isFetched, scoreId]);

  useEffect(() => {
    dispatch(pagesStatesActions.resetAllActiveSet());
  }, [dispatch]);

  if (error) {
    throw new Error(error);
  }

  return (
    <div className="space-y-3 sm:space-y-4 md:space-y-6">
      <MatchDetailBanner
        competitorsClickable
        key={isLoading}
        status={matchDetail && matchDetail.status}
        startTime={matchDetail && matchDetail.startTime}
        competitors={matchDetail && matchDetail.competitors}
        season={matchDetail && matchDetail.season}
        dewPoint={matchDetail && matchDetail.dewPoint}
        pastPsi={matchDetail && matchDetail.pastPsi}
      />
      <MatchDetailTabs
        competitors={matchDetail && matchDetail.competitors}
        status={matchDetail && matchDetail.status}
        startTime={matchDetail && new Date(matchDetail.startTime)}
        court={matchDetail && matchDetail.court}
        psi={
          matchDetail && matchDetail.chartedData && matchDetail.chartedData.psi
        }
        pastPsi={matchDetail && matchDetail.pastPsi}
        gameStats={matchDetail && matchDetail.gameStats}
        ways={
          matchDetail && matchDetail.chartedData && matchDetail.chartedData.ways
        }
        misses={
          matchDetail &&
          matchDetail.chartedData &&
          matchDetail.chartedData.misses
        }
        pointByPoint={
          matchDetail &&
          (matchDetail.pointByPoint ||
            (matchDetail.chartedData && matchDetail.chartedData.pointByPoint))
        }
        h2h={matchDetail && matchDetail.h2h}
        form={matchDetail && matchDetail.form}
        note={
          matchDetail && matchDetail.chartedData && matchDetail.chartedData.note
        }
      />
    </div>
  );
};

export default MatchDetailPage;

export const loader = (queryClient) => {
  return async ({ params }) => {
    const scoreId = params.scoreId;
    return defer({
      promise:
        queryClient.getQueryData(matchDetailQuery(scoreId)) ??
        queryClient.fetchQuery(matchDetailQuery(scoreId)),
    });
  };
};
