import { useState } from "react";
import { customSortArray, titlelize } from "../../helpers";
import { MdSportsTennis } from "react-icons/md";
import Picker from "../UI/Picker";

const PlayerTitlesTab = (props) => {
  const tournamentTypeOptions = customSortArray(
    Object.keys(props.titles).filter((t) => props.titles[t] !== null),
    ["Singles", "Doubles"]
  );
  const [tournamentType, setTournamentType] = useState(
    tournamentTypeOptions[0]
  );

  const tournamentTypeChangeHandler = (options, index) => {
    const newTournamentType = options[index];
    setTournamentType(newTournamentType);
  };

  const headerClassName = "border-y dark:border-slate-600 py-2 text-slate-400";

  return (
    <div className="space-y-8">
      <div className="flex justify-end">
        <Picker
          name="playerDetailTypePicker"
          icon={<MdSportsTennis />}
          activeOption={tournamentType}
          options={tournamentTypeOptions}
          onOptionChange={tournamentTypeChangeHandler}
          pickerBgColor="bg-slate-100 dark:bg-slate-800"
          maxDropdownHeight="max-h-52"
        />
      </div>
      <div className="grid grid-cols-[3.5rem_3.5rem_1fr] md:grid-cols-[5rem_4rem_3fr_1fr_7rem]">
        <>
          <div className={headerClassName}>Year</div>
          <div className={headerClassName}>Titles</div>
          <div className={headerClassName}>Tournament</div>
          <div className={`${headerClassName} hidden md:block`}>Prize</div>
          <div className={`${headerClassName} hidden md:block`}>Surface</div>
        </>
        {props.titles[tournamentType].map((item) => {
          let rowSpan;
          if (item.tournament.length === 1) rowSpan = "row-span-1";
          else if (item.tournament.length === 2) rowSpan = "row-span-2";
          else if (item.tournament.length === 3) rowSpan = "row-span-3";
          else if (item.tournament.length === 4) rowSpan = "row-span-4";
          else if (item.tournament.length === 5) rowSpan = "row-span-5";
          else if (item.tournament.length === 6) rowSpan = "row-span-6";
          else if (item.tournament.length === 7) rowSpan = "row-span-7";
          else if (item.tournament.length === 8) rowSpan = "row-span-8";
          else if (item.tournament.length === 9) rowSpan = "row-span-9";
          else if (item.tournament.length === 10) rowSpan = "row-span-10";
          else if (item.tournament.length === 11) rowSpan = "row-span-11";
          else if (item.tournament.length === 12) rowSpan = "row-span-12";
          else if (item.tournament.length === 13) rowSpan = "row-span-13";
          else if (item.tournament.length === 14) rowSpan = "row-span-14";
          return (
            <>
              <div className={`border-b dark:border-slate-600 py-2 ${rowSpan}`}>
                {item.year}
              </div>
              <div className={`border-b dark:border-slate-600 py-2 ${rowSpan}`}>
                {item.tournament.length}
              </div>
              {item.tournament.map((tournament, index) => {
                const className = `py-2 dark:border-slate-600 ${
                  index === item.tournament.length - 1 && "border-b"
                }`;
                return (
                  <>
                    <div className={`${className} hidden md:block`}>
                      {tournament.name}
                    </div>
                    <div className={`${className} md:hidden pr-1`}>
                      {`${tournament.name}, ${titlelize(tournament.surface)}`}
                    </div>
                    <div className={`${className} hidden md:block`}>
                      {tournament.prize}
                    </div>
                    <div className={`${className} hidden md:block`}>
                      {titlelize(tournament.surface)}
                    </div>
                  </>
                );
              })}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default PlayerTitlesTab;
