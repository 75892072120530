import PSIList from "./PSIList";
import { useDispatch, useSelector } from "react-redux";
import { camelCase, range, startCase } from "lodash";
import Filters from "../../UI/Filters";
import { pagesStatesActions } from "../../../store/pages-states-slice";

const PSITab = (props) => {
  const activeSet = useSelector((state) => state.pagesStates.psiActiveSet);
  const dispatch = useDispatch();

  const setChangeHandler = (newSet) => {
    dispatch(
      pagesStatesActions.changeActiveSet({
        tab: "psi",
        activeSet: newSet,
      })
    );
  };

  return (
    <div className="space-y-5">
      {props.psi && (
        <Filters
          filtersList={Object.keys(props.psi).map((set) => startCase(set))}
          activeItem={activeSet}
          onActiveItemChange={setChangeHandler}
          className="justify-start sm:justify-center"
        />
      )}
      <PSIList psiList={props.psi && props.psi[camelCase(activeSet)]} />
    </div>
  );
};

export default PSITab;
