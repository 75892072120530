import { camelCase, startCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { pagesStatesActions } from "../../../store/pages-states-slice";
import Filters from "../../UI/Filters";
import RatioList from "./RatioList";

const RatioTab = (props) => {
  const activeSet = useSelector((state) => state.pagesStates.ratioActiveSet);
  const dispatch = useDispatch();

  const setChangeHandler = (newSet) => {
    dispatch(
      pagesStatesActions.changeActiveSet({
        tab: "ratio",
        activeSet: newSet,
      })
    );
  };

  return (
    <div className="space-y-8">
      <Filters
        filtersList={Object.keys(props.ways).map((set) => startCase(set))}
        activeItem={activeSet}
        onActiveItemChange={setChangeHandler}
        className="justify-start sm:justify-center"
      />
      <RatioList waysList={props.ways[camelCase(activeSet)]} />
    </div>
  );
};

export default RatioTab;
