import PointByPointItem from "./PointByPointItem";

const PointByPointList = (props) => {
  return (
    <ul className="space-y-4 divide-y dark:divide-slate-700">
      {props.pointByPointList.map((event, index) => (
        <PointByPointItem
          key={`${event.homeScore}-${event.awayScore}`}
          className={index > 0 && "pt-4"}
          homeScore={event.homeScore}
          awayScore={event.awayScore}
          homeTiebreakScore={event.homeTiebreakScore}
          awayTiebreakScore={event.awayTiebreakScore}
          server={event.server}
          homeLostServe={event.homeLostServe}
          awayLostServe={event.awayLostServe}
          winner={event.winner}
          games={event.games}
        />
      ))}
    </ul>
  );
};

export default PointByPointList;
