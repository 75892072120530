import Card from "../UI/Card";
import CompetitorPhoto from "../shared/CompetitorPhoto";
import CountryFlag from "../shared/CountryFlag";
import Skeleton from "../shared/Skeleton";
import RankingNumber from "./RankingNumber";

const PlayerSummary = (props) => {
  const isLoading = !props.name;

  const nameParts = !isLoading && props.name.split(" ");
  const firstName = !isLoading && nameParts[0];
  const lastName = !isLoading && nameParts.slice(1).join(" ");

  return (
    <Card key={isLoading} className="p-8 md:p-8">
      <div className="md:grid md:grid-cols-[1fr_3fr_1fr] md:items-center md:gap-6">
        {isLoading ? (
          <Skeleton className="hidden h-20 w-20 rounded-lg md:block" />
        ) : (
          <RankingNumber
            className="hidden md:block"
            rankings={props.rankings}
          />
        )}
        <div className="flex flex-col items-center justify-center md:flex-row gap-6">
          <div className="h-28 w-28 md:h-32 md:w-32">
            <CompetitorPhoto
              className="h-28 w-28 md:h-32 md:w-32"
              image={props.image}
              alt={props.name}
            />
          </div>
          <div className="flex items-center justify-center gap-4 divide-x dark:divide-slate-600 md:block md:divide-x-0">
            {isLoading ? (
              <Skeleton className="h-20 w-20 rounded-lg md:hidden" />
            ) : (
              <RankingNumber className="md:hidden" rankings={props.rankings} />
            )}
            <div className="space-y-2 pl-4 md:space-y-4">
              <div>
                {isLoading ? (
                  <Skeleton className="mb-2 h-5 w-20 rounded-md" />
                ) : (
                  <p className="text-xl font-bold md:text-2xl">{firstName}</p>
                )}
                {isLoading ? (
                  <Skeleton className="h-12 w-40 rounded-lg" />
                ) : (
                  <p className="text-3xl font-bold md:text-5xl">{lastName}</p>
                )}
              </div>
              <div className="flex items-center gap-2">
                <CountryFlag country={props.country} className="w-8 md:w-10" />
                {isLoading ? (
                  <Skeleton className="h-5 w-16 rounded-lg" />
                ) : (
                  <p>{props.country}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block" />
      </div>
    </Card>
  );
};

export default PlayerSummary;
