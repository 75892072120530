import { LayoutGroup } from "framer-motion";
import EmptyText from "../shared/EmptyText";
import ExpandableCard from "../shared/ExpandableCard";
import PlayersList from "../shared/PlayersList";
import TournamentItemsList from "../tournaments/TournamentItemsList";

const SearchResult = (props) => {
  if (
    props.result.tournaments.length === 0 &&
    props.result.players.length === 0
  )
    return <EmptyText>No result found</EmptyText>;

  return (
    <LayoutGroup>
      {props.result.tournaments.length > 0 && (
        <ExpandableCard
          header={<p className="font-bold">Tournaments</p>}
          details={
            <TournamentItemsList
              tournaments={props.result.tournaments}
              isSearchResult={true}
            />
          }
          initialExpanded={true}
        />
      )}
      {props.result.players.length > 0 && (
        <ExpandableCard
          header={<p className="font-bold">Players</p>}
          details={<PlayersList players={props.result.players} />}
          initialExpanded={true}
        />
      )}
    </LayoutGroup>
  );
};

export default SearchResult;
