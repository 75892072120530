import { useDispatch, useSelector } from "react-redux";
import { pagesStatesActions } from "../../store/pages-states-slice";
import Filters from "../UI/Filters";
import PlayersList from "../shared/PlayersList";
import EmptyText from "../shared/EmptyText";

const TournamentPlayersTab = (props) => {
  const activeRound = useSelector(
    (state) => state.pagesStates.tournamentPlayersActiveRound
  );
  const dispatch = useDispatch();

  const roundChangeHandler = (newRound) => {
    dispatch(
      pagesStatesActions.changeActiveRound({
        tab: "players",
        activeRound: newRound,
      })
    );
  };

  return (
    <div className="space-y-6" key={activeRound}>
      {(props.players.group.length > 0 ||
        props.players.qualification.length > 0) && (
        <Filters
          filtersList={
            props.players.group.length > 0
              ? ["Group", "Main"]
              : ["Qualification", "Main"]
          }
          activeItem={activeRound}
          onActiveItemChange={roundChangeHandler}
          className="justify-start sm:justify-center"
        />
      )}
      {props.players[activeRound.toLowerCase()].length > 0 ? (
        <PlayersList
          isDouble={props.isDouble}
          players={props.players[activeRound.toLowerCase()]}
        />
      ) : (
        <EmptyText>Main round has not started yet</EmptyText>
      )}
    </div>
  );
};

export default TournamentPlayersTab;
