import BracketMatch from "./BracketMatch";

// const BracketMatchesList = (props) => {
//   // // filter out cancelled matches
//   // const filteredMatches = props.matches.filter(
//   //   (match) => match.status !== "cancelled"
//   // );
//   return (
//     <div className="w-full sm:w-96 md:w-112">
//       {props.matches.map((match, index) => {
//         if (match === null)
//           return (
//             <div
//               className="h-40 rounded-2xl border shadow-sm dark:border-slate-600"
//               style={{
//                 marginTop: index !== 0 && props.spacing,
//               }}
//             />
//           );
//         const isActive =
//           props.hoveredPlayers.includes(match.competitors[0].id) ||
//           props.hoveredPlayers.includes(match.competitors[1].id);
//         return (
//           <BracketMatch
//             key={match.id}
//             id={match.id}
//             status={match.status}
//             startTime={new Date(match.startTime)}
//             competitors={match.competitors}
//             gameStats={match.gameStats}
//             pointByPoint={match.pointByPoint}
//             periodScores={match.period_scores}
//             style={{
//               marginTop: index !== 0 && props.spacing,
//             }}
//             onMatchHovered={props.onMatchHovered}
//             onMatchUnhovered={props.onMatchUnhovered}
//             isActive={isActive}
//             chartedData={match.chartedData}
//           />
//         );
//       })}
//     </div>
//   );
// };

const BracketMatchesList = (props) => {
  return (
    <div className="w-full sm:w-96 md:w-[31rem]">
      {props.matches.map((match, index) => {
        if (match === null)
          return (
            <BracketMatch
              dummy
              key={index}
              isEven={index % 2 === 0}
              isDouble={props.isDouble}
              roundIndex={props.roundIndex}
              roundName={props.roundName}
              isLastRound={props.isLastRound}
              matchContainerHeights={props.matchContainerHeights}
            />
          );
        const isActive =
          props.hoveredPlayers.includes(match.competitors[0].id) ||
          props.hoveredPlayers.includes(match.competitors[1].id);
        return (
          <BracketMatch
            key={index}
            id={match.id}
            isEven={index % 2 === 0}
            isActive={isActive}
            isDouble={props.isDouble}
            isLastRound={props.isLastRound}
            currentIndex={props.currentIndex}
            roundIndex={props.roundIndex}
            roundName={props.roundName}
            height={`${
              props.matchContainerHeights[props.roundIndex - props.currentIndex]
            }rem`}
            status={match.status}
            startTime={new Date(match.startTime)}
            competitors={match.competitors}
            gameStats={match.gameStats}
            pointByPoint={match.pointByPoint}
            periodScores={match.period_scores}
            onMatchHovered={props.onMatchHovered}
            onMatchUnhovered={props.onMatchUnhovered}
            chartedData={match.chartedData}
            dewPoint={match.dewPoint}
          />
        );
      })}
    </div>
  );
};

export default BracketMatchesList;
