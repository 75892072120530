import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { uiActions } from "../../store/ui-slice";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { useState } from "react";
import { motion } from "framer-motion";

const NavItem = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();

  const exploreOptions = {
    "PSI Background": "psi-background",
    "PSI Metrics": "psi-metrics",
    "PSI Performance Measurements": "psi-performance-measurements",
  };

  const dropdown = (
    <div
      className={
        props.isMobile
          ? "py-2"
          : "hidden absolute group-hover:block p-5 dark:bg-slate-800 bg-white dark:shadow-none shadow-md rounded-xl text-white w-72"
      }
    >
      <ul className={props.isMobile ? "space-y-1" : "space-y-3"}>
        {Object.keys(exploreOptions).map((name) => (
          <li>
            <NavLink
              className={(navData) =>
                navData.isActive
                  ? "text-sm text-primary sm:text-base"
                  : "text-slate-400 text-sm sm:text-base hover:text-black dark:hover:text-white"
              }
              onClick={() => {
                dispatch(uiActions.closeMenu());
              }}
              to={`/${exploreOptions[name]}`}
            >
              {name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <li className="relative w-max">
      {props.dropdown ? (
        <div
          className={`text-base sm:text-lg dark:text-white hover:text-slate-500 dark:hover:text-slate-300 ${
            !props.isMobile && "group"
          }`}
        >
          <div
            className="flex items-center gap-1 cursor-pointer"
            onClick={() => {
              setShowDropdown((currentValue) => !currentValue);
            }}
          >
            {props.name}
            {props.isMobile ? (
              <motion.div
                className="text-xs"
                variants={{ open: { rotate: 90 }, closed: { rotate: 0 } }}
                animate={showDropdown ? "open" : "closed"}
                transition={{ duration: 0.2 }}
              >
                <FaChevronRight />
              </motion.div>
            ) : (
              <FaChevronDown className="text-xs" />
            )}
          </div>
          {(!props.isMobile || showDropdown) && dropdown}
        </div>
      ) : (
        <NavLink
          className={(navData) =>
            navData.isActive
              ? "text-base text-primary sm:text-lg"
              : "text-base dark:text-white hover:text-slate-500 dark:hover:text-slate-300 sm:text-lg"
          }
          onClick={() => {
            dispatch(uiActions.closeMenu());
          }}
          to={props.to}
        >
          {props.name}
        </NavLink>
      )}
    </li>
  );
};

export default NavItem;
