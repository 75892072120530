const PrivacyPage = (props) => {
  return (
    <div className="space-y-6">
      <h2 className="font-bold text-3xl md:text-4xl">Privacy Policy</h2>
      <div className="space-y-4 flex flex-col items-center text-base sm:text-lg">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus id
          interdum velit laoreet id donec ultrices. Sit amet tellus cras
          adipiscing enim eu turpis. Cursus mattis molestie a iaculis at erat
          pellentesque. Lacus luctus accumsan tortor posuere ac ut consequat
          semper. Pharetra massa massa ultricies mi quis hendrerit. In mollis
          nunc sed id semper risus. Purus in massa tempor nec feugiat nisl. Sem
          fringilla ut morbi tincidunt augue. Diam maecenas ultricies mi eget
          mauris pharetra et ultrices. Turpis nunc eget lorem dolor sed viverra
          ipsum nunc. Quis enim lobortis scelerisque fermentum dui faucibus in
          ornare quam.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPage;
