import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { defer, useNavigate, useParams } from "react-router-dom";
import { matchDetailTransformer, saveMatchToDb } from "../api/chart-api";
import MatchDetailBanner from "../components/shared/match-details/MatchDetailBanner";
import MatchDetailTabs from "../components/shared/match-details/MatchDetailTabs";
import Button from "../components/UI/Button";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import { chartActions } from "../store/chart-slice";
import { uiActions } from "../store/ui-slice";
import { compareScores } from "../helpers";
import { getMatchDetail } from "../api/scores-api";
import { range } from "lodash";
import { GAME_SCORE_NUMBERS } from "../constants";
import io from "socket.io-client";

const socket = io(process.env.REACT_APP_BACKEND_URL);

const apiMatchDetailQuery = (scoreId) => ({
  queryKey: ["scores", scoreId],
  queryFn: async () => getMatchDetail(scoreId),
});

const chartingMatchDetailQuery = (matchPresentState) => ({
  queryKey: ["chart", matchPresentState.chartingMatchId],
  queryFn: async () => matchDetailTransformer(matchPresentState),
});

const ChartingMatchResultPage = (props) => {
  const { past, present: matchPresentState } = useSelector(
    (state) => state.chart
  );
  const { scoreId } = useParams();
  // const {
  //   state: { home, away, startTime, status, apiPeriodScores },
  // } = useLocation();
  const {
    data: apiMatchDetail,
    isLoading: apiMatchDetailIsLoading,
    isError: apiMatchDetailError,
  } = useQuery(apiMatchDetailQuery(scoreId));

  const {
    data: chartedMatchDetail,
    isLoading: chartedMatchDetailIsLoading,
    isError: chartedMatchDetailError,
  } = useQuery(chartingMatchDetailQuery(matchPresentState));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = apiMatchDetailIsLoading || chartedMatchDetailIsLoading;

  if (apiMatchDetailError || chartedMatchDetailError) {
    throw new Error(apiMatchDetailError || chartedMatchDetailError);
  }

  const apiPeriodScores =
    apiMatchDetail &&
    range(5)
      .map(
        (i) =>
          apiMatchDetail.competitors[0].periodScores[i].score !== "" &&
          apiMatchDetail.competitors[1].periodScores[i].score !== "" && {
            home_score: +apiMatchDetail.competitors[0].periodScores[i].score,
            away_score: +apiMatchDetail.competitors[1].periodScores[i].score,
            type: "set",
            number: i + 1,
            home_tiebreak_score:
              apiMatchDetail.competitors[0].periodScores[i].tiebreakScore !==
                "" &&
              +apiMatchDetail.competitors[0].periodScores[i].tiebreakScore,
            away_tiebreak_score:
              apiMatchDetail.competitors[1].periodScores[i].tiebreakScore !==
                "" &&
              +apiMatchDetail.competitors[1].periodScores[i].tiebreakScore,
          }
      )
      .filter((period) => period);

  const undoHandler = () => {
    const lastMatchState =
      past[
        past.length -
          1 -
          past
            .slice()
            .reverse()
            .findIndex((state) => [false, undefined].includes(state.isFinished))
      ];
    const lastMatchStateIndex = past.lastIndexOf(lastMatchState);
    dispatch(UndoActionCreators.jumpToPast(lastMatchStateIndex));
    navigate("/chart/" + scoreId);
  };

  const submitHandler = () => {
    if (!compareScores(matchPresentState.periodScores, apiPeriodScores)) {
      dispatch(
        uiActions.openAlertModal({
          header: "Scores Mismatch",
          message:
            "The match scores you have charted are not matching the ones in our record. Please check again before submitting.",
        })
      );
    } else {
      saveMatchToDb({
        ...matchPresentState,
        charterId: localStorage.getItem("charterID"),
      })
        .then(() => {
          socket.emit("stopCharting", matchPresentState.chartingMatchId);
          dispatch(chartActions.endMatch());
          dispatch(UndoActionCreators.clearHistory());
          dispatch(
            uiActions.openAlertModal({
              header: "Woo-hoo!",
              message:
                "The result of this match has been saved successfully to the database",
            })
          );
          navigate("/chart");
        })
        .catch((err) => {
          dispatch(
            uiActions.openAlertModal({
              header: "Uh-oh!",
              message:
                "An error has occurred while saving the match result. Please try again.",
            })
          );
        });
    }
  };

  const competitorsWithChartedScores = !isLoading && [
    {
      ...apiMatchDetail.competitors[0],
      periodScores: matchPresentState.periodScores.map((period) => ({
        score: period.home_score,
        tiebreakScore: period.home_tiebreak_score,
      })),
      gameScore:
        !matchPresentState.isFinished &&
        GAME_SCORE_NUMBERS[matchPresentState.gameScores.home],
      totalScore: matchPresentState.setScores.home,
      serve: matchPresentState.server === "home",
    },
    {
      ...apiMatchDetail.competitors[1],
      periodScores: matchPresentState.periodScores.map((period) => ({
        score: period.away_score,
        tiebreakScore: period.away_tiebreak_score,
      })),
      gameScore:
        !matchPresentState.isFinished &&
        GAME_SCORE_NUMBERS[matchPresentState.gameScores.away],
      totalScore: matchPresentState.setScores.away,
      serve: matchPresentState.server === "away",
    },
  ];

  return (
    <div className="space-y-3 sm:space-y-4 md:space-y-6">
      {!isLoading && (
        <div className="m-auto flex max-w-7xl justify-between">
          <Button onClick={undoHandler}>Undo Last Step</Button>
          <Button onClick={submitHandler}>Submit Result</Button>
        </div>
      )}
      <MatchDetailBanner
        status={!isLoading && apiMatchDetail.status}
        startTime={!isLoading && apiMatchDetail.startTime}
        season={!isLoading && apiMatchDetail.season}
        competitors={competitorsWithChartedScores}
      />
      <MatchDetailTabs
        status={!isLoading && apiMatchDetail.status}
        competitors={competitorsWithChartedScores}
        psi={!isLoading && chartedMatchDetail.psi}
        // gameStats={!isLoading && chartedMatchDetail.gameStats}
        ways={!isLoading && chartedMatchDetail.ways}
        misses={!isLoading && chartedMatchDetail.misses}
        pointByPoint={!isLoading && chartedMatchDetail.pointByPoint}
        note={!isLoading && chartedMatchDetail && chartedMatchDetail.note}
      />
    </div>
  );
};

export default ChartingMatchResultPage;

export const loader = (queryClient) => {
  return async ({ params }) => {
    const scoreId = params.scoreId;
    return defer({
      promise:
        queryClient.getQueryData(apiMatchDetailQuery(scoreId)) ??
        queryClient.fetchQuery(apiMatchDetailQuery(scoreId)),
    });
  };
};
