import TournamentSummary from "../components/tournaments/TournamentSummary";
import { defer, useNavigate, useParams } from "react-router-dom";
import { getTournamentDetail } from "../api/tournaments-api";
import { useQuery, useQueryClient } from "react-query";
import TournamentDetailTabs from "../components/tournaments/TournamentDetailTabs";
import Picker from "../components/UI/Picker";
import { BiCalendar } from "react-icons/bi";
import { MdSportsTennis } from "react-icons/md";
import { formatDate, titlelize } from "../helpers";
import { useEffect } from "react";
import io from "socket.io-client";
import { pagesStatesActions } from "../store/pages-states-slice";
import { useDispatch } from "react-redux";

const tournamentDetailQuery = (seasonId) => ({
  queryKey: ["tournaments", seasonId],
  queryFn: async () => getTournamentDetail(seasonId),
  staleTime: 300_000,
});

const TournamentDetailPage = (props) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { seasonId } = useParams();
  const navigate = useNavigate();
  const {
    data: tournamentDetail,
    isFetched,
    error,
  } = useQuery(tournamentDetailQuery(seasonId));

  useEffect(() => {
    if (!isFetched) return;
    document.title = `${tournamentDetail.name} - PSI Tennis Analytics`;

    const dataChangeHandler = (updatedMatchData) => {
      // queryClient.invalidateQueries(["scores", dateIndex - 7]);
      if (updatedMatchData.operationType === "update") {
        // console.log(updatedMatchData);
        // Update the React Query cache with the new data
        queryClient.setQueryData(
          ["tournaments", seasonId],
          (tournamentData) => {
            const updatedMatchId = updatedMatchData.id;

            for (const round of Object.keys(tournamentData.seasonMatches)) {
              for (const week of tournamentData.seasonMatches[round]) {
                for (const match of week.matches) {
                  if (match.id === updatedMatchId) {
                    // console.log(`${match.id} updated`);
                    if ("status" in updatedMatchData) {
                      match.status = updatedMatchData.status;
                    }
                    if ("chartingBy" in updatedMatchData) {
                      match.chartingBy = updatedMatchData.chartingBy;
                    }
                    if ("competitors" in updatedMatchData) {
                      for (const [
                        i,
                        competitor,
                      ] of updatedMatchData.competitors.entries()) {
                        for (const field of Object.keys(competitor)) {
                          if (["s1", "s2", "s3", "s4", "s5"].includes(field)) {
                            const setIndex = parseInt(field[1]) - 1;
                            // if got tiebreak score
                            if (competitor[field].includes(".")) {
                              const [score, tiebreakScore] =
                                competitor[field].split(".");
                              match.competitors[i].periodScores[
                                setIndex
                              ].score = score;
                              match.competitors[i].periodScores[
                                setIndex
                              ].tiebreakScore = tiebreakScore;
                            } else {
                              match.competitors[i].periodScores[
                                setIndex
                              ].score = competitor[field];
                            }
                          } else {
                            match.competitors[i][field] = competitor[field];
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            return tournamentData;
          }
        );
      }
    };

    const socket = io(process.env.REACT_APP_BACKEND_URL);

    // Listen for 'dataChange' events from the server
    socket.on("dataChange", dataChangeHandler);
    // Clean up the socket connection on unmount
    return () => {
      socket.off("dataChange", dataChangeHandler);
      socket.disconnect();
    };
  }, [queryClient, isFetched, seasonId]);

  useEffect(() => {
    if (tournamentDetail) {
      let activeRound;
      for (const round of ["main", "qualification", "group"]) {
        if (tournamentDetail.seasonMatches[round].length > 0) {
          activeRound = titlelize(round);
          break;
        }
      }
      dispatch(
        pagesStatesActions.changeActiveRound({
          tab: "results",
          activeRound: activeRound,
        })
      );
      dispatch(
        pagesStatesActions.changeActiveRound({
          tab: "players",
          activeRound: activeRound,
        })
      );
      dispatch(
        pagesStatesActions.changeActiveRound({
          tab: "draws",
          activeRound: activeRound,
        })
      );
    }
  }, [tournamentDetail, dispatch]);

  if (error) {
    throw new Error(error);
  }

  const isEmpty =
    tournamentDetail &&
    tournamentDetail.seasonMatches.group.length === 0 &&
    tournamentDetail.seasonMatches.qualification.length === 0 &&
    tournamentDetail.seasonMatches.main.length === 0;

  // const competitionInSiblingTournaments =
  //   tournamentDetail.siblingTournaments.find(
  //     (competition) => competition.id === tournamentDetail.competition.id
  //   );

  // const allTournamentYears = competitionInSiblingTournaments.seasons.map(
  //   (season) => season.year
  // );

  // const allTournamentTypes = tournamentDetail.siblingTournaments.map(
  //   (competition) => startCase(competition.type)
  // );

  const tournamentYearChangeHandler = (tournamentYears, index) => {
    if (+tournamentYears[index] === +tournamentDetail.year) {
      return;
    }
    const newTournamentId = tournamentDetail.allSeasons[
      tournamentDetail.type
    ].find((season) => +season.year === +tournamentYears[index]).id;
    navigate("/tournaments/" + newTournamentId);
  };

  const tournamentTypeChangeHandler = (tournamentTypes, index) => {
    if (tournamentTypes[index] === tournamentDetail.type) {
      return;
    }
    const newTournamentId = tournamentDetail.allSeasons[
      tournamentTypes[index]
    ].find((season) => +season.year === +tournamentDetail.year).id;
    console.log(newTournamentId);
    navigate("/tournaments/" + newTournamentId);
  };

  return (
    <div className="space-y-3 sm:space-y-4 md:space-y-6">
      <TournamentSummary
        name={tournamentDetail && tournamentDetail.name}
        category={tournamentDetail && tournamentDetail.category}
        level={tournamentDetail && tournamentDetail.level}
        startDate={tournamentDetail && tournamentDetail.startDate}
        endDate={tournamentDetail && tournamentDetail.endDate}
        surface={tournamentDetail && tournamentDetail.surface}
        city={tournamentDetail && tournamentDetail.city}
        location={tournamentDetail && tournamentDetail.location}
        prizeMoney={tournamentDetail && tournamentDetail.prizeMoney}
      />

      <div className="flex gap-5">
        <Picker
          baseBackground
          name="tournamentDetailYearPicker"
          icon={<BiCalendar />}
          activeOption={tournamentDetail && tournamentDetail.year}
          options={
            tournamentDetail &&
            tournamentDetail.allSeasons[tournamentDetail.type].map(
              (season) => +season.year
            )
          }
          onOptionChange={tournamentYearChangeHandler}
        />
        <Picker
          baseBackground
          name="tournamentDetailTypePicker"
          icon={<MdSportsTennis />}
          activeOption={tournamentDetail && tournamentDetail.type}
          options={
            tournamentDetail &&
            Object.keys(tournamentDetail.allSeasons).filter((tournamentType) =>
              tournamentDetail.allSeasons[tournamentType]
                .map((yearItem) => +yearItem.year)
                .includes(tournamentDetail.year)
            )
          }
          onOptionChange={tournamentTypeChangeHandler}
        />
      </div>
      {isEmpty ? (
        <p className="p-10 text-center text-xl font-bold text-slate-400">
          This tournament will start on{" "}
          {formatDate(new Date(tournamentDetail.startDate))}{" "}
          {tournamentDetail.year}.
        </p>
      ) : (
        <TournamentDetailTabs tournamentDetail={tournamentDetail} />
      )}
    </div>
  );
};

export default TournamentDetailPage;

export const loader = (queryClient) => {
  return async ({ params }) => {
    const seasonId = params.seasonId;
    return defer({
      promise:
        queryClient.getQueryData(tournamentDetailQuery(seasonId)) ??
        queryClient.fetchQuery(tournamentDetailQuery(seasonId)),
    });
  };
};
