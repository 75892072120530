import axios from "axios";
import { getErrorMessage } from "../helpers";

export const getRankings = async (tournamentCategory, tournamentType, page) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/rankings?category=${tournamentCategory}&type=${tournamentType}&page=${page}`
    );
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export const getPlayerDetail = async (playerId, matchYear) => {
  try {
    const response = await axios.get(
      matchYear
        ? `${process.env.REACT_APP_BACKEND_URL}/api/rankings/${playerId}?matchYear=${matchYear}`
        : `${process.env.REACT_APP_BACKEND_URL}/api/rankings/${playerId}`
    );
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};
