import { useEffect, useState } from "react";
import SearchInput from "../components/search/SearchInput";
import SearchResult from "../components/search/SearchResult";
import { defer, useSearchParams } from "react-router-dom";
import { getSearchResult } from "../api/search-api";
import { useQuery } from "react-query";

const searchResultQuery = (query) => ({
  queryKey: ["search", query],
  queryFn: async () => getSearchResult(query),
});

const SearchPage = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: searchResult,
    isFetched,
    error,
  } = useQuery(searchResultQuery(searchParams.get("query")));

  useEffect(() => {
    console.log("useEffect running...");
    setSearchInput(searchParams.get("query"));
  }, [searchParams]);

  if (error) {
    throw new Error(error);
  }

  return (
    <div className="space-y-5">
      <SearchInput
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onSubmit={() => {
          setSearchParams({ query: searchInput.trim() });
        }}
      />
      {isFetched && searchResult && <SearchResult result={searchResult} />}
    </div>
  );
};

export default SearchPage;

export const loader = (queryClient) => {
  return async () => {
    return defer({
      promise:
        queryClient.getQueryData(searchResultQuery("djokovic")) ??
        queryClient.fetchQuery(searchResultQuery("djokovic")),
    });
  };
};
