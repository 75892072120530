import { startCase } from "lodash";
import CardSubheader from "../../UI/CardSubheader";
import Number from "./Number";

const MissesItem = (props) => (
  <div className="space-y-2 w-full">
    <CardSubheader name={`${props.name} Misses`} />
    <div className="grid grid-cols-misses place-items-center gap-y-2">
      {props.missesItem.map((position) => (
        <>
          <Number
            background
            sign={
              position.home > position.away
                ? "-"
                : position.home < position.away
                  ? "+"
                  : ""
            }
          >
            {position.home}
          </Number>
          <p className="px-8">{startCase(position.name)}</p>
          <Number
            background
            sign={
              position.away > position.home
                ? "-"
                : position.away < position.home
                  ? "+"
                  : ""
            }
          >
            {position.away}
          </Number>
        </>
      ))}
    </div>
  </div>
);

const MissesTab = (props) => {
  return (
    <div className="flex flex-col items-center gap-6">
      <MissesItem name="Forehand" missesItem={props.misses.forehand} />
      <MissesItem name="Backhand" missesItem={props.misses.backhand} />
    </div>
  );
};

export default MissesTab;
