import { startCase } from "lodash";
import Skeleton from "../Skeleton";

const ScoresSummary = (props) => {
  const isLoading = !props.matchStatus;
  const matchStatus =
    props.matchStatus === "1" ? "Not Started" : props.matchStatus;

  return (
    <div className="flex flex-col items-center space-y-2 p-2">
      {isLoading ? (
        <Skeleton className="h-12 w-24 rounded-lg sm:h-16 sm:w-32" />
      ) : (
        <p className="font-number text-4xl font-bold sm:text-6xl">
          {props.homeMatchScore} - {props.awayMatchScore}
        </p>
      )}
      {isLoading ? (
        <Skeleton className="h-3 w-12 rounded-full" />
      ) : (
        <p className="text-sm text-slate-600 dark:text-slate-400">
          {startCase(matchStatus)}
        </p>
      )}
    </div>
  );
};

export default ScoresSummary;
