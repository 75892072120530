import CardSubheader from "../../UI/CardSubheader";
import StatItem from "./StatItem";

const StatGroup = (props) => {
  return (
    <li className={props.className}>
      <CardSubheader name={props.name} />
      <ul className="space-y-4">
        {props.stat.map((statItem) => (
          <StatItem
            key={statItem.name}
            name={statItem.name}
            home={statItem.home}
            away={statItem.away}
          />
        ))}
      </ul>
    </li>
  );
};

export default StatGroup;
