import { useEffect, useState } from "react";

const RankingNumber = (props) => {
  const [activeRankType, setActiveRankType] = useState();

  const changeActiveRankType = (newRankType) => {
    setActiveRankType(newRankType);
  };

  useEffect(() => {
    if (props.rankings) {
      setActiveRankType(Object.keys(props.rankings)[0]);
    }
  }, [props.rankings]);

  return (
    <div className={props.className}>
      <p>Rank</p>
      <p className="font-number text-5xl font-bold md:text-7xl">
        {props.rankings && activeRankType
          ? props.rankings[activeRankType].rank
          : "-"}
      </p>
      {props.rankings && (
        <div className="flex border-black dark:border-white border rounded-md overflow-hidden w-max mt-2">
          {Object.keys(props.rankings).map((rankType) => (
            <div
              className={`text-2xs md:text-xs px-1 md:py-1 md:px-2 cursor-pointer ${
                rankType === activeRankType &&
                "bg-black text-white dark:bg-white dark:text-black"
              }`}
              onClick={() => {
                changeActiveRankType(rankType);
              }}
            >
              {rankType}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RankingNumber;
