import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { defer, useNavigate, useParams } from "react-router-dom";
import { getMatchDetail } from "../api/scores-api";
import ChartingActions from "../components/charter/ChartingActions";
import ChartScores from "../components/charter/ChartScores";
import Button from "../components/UI/Button";
import { uiActions } from "../store/ui-slice";
import { range } from "lodash";

const matchDetailQuery = (scoreId) => ({
  queryKey: ["scores", scoreId],
  queryFn: async () => getMatchDetail(scoreId),
});

const ChartingPage = (props) => {
  const { scoreId } = useParams();
  const { isFinished, chartingMatchId } = useSelector(
    (state) => state.chart.present
  );
  const { data: matchDetail, error } = useQuery(matchDetailQuery(scoreId));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const {
  //   state: { charted, isBestOf5, competitors, startTime, status },
  // } = useLocation();

  useEffect(() => {
    if (isFinished && chartingMatchId === scoreId) {
      navigate("/chart/result/" + scoreId);
    }
  }, [isFinished, navigate, scoreId, chartingMatchId]);

  if (error) {
    throw new Error(error);
  }

  const backToMatchHandler = () => {
    navigate(`/chart/${chartingMatchId}`);
    dispatch(uiActions.closeModal());
  };

  if (chartingMatchId && chartingMatchId !== scoreId) {
    return (
      <div className="m-auto max-w-7xl space-y-5 p-8">
        <h2 className="text-center text-xl font-bold">
          You have an unfinished match.
        </h2>
        <p className="text-center text-slate-400">
          {`You still haven't complete charting match ${chartingMatchId}.
          Please complete it before charting another one.`}
        </p>
        <Button className="w-full" onClick={backToMatchHandler}>
          Back to my current match
        </Button>
      </div>
    );
  }

  if (matchDetail && matchDetail.chartedData) {
    return (
      <div className="m-auto max-w-7xl space-y-5 p-8">
        <h2 className="text-center text-xl font-bold">
          This match has already been charted.
        </h2>
        <p className="text-center text-slate-400">Please try another match.</p>
      </div>
    );
  }

  if (
    matchDetail &&
    matchDetail.chartingBy &&
    matchDetail.chartingBy !== localStorage.getItem("charterID")
  ) {
    return (
      <div className="m-auto max-w-7xl space-y-5 p-8">
        <h2 className="text-center text-xl font-bold">
          This match is currently being charted by another charter.
        </h2>
        <p className="text-center text-slate-400">Please try another match.</p>
      </div>
    );
  }

  if (chartingMatchId === null) {
    return (
      <div className="m-auto max-w-7xl space-y-5 p-8">
        <h2 className="text-center text-xl font-bold">
          The settings for this match has not been initialized yet.
        </h2>
        <p className="text-center text-slate-400">
          Please launch this page from the main Chart page.
        </p>
      </div>
    );
  }

  const apiPeriodScores =
    matchDetail &&
    range(5)
      .map(
        (i) =>
          matchDetail.competitors[0].periodScores[i].score !== "" &&
          matchDetail.competitors[1].periodScores[i].score !== "" && {
            home_score: +matchDetail.competitors[0].periodScores[i].score,
            away_score: +matchDetail.competitors[1].periodScores[i].score,
            type: "set",
            number: i + 1,
            home_tiebreak_score:
              matchDetail.competitors[0].periodScores[i].tiebreakScore !== "" &&
              +matchDetail.competitors[0].periodScores[i].tiebreakScore,
            away_tiebreak_score:
              matchDetail.competitors[1].periodScores[i].tiebreakScore !== "" &&
              +matchDetail.competitors[1].periodScores[i].tiebreakScore,
          }
      )
      .filter((period) => period);

  return (
    <div className="space-y-3 sm:space-y-4 md:space-y-6">
      <ChartScores
        home={matchDetail && matchDetail.competitors[0]}
        away={matchDetail && matchDetail.competitors[1]}
      />
      <ChartingActions
        home={matchDetail && matchDetail.competitors[0]}
        away={matchDetail && matchDetail.competitors[1]}
        startTime={matchDetail && matchDetail.startTime}
        season={matchDetail && matchDetail.season}
        apiPeriodScores={apiPeriodScores}
      />
    </div>
  );
};

export default ChartingPage;

export const loader = (queryClient) => {
  return async ({ params }) => {
    const scoreId = params.scoreId;
    return defer({
      promise:
        queryClient.getQueryData(matchDetailQuery(scoreId)) ??
        queryClient.fetchQuery(matchDetailQuery(scoreId)),
    });
  };
};
