import StatGroup from "./StatGroup";

const StatList = (props) => {
  return (
    <ul className="space-y-6 sm:space-y-8">
      {props.statsList.map((stat, index) => {
        return (
          <StatGroup
            key={stat.name}
            className={index > 0 ? "pt-10" : ""}
            name={stat.name}
            stat={stat.stat}
          />
        );
      })}
    </ul>
  );
};

export default StatList;
