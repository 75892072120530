import { random, range } from "lodash";
import CardSubheader from "../UI/CardSubheader";
import MatchesList from "../scores/MatchesList";

const FormMatches = (props) => {
  const isLoading = !props.form;
  return (
    <ul className="space-y-8 ">
      {(isLoading ? range(random(3, 6)) : props.form).map((season) => (
        <li key={season.name}>
          <CardSubheader name={season.name} />
          <MatchesList
            showTimeAgo
            isOnMatchDetailModal={props.isOnMatchDetailModal}
            matches={season.matches}
          />
        </li>
      ))}
    </ul>
  );
};

export default FormMatches;
