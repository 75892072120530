import { useSelector } from "react-redux";
import Label from "../UI/Label";

const OngoingLabel = (props) => {
  const isDarkMode = useSelector((state) => state.ui.darkMode);
  return (
    <Label className={isDarkMode ? "bg-green-600" : "bg-green-500"}>
      Ongoing
    </Label>
  );
};

export default OngoingLabel;
