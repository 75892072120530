import { Link } from "react-router-dom";
import ExpandableCard from "../shared/ExpandableCard";
import MatchesList from "./MatchesList";
import { MdInfoOutline } from "react-icons/md";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

const ClayInfoModal = (props) => {
  return (
    <motion.div
      className="absolute w-72 z-10 top-14 origin-top-right rounded-lg p-3 bg-white shadow-md dark:bg-slate-800"
      initial={{
        opacity: 0,
        scale: 0.75,
        translateX: "-95%",
        // y: "100vh",
      }}
      animate={{
        opacity: 1,
        scale: 1,
        translateX: "-95%",
        transition: {
          ease: "easeOut",
          duration: 0.15,
        },
      }}
      exit={{
        opacity: 0,
        scale: 0.75,
        translateX: "-95%",
        transition: {
          ease: "easeIn",
          duration: 0.15,
        },
      }}
    >
      <p className="text-xs">
        Matches played on clay will be identified as either a Day Match or
        Evening Match. Day matches tend to lead to faster conditions due to less
        humidity. Evening matches tend to lead to slower conditions due to
        higher humidity resulting in LESS winners, forced errors, and unreturned
        serves.
      </p>
    </motion.div>
  );
};

const LiveTournamentItem = (props) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <li className="relative">
      <ExpandableCard
        header={
          <div className="flex items-center gap-1">
            <Link
              to={props.id && `/tournaments/${props.id}`}
              className="hover:opacity-70"
            >
              <p className="font-bold">{props.title}</p>
            </Link>
            {props.surface && props.surface.toLowerCase() === "clay" && (
              <div>
                <button
                  className="rounded-full p-2 hover:bg-slate-50 dark:hover:bg-slate-800 cursor-pointer text-lg"
                  onMouseEnter={() => {
                    setShowInfo(true);
                  }}
                  onMouseLeave={() => {
                    setShowInfo(false);
                  }}
                >
                  <MdInfoOutline />
                </button>
                <AnimatePresence>
                  {showInfo && <ClayInfoModal />}
                </AnimatePresence>
              </div>
            )}
          </div>
        }
        details={<MatchesList matches={props.matches} />}
        initialExpanded={true}
      />
    </li>
  );
};

export default LiveTournamentItem;
