import { useDispatch, useSelector } from "react-redux";
import { pagesStatesActions } from "../../../store/pages-states-slice";
import Filters from "../../UI/Filters";
import PSIList from "./PSIList";
import { camelCase, startCase } from "lodash";
import { useEffect, useMemo } from "react";

const PastPSITab = (props) => {
  const pastPsiCategory = useSelector(
    (state) => state.pagesStates.pastPsiCategory
  );
  const dispatch = useDispatch();

  const pastPsi = useMemo(
    () =>
      Object.keys(props.pastPsi).filter(
        (psiCat) => props.pastPsi[psiCat].length !== 0
      ),
    [props.pastPsi]
  );

  useEffect(() => {
    dispatch(pagesStatesActions.changePastPsiCategory(startCase(pastPsi[0])));
  }, [dispatch, pastPsi]);

  const setChangeHandler = (newPastPsiCategory) => {
    dispatch(pagesStatesActions.changePastPsiCategory(newPastPsiCategory));
  };

  return (
    <div className="space-y-5">
      <Filters
        filtersList={pastPsi.map((psiCat) => startCase(psiCat))}
        activeItem={pastPsiCategory}
        onActiveItemChange={setChangeHandler}
        className="justify-start sm:justify-center"
      />
      <PSIList psiList={props.pastPsi[camelCase(pastPsiCategory)]} />
    </div>
  );
};

export default PastPSITab;
