import { useDispatch, useSelector } from "react-redux";
import {
  aceHandlerThunk,
  ballInHandlerThunk,
  chartActions,
  faultHandlerThunk,
  gamePenaltyHandlerThunk,
  pointPenaltyHandlerThunk,
} from "../../store/chart-slice";
import Button from "../UI/Button";
import Card from "../UI/Card";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import { useState } from "react";
import CompetitorName from "../shared/CompetitorName";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { uiActions } from "../../store/ui-slice";
import { useNavigate } from "react-router-dom";
import { BiArrowBack, BiRedo, BiUndo } from "react-icons/bi";
import { compareScores } from "../../helpers";
import { saveMatchToDb } from "../../api/chart-api";
import io from "socket.io-client";
import Skeleton from "../shared/Skeleton";

const socket = io(process.env.REACT_APP_BACKEND_URL);

const forcedUnforcedErrorMapping = [
  "winner",
  "winner",
  "unforced_error",
  "unforced_error",
];

const ForcedUnforcedErrorButton = (props) => (
  <Button onClick={props.onClick}>
    <div className="flex flex-col items-center justify-center gap-2 py-2">
      <p>{props.topLine}</p>
      {props.icon}
      <p>{props.bottomLine}</p>
    </div>
  </Button>
);

const ChartingActions = (props) => {
  const [actionsPage, setActionsPage] = useState(0);
  const [forcedUnforcedErrorOption, setForcedUnforcedErrorOption] = useState();
  const { past, present, future } = useSelector((state) => state.chart);
  const { server, isSecondServe, isTiebreaker, isSupertiebreaker, note } =
    present;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = !props.home;

  const missesHandler = (winner, way, position) => {
    dispatch(
      ballInHandlerThunk(
        winner,
        isSecondServe,
        winner === server ? "server_won" : "receiver_won",
        `${way}_${forcedUnforcedErrorMapping[forcedUnforcedErrorOption]}_${position}`
      )
    );
    dispatch(uiActions.closeModal());
    setActionsPage(0);
    setForcedUnforcedErrorOption(null);
  };

  const ballInHandler = (way) => {
    const winner =
      forcedUnforcedErrorOption === 0 || forcedUnforcedErrorOption === 3
        ? "home"
        : "away";
    if (
      (way === "forehand" || way === "backhand") &&
      (forcedUnforcedErrorOption === 2 || forcedUnforcedErrorOption === 3)
    ) {
      dispatch(
        uiActions.openAlertModal({
          header: "Position",
          actions: [
            {
              name: "Offensive",
              onClick: () => {
                missesHandler(winner, way, "offensive");
              },
            },
            {
              name: "Neutral",
              onClick: () => {
                missesHandler(winner, way, "neutral");
              },
            },
            {
              name: "Defensive",
              onClick: () => {
                missesHandler(winner, way, "defensive");
              },
            },
          ],
        })
      );
    } else {
      dispatch(
        ballInHandlerThunk(
          winner,
          isSecondServe,
          winner === server ? "server_won" : "receiver_won",
          `${way}_${forcedUnforcedErrorMapping[forcedUnforcedErrorOption]}`
        )
      );
      setActionsPage(0);
      setForcedUnforcedErrorOption(null);
    }
  };

  const aceHandler = () => {
    dispatch(aceHandlerThunk(server, isSecondServe));
  };

  const faultHandler = () => {
    dispatch(faultHandlerThunk(server, isSecondServe));
  };

  const pointPenaltyHandler = () => {
    dispatch(
      uiActions.openAlertModal({
        header: "Point Penalty",
        message: "Which player should be missed point be awarded to?",
        actions: [
          {
            name: props.home.name,
            onClick: () => {
              dispatch(pointPenaltyHandlerThunk("home", isSecondServe));
              dispatch(uiActions.closeModal());
            },
          },
          {
            name: props.away.name,
            onClick: () => {
              dispatch(pointPenaltyHandlerThunk("away", isSecondServe));
              dispatch(uiActions.closeModal());
            },
          },
        ],
      })
    );
  };

  const gamePenaltyHandler = () => {
    dispatch(
      uiActions.openAlertModal({
        header: "Game Penalty",
        message: "Which player should be missed game be awarded to?",
        actions: [
          {
            name: props.home.name,
            onClick: () => {
              dispatch(gamePenaltyHandlerThunk("home", isSecondServe));
              dispatch(uiActions.closeModal());
            },
          },
          {
            name: props.away.name,
            onClick: () => {
              dispatch(gamePenaltyHandlerThunk("away", isSecondServe));
              dispatch(uiActions.closeModal());
            },
          },
        ],
      })
    );
  };

  const undoHandler = () => {
    dispatch(UndoActionCreators.undo());
  };

  const redoHandler = () => {
    dispatch(UndoActionCreators.redo());
  };

  const showMatchDetailModal = () => {
    dispatch(uiActions.openMatchDetailModal({ ...props }));
  };
  const showAddNoteModal = () => {
    dispatch(uiActions.openAddNoteModal());
  };

  const saveMatchHandler = () => {
    dispatch(uiActions.closeModal());
    if (!compareScores(present.periodScores, props.apiPeriodScores)) {
      dispatch(
        uiActions.openAlertModal({
          header: "Scores Mismatch",
          message:
            "The match scores you have charted are not matching the ones in our record. Please check again before submitting.",
        })
      );
    } else {
      saveMatchToDb({
        ...present,
        charterId: localStorage.getItem("charterID"),
      })
        .then(() => {
          socket.emit("stopCharting", present.chartingMatchId);
          dispatch(chartActions.endMatch());
          dispatch(UndoActionCreators.clearHistory());
          dispatch(
            uiActions.openAlertModal({
              header: "Woo-hoo!",
              message:
                "The result of this match has been saved successfully to the database",
            })
          );
          dispatch(chartActions.endMatch());
          dispatch(UndoActionCreators.clearHistory());
          navigate("/chart");
        })
        .catch(() => {
          dispatch(
            uiActions.openAlertModal({
              header: "Uh-oh!",
              message:
                "An error has occurred while saving the match result. Please try again.",
            })
          );
        });
    }
  };

  const endMatchHandler = () => {
    dispatch(
      uiActions.openAlertModal({
        header: "Match ended?",
        message:
          "If the match has ended, although not completed, please choose yes.",
        actions: [
          {
            name: "Yes, the match has ended, save the match",
            onClick: saveMatchHandler,
          },
          {
            name: "No, the match is still ongoing, I just wanted to stop charting it",
            onClick: () => {
              socket.emit("stopCharting", present.chartingMatchId);
              dispatch(uiActions.closeModal());
              dispatch(chartActions.endMatch());
              dispatch(UndoActionCreators.clearHistory());
              navigate("/chart");
            },
          },
          {
            name: "No, I would like to continue charting",
            onClick: () => {
              dispatch(uiActions.closeModal());
            },
          },
        ],
      })
    );
  };

  let content;
  if (isLoading) {
    content = (
      <>
        <div className="flex justify-between">
          <Skeleton className="rounded-full h-8 w-8" />
          <Skeleton className="rounded-full h-8 w-8" />
        </div>
        <Skeleton className="rounded-full h-3 w-20" />
        <Skeleton className="rounded-lg h-10 w-full" />
        <Skeleton className="rounded-lg h-10 w-full" />
        <Skeleton className="rounded-lg h-10 w-full" />
        <div className="flex gap-2">
          <Skeleton className="rounded-lg h-10 w-full" />
          <Skeleton className="rounded-lg h-10 w-full" />
        </div>
        <div className="flex gap-2">
          <Skeleton className="rounded-lg h-10 w-full" />
          <Skeleton className="rounded-lg h-10 w-full" />
          <Skeleton className="rounded-lg h-10 w-full" />
        </div>
      </>
    );
  } else if (actionsPage === 0) {
    content = (
      <>
        <div className="flex justify-between">
          <Button
            rounded
            disabled={past.at(-1).chartingMatchId === null}
            onClick={undoHandler}
          >
            <BiUndo />
          </Button>
          <Button rounded disabled={future.length === 0} onClick={redoHandler}>
            <BiRedo />
          </Button>
        </div>
        <div className="flex justify-between">
          <p>{isSecondServe ? "Second Serve" : "First Serve"}</p>
          {isTiebreaker && <p>Tiebreaker</p>}
          {isSupertiebreaker && <p>Supertiebreaker</p>}
        </div>
        <Button
          className="w-full"
          onClick={() => {
            setActionsPage(1);
          }}
        >
          Ball In
        </Button>
        <Button className="w-full" onClick={aceHandler}>
          Unreturned Serve
        </Button>
        <Button className="w-full" onClick={faultHandler}>
          Fault
        </Button>
        <div className="flex gap-2">
          <Button className="w-full" onClick={pointPenaltyHandler}>
            Point Penalty
          </Button>
          <Button className="w-full" onClick={gamePenaltyHandler}>
            Game Penalty
          </Button>
        </div>
        <div className="flex justify-between gap-2">
          <Button className="w-full" onClick={showMatchDetailModal}>
            See Stats
          </Button>
          <Button className="w-full" onClick={showAddNoteModal}>
            {note.title || note.content ? "Edit Note" : "Add Note"}
          </Button>
          <Button className="w-full" onClick={endMatchHandler}>
            End Match
          </Button>
        </div>
      </>
    );
  } else if (actionsPage === 1) {
    content = (
      <>
        <Button
          rounded
          onClick={() => {
            setActionsPage(0);
          }}
        >
          <BiArrowBack />
        </Button>
        <div className="flex justify-between">
          <CompetitorName competitor={props.home} />
          <CompetitorName competitor={props.away} />
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4">
          <ForcedUnforcedErrorButton
            topLine="Winner"
            icon={<AiFillPlusCircle />}
            bottomLine="Forced an Error"
            onClick={() => {
              setActionsPage(2);
              setForcedUnforcedErrorOption(0);
            }}
          />
          <ForcedUnforcedErrorButton
            topLine="Winner"
            icon={<AiFillPlusCircle />}
            bottomLine="Forced an Error"
            onClick={() => {
              setActionsPage(2);
              setForcedUnforcedErrorOption(1);
            }}
          />
          <ForcedUnforcedErrorButton
            topLine="Unforced Error"
            icon={<AiFillMinusCircle />}
            bottomLine="Missed Makeable Shot"
            onClick={() => {
              setActionsPage(2);
              setForcedUnforcedErrorOption(2);
            }}
          />
          <ForcedUnforcedErrorButton
            topLine="Unforced Error"
            icon={<AiFillMinusCircle />}
            bottomLine="Missed Makeable Shot"
            onClick={() => {
              setActionsPage(2);
              setForcedUnforcedErrorOption(3);
            }}
          />
        </div>
      </>
    );
  } else {
    content = (
      <>
        <Button
          rounded
          onClick={() => {
            setActionsPage(1);
            setForcedUnforcedErrorOption(null);
          }}
        >
          <BiArrowBack />
        </Button>
        <Button
          className="w-full"
          onClick={() => {
            ballInHandler("forehand");
          }}
        >
          Forehand
        </Button>
        <Button
          className="w-full"
          onClick={() => {
            ballInHandler("backhand");
          }}
        >
          Backhand
        </Button>
        <div className="flex gap-2">
          <Button
            className="w-full"
            onClick={() => {
              ballInHandler("forehand_volley");
            }}
          >
            Forehand Volley
          </Button>
          <Button
            className="w-full"
            onClick={() => {
              ballInHandler("backhand_volley");
            }}
          >
            Backhand Volley
          </Button>
        </div>
        <div className="flex gap-2">
          <Button
            className="w-full"
            onClick={() => {
              ballInHandler("overhead");
            }}
          >
            Overhead
          </Button>
          <Button
            className="w-full"
            onClick={() => {
              ballInHandler("drop_shot");
            }}
          >
            Drop Shot
          </Button>
          <Button
            className="w-full"
            onClick={() => {
              ballInHandler("lob");
            }}
          >
            Lob
          </Button>
          <Button
            className="w-full"
            onClick={() => {
              ballInHandler("half_volley");
            }}
          >
            Half Volley
          </Button>
        </div>
      </>
    );
  }

  return <Card className="space-y-4 py-6 px-3 sm:px-4 md:px-6">{content}</Card>;
};

export default ChartingActions;
